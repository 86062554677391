/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { eventUtil, Modal, Heading, Types, Messages } from '@marriott/mi-ui-library';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import {
  AccountModalEvent,
  ACCOUNT_MODAL_CLOSE_EVENT,
  ACCOUNT_MODAL_OPEN_EVENT,
  addClassToBlankTargetLinks,
  CHECKED_IN_MODAL_ID,
  modalScrollHandler,
  setSubDirectoryPrefix,
} from '../../modules';
import { replaceText } from '../../modules/utils/promotionHelper';
import { StyledModalAccountContainerDiv } from './AccountContainer.styles';
/**
 *
 * @param param0
 * will recive a prop Children = value should be a function component that will have some props
 * @returns
 * modal component with required children componenet
 */

export const MemoizedPageContentWithModal: FC<{
  Children: FC<{ accountEvent?: AccountModalEvent | null }>;
  modalId?: string;
}> = ({ Children, modalId }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [accountModalEvent, setAccountModalEvent] = useState<AccountModalEvent | null>();
  const accountContainerModalIdClass = modalId ? `account-container-modal__${modalId}` : '';

  useEffect(() => {
    /**register modal for now
     * TODO: need to integrate this with zustand
     */
    eventUtil.on(ACCOUNT_MODAL_OPEN_EVENT, (modalEvent: AccountModalEvent) => {
      /** open modal and publish the event
       * listen data if anything publish
       */
      if (modalId === modalEvent?.modalType) {
        setSubDirectoryPrefix(modalEvent?.subDirectoryPrefix ?? '');
        modalScrollHandler(true);
        setIsModalOpen(true);
        setAccountModalEvent(modalEvent);
      }
    });
    eventUtil.on(ACCOUNT_MODAL_CLOSE_EVENT, () => {
      /** close the modal in publish the event
       *  listen data if anything publish wuith event
       */
      modalScrollHandler(false);
      setIsModalOpen(false);
      setAccountModalEvent(null);
    });
    return () => {
      /**remove registered event */
      eventUtil.remove(ACCOUNT_MODAL_CLOSE_EVENT, () => {
        /**
         * handle cleaning activity at this place
         */
        setIsModalOpen(false);
      });
      eventUtil.remove(ACCOUNT_MODAL_OPEN_EVENT, () => {
        /**
         * handle cleaning activity at this place
         */
        setIsModalOpen(false);
      });
    };
  }, []);

  const closeModal = () => {
    //event dispatch close modal event, empty modal event obj
    if (document.body) {
      document.body.style.removeProperty('overflow');
    }
    setIsModalOpen(false);
  };

  return (
    <StyledModalAccountContainerDiv>
      <Modal
        className={clsx(`account-container-modal custom-scrollbar`, isModalOpen && 'show')}
        secondaryClassName={`account-container-modal-secondary standard ${accountContainerModalIdClass}`}
        show={isModalOpen}
        handleBlur={true}
        popupOpenState={isModalOpen}
        setPopupOpenState={() => {
          closeModal();
        }}
        role="dialog"
        labelledBy="account-container-modal"
        onClose={closeModal}
        modalId="account-container-modal"
      >
        <Modal.Header
          className="modal-header pr-4 pt-4 pb-4 p-lg-0 d-flex justify-content-end"
          labelText=""
          popupHeaderOnCLoseFunc={closeModal}
        />
        {isModalOpen && (
          <Modal.Body className={clsx('py-0 modal-body px-md-5', modalId === CHECKED_IN_MODAL_ID ? 'px-4' : 'px-3')}>
            <Children accountEvent={accountModalEvent} />
          </Modal.Body>
        )}
      </Modal>
    </StyledModalAccountContainerDiv>
  );
};

export const MemoizedPageTitleAndAlertContent: React.FC<{
  isEAASignIn?: boolean;
  contentDescription?: string;
  title?: string;
  pageErrors?: any;
  displayTypeOneError?: boolean;
  typeTwoErrorHeader?: string;
  isOverlay?: boolean;
  pagePath?: string;
  isCheckedIn?: boolean;
  trackingPageName: string;
}> = ({
  isEAASignIn,
  isOverlay,
  contentDescription,
  title,
  pageErrors,
  displayTypeOneError,
  typeTwoErrorHeader,
  isCheckedIn,
  trackingPageName,
}) => {
  /** memoized the component due to avoid the rerender
   * if any state update then it will rerender only
   */
  return (
    <div
      className={clsx(
        {
          'row mb-4':
            pageErrors?.text ||
            !!pageErrors?.errorList?.length ||
            (!isEAASignIn && (title || contentDescription || pageErrors)),
        },
        isCheckedIn && 'mb-md-5'
      )}
    >
      {title && (
        <Heading
          fontSize={Types.size.small}
          variation={Types.headingType.title}
          titleText={title}
          customClass="col-12 page-title mb-0 t-title-s"
        />
      )}
      {contentDescription && (
        <Heading variation={Types.headingType.body} titleText={contentDescription} customClass="col-12 t-font-s mt-1" />
      )}
      {(pageErrors?.text || !!pageErrors?.errorList?.length) && (
        <div className="col-12 error_container">
          <Messages
            messageType="error-sev1"
            className={clsx('mt-3 account-page-error-msg px-2', isOverlay ? 'overlay-msg-content' : '')}
          >
            {pageErrors?.text && (
              <div
                role="alert"
                dangerouslySetInnerHTML={{
                  __html: addClassToBlankTargetLinks(replaceText(pageErrors?.text || '', [trackingPageName])),
                }}
              ></div>
            )}
            {pageErrors?.errorList &&
              !!pageErrors?.errorList?.length &&
              (displayTypeOneError ? (
                <div role="alert" dangerouslySetInnerHTML={{ __html: pageErrors?.errorList[0] }}></div>
              ) : (
                <>
                  <span>{typeTwoErrorHeader}</span>
                  {pageErrors?.errorList.map((pe: string) => (
                    <ul>
                      <li>{pe}</li>
                    </ul>
                  ))}
                </>
              ))}
          </Messages>
        </div>
      )}
    </div>
  );
};
