// Styles for SignInAppBanner go here.
import styled from 'styled-components';
import { baseVariables as theme, toRem } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';

export const StyledSignInAppBannerDiv = styled.div`
  ${rtl`
      background-color: ${theme.color.neutral00};
      .banner {
        &__link {
          cursor: pointer;
          width: 100%;
        }
        &__content {
          &-wrapper {
            max-width: ${toRem(315)};
            border-top: ${theme.border.borderWidth01} ${theme.border.borderStyleDefault} ${theme.color.neutral40};
            border-bottom: ${theme.border.borderWidth01} ${theme.border.borderStyleDefault} ${theme.color.neutral40};
            @media ${theme.mediaQuery['md']} {
              border: 0;
            }
          }
          &-img {
            height: ${toRem(60)};
          }
          &-text {
            max-width: ${toRem(256)};
            @media ${theme.mediaQuery['md']} {
              min-width: ${toRem(256)};
            }
            :is(${constants.RTL_LANG_LIST}) {
            text-align: right !important;
            }
          }
          &-icon {
            &:before {
              color: ${theme.color.accent10};
              width: ${toRem(18)};
              height: ${toRem(12)};
            }
          }
        }
      }

      &:is(${constants.RTL_LANG_LIST}) {
        .banner__content-icon:before {
            transform: none !important;
        }
      }
  `}
`;
