import React, { useEffect, FC } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { correctSubDirectoryPathForHeader } from '../../modules/utils/helper';
import { GuestEmailSurvey } from './GuestEmailSurvey';
import { Unsubscribe } from './Unsubscribe';
import { EmailUnsubscribeProps } from './EmailUnsubscribe.types';
import { StyledEmailUnsubscribe } from './EmailUnsubscribe.styles';

export const EmailUnsubscribe: FC<EmailUnsubscribeProps> = pageProps => {
  useEffect(() => {
    correctSubDirectoryPathForHeader();
  }, []);
  return (
    <StyledEmailUnsubscribe
      data-testid="ui-library-EmailUnsubscribe"
      data-component-name="m-ui-library-EmailUnsubscribe"
    >
      <div className=" unsubscribe__body my-4 spacing-top-bottom">
        {pageProps?.groupUnsubscribePage ? (
          <GuestEmailSurvey pageProps={pageProps} />
        ) : (
          <Unsubscribe pageProps={pageProps} />
        )}
      </div>
    </StyledEmailUnsubscribe>
  );
};

export const EmailUnsubscribeConfig = {
  emptyLabel: 'emailunsubscribe',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/emailunsubscribe`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EmailUnsubscribeEditable = (props: any) => {
  return (
    <EditableComponent config={EmailUnsubscribeConfig} {...props}>
      <EmailUnsubscribe {...props} />
    </EditableComponent>
  );
};
