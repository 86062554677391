import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';
import { BANNER_GRADIENTS } from './BannerContent.types';
const { WIDE } = BANNER_GRADIENTS;
const { lg, xl, md } = { ...baseVariables.mediaQuery };

export const StyledPromotionBannerImage = styled.div`
  .pb__img {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const StyledPromotionBannerContainer = styled.div`
  ${rtl` .pb-item {
    position: relative;
    overflow: hidden;
    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right;
    }
    & .pb-item {
      &__skeleton {
        &__img {
          width: 100%;
          height: ${toRem(364)};
          @media ${md} {
            height: ${toRem(256)};
          }
          @media ${xl} {
            height: ${toRem(480)};
          }
        }
      }
    }
  }
  .pb {
    &__cnt-sec {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      height: 100%;
      width: 100%;
      @media ${md} {
        align-items: center;
      }
    }
    &__cnt-ctr {
      color: ${baseVariables.color['base20']};
      position: relative;
      bottom: ${toRem(40)};
      z-index: 1;
      @media ${md} {
        bottom: auto;
      }
    }
    &__img-header {
      line-height: ${toRem(36)};
      font-size: ${toRem(30)};
      padding: 0 0 ${toRem(16)} 0;
      @media ${lg} {
        line-height: ${toRem(44)};
        font-size: ${toRem(40)};
      }
    }
    &__btn {
      min-width: ${toRem(156)};
      height: ${toRem(32)} !important;
      @media ${lg} {
        min-width: ${toRem(176)};
        height: ${toRem(40)} !important;
      }
    }
    &__sign-register {
      width: ${toRem(328)};
    }
    &__signin {
      background: none;
      border: ${toRem(1)} solid ${baseVariables.color['base20']};
    }
    &__scrim {
      background: ${baseVariables.gradient['baseGradient05']};
      @media ${md} {
        background: ${WIDE.md};
      }
      @media ${xl} {
        background: ${WIDE.xl};
      }
    }
  }
`}
`;
