// Styles for CreateAccount go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledCreateAccount = styled.div`
  .create-account {
    margin-bottom: ${toRem(-225)};

    @media ${baseVariables.mediaQuery.md} {
      margin-bottom: ${toRem(-340)};
    }

    @media ${baseVariables.mediaQuery.xl} {
      margin-bottom: ${toRem(-265)};
    }

    &-image-container {
      height: ${toRem(255)};

      @media ${baseVariables.mediaQuery.md} {
        height: ${toRem(375)};
      }

      @media ${baseVariables.mediaQuery.xl} {
        height: ${toRem(344)};
      }
    }

    &__image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &__wrapper {
      min-width: ${toRem(332)};
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(704)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(1124)};
      }
      background-color: ${baseVariables.color.base20};
      border-color: ${baseVariables.color.neutral20};
      border-radius: ${toRem(14)} ${toRem(14)} 0 0;
      position: relative;
      top: ${toRem(-199)};
      width: calc(100% - ${toRem(32)});
      padding-top: ${toRem(32)};
      box-shadow: ${toRem(10)} ${toRem(-10)} ${toRem(10)} ${toRem(-10)} ${baseVariables.shadows.boxShadowTile},
        ${toRem(-10)} ${toRem(-10)} ${toRem(10)} ${toRem(-10)} ${baseVariables.shadows.boxShadowTile};

      @media ${baseVariables.mediaQuery.md} {
        top: ${toRem(-311)};
        padding: ${toRem(64)} ${toRem(60)} 0;
      }

      @media ${baseVariables.mediaQuery.xl} {
        top: ${toRem(-240)};
        padding: ${toRem(64)} ${toRem(96)} 0;
      }
    }
  }
  .create-account__wrapper {
    .create_account__checkbox {
      label {
        &:before {
          outline: none;
        }
      }
    }
  }
`;
