// Imports for external libraries go here.
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import clsx from 'clsx';
import { inspect } from 'util';
import { Messages, RichText } from '@marriott/mi-ui-library';
import { useQuery } from '@apollo/client';
import { Phones, ProfileSectionElementProps, ProfileSectionRow } from './ProfileSectionElement.types';
import { CustomerPaymentMethod, useProfileStore } from '../../../modules/store/profileStore';
import { phoenixAccountGetProfileDetails } from '../../../modules/graph';
import {
  PROFILE_CONSTANTS,
  PageContext,
  apiLogger,
  generateApolloClientHeaders,
  accountConstants,
  profileRememberMeURL,
  getUserRememberData,
  logger,
  useCheckBreakpoint,
  BANNER_MSGS_KEYS,
} from '../../../modules';
import { useBannerMessagesStore } from '../../../modules/store/bannerMessagesStore';

import {
  countryCodeHelper,
  renderLineItemHelper,
  sectionTitleHelper,
  getCreditCardDetail,
  getCardDetailStructure,
  checkPaymentMethods,
} from '../ProfileUtils/profileHelper';
import { ProfileSectionType } from '../ProfileSection.types';
import { StyledProfileSectionElement } from './ProfileSectionElement.styles';
import profileUXLMock from '../__mock__/profileSectionUXL.modal.json';

const { log } = logger({})('UserDetails/userDetails');

// Use named rather than default exports.
export const ProfileSectionElement: FC<ProfileSectionElementProps> = ({
  profileSection,
  sectionClass,
  memberNumber,
  levelTypeCode,
  handleModalClick,
  isAuthorMode,
  isDTT,
}) => {
  const isRememberMeSection =
    profileSection?.profileSectionRow &&
    profileSection?.profileSectionRow?.filter(
      section => section?.actionObject?.profileId === PROFILE_CONSTANTS?.DYNAMIC_LINE_ITEM_ID?.REMEMBER_ME
    )?.length > 0;
  const pageContext = useContext(PageContext);
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const {
    profileDetails,
    setProfileDetails,
    setIsProfileUXLLoading,
    setPaymentId,
    setRevisionToken,
    isRememberMeSet,
    setIsRememberMeSet,
    isRememberMeChecked,
    setIsRememberMeChecked,
  } = useProfileStore(state => state);
  const { IS_LOCAL_DEV } = process.env;
  const currentLocale = pageContext?.currentLocale ?? accountConstants?.DEFAULT_LANG;
  const sessionData = pageContext?.sessionData;
  const sessionCachedData = sessionData?.cacheData?.data;
  const sessionToken = sessionData?.sessionToken;
  const dataLoaded = useRef<boolean>(false);
  const isTabletAndAboveViewPort = useCheckBreakpoint('viewportM'); // check if viewport is Tablet and above
  const router = useRouter();
  const [isIcc, setIsIcc] = useState<boolean | null>(null);

  useEffect(() => {
    const storedIsIcc = sessionStorage.getItem('isIcc') === 'true';
    if (!storedIsIcc) {
      const isIcc = router?.query['ic'] === 'true';
      if (isIcc) sessionStorage.setItem('isIcc', 'true');
      setIsIcc(isIcc);
    } else {
      setIsIcc(true);
    }
  }, [router?.query]);

  const handleEditModalClick = (userDetails: CustomerPaymentMethod[] | { id: string }[]) => {
    handleModalClick(PROFILE_CONSTANTS.DYNAMIC_LINE_ITEM_ID.CREDITCARD);
    setPaymentId(userDetails[0]?.id ?? '');
  };
  const getUserRemeberDetails = useCallback(async (isDTT: boolean) => {
    setIsRememberMeSet(true);
    try {
      const sessionToken = sessionData?.data?.cacheData?.data?.sessionToken;

      const response = await getUserRememberData(sessionToken, false, isDTT);

      if (response) {
        const { data } = response;
        setIsRememberMeChecked(data?.isRememberMe);
      }
    } catch (e) {
      log.debug(`[User Remember] error sessionID: ${sessionData?.sessionToken}, ${inspect(e)}`);
    }
  }, []);
  //handle checkbox call BE and store negation of the present checkbox if api return success 200
  const handleToggleCheckbox = async () => {
    try {
      const response = await axios.get(profileRememberMeURL);
      if (response.status === 200) {
        setIsRememberMeChecked(!isRememberMeChecked);
      }
    } catch (error: unknown) {
      apiLogger(`[profileDetailsData] profileDetailsData - Error: ${inspect(error)}`);
    }
  };

  // This is to reduce the number of parameter
  const actionObjectDataHelper = (sectionRow: ProfileSectionRow) => {
    return {
      titleObject: sectionRow?.titleObject,
      actionObject: sectionRow?.actionObject,
      handleModalClick,
      isRememberMeChecked,
      handleToggleCheckbox,
      memberNumber,
    };
  };
  const cardObjectDataHelper = (
    userCardDetails: CustomerPaymentMethod[],
    cardNumberLabel: ProfileSectionRow,
    profileSection: ProfileSectionType
  ) => {
    if (userCardDetails.length > 0) {
      const cardDetails = getCreditCardDetail(
        userCardDetails,
        cardNumberLabel?.titleObject?.featuredDynamicImage,
        isTabletAndAboveViewPort
      );
      const cardStructureHTML = getCardDetailStructure(
        cardDetails,
        profileSection?.profileSectionRow?.[0]?.titleObject?.defaultLabel?.toUpperCase(),
        profileSection?.profileSectionRow?.[0]?.titleObject?.expiryLabel?.toUpperCase(),
        profileSection?.profileSectionRow?.[0]?.titleObject?.cardTag?.toUpperCase(),
        profileSection?.profileSectionRow?.[0]?.titleObject?.cardDescription
      );

      // Ensure that the returned object matches ActionObjectDataType
      return {
        titleObject: {
          text: `text-${cardNumberLabel.actionObject.profileId}`,
          titleElement: cardStructureHTML,
        },
        actionObject: cardNumberLabel.actionObject,
        handleModalClick: () => handleEditModalClick(userCardDetails),

        isRememberMeChecked,
        memberNumber,
        handleToggleCheckbox,
      };
    }

    return null; // Handle the case when there are no cards to display
  };
  //conditionalForLineItem- if false we have to show row, if true we have to check for user or country type and check if it is present in allowed countries/ user, in the end it will return the object which satisfies the condition
  const filteredProfileSectionRow = useMemo(() => {
    return profileSection?.profileSectionRow?.filter(section => {
      const { USER_TYPE, COUNTRY_TYPE } = PROFILE_CONSTANTS;
      const { allowedCountries, allowedUsers, conditionType, conditionalForLineItem, profileId } =
        section?.actionObject || {};
      //if conditionalLineItem is false, it mean no condition is required to show the row
      if (!conditionalForLineItem) {
        return true;
      }
      const countryCode = countryCodeHelper(profileDetails?.profileAddress, profileId, currentLocale);
      //checking if user type, then if true, checking if it lies in allowed user
      const isAllowedByUser = conditionType === USER_TYPE && allowedUsers.includes(levelTypeCode ?? '');
      //checking if country type, then if true, checking if it lies in allowed countries
      const isAllowedCountry = conditionType === COUNTRY_TYPE && allowedCountries.includes(countryCode);
      return isAllowedByUser || isAllowedCountry;
    });
  }, [profileDetails]);

  useEffect(() => {
    if (isAuthorMode) {
      const {
        contactInformation,
        loyaltyInformation,
        affiliateConnection,
        wallet,
        profile,
        featuresByGroup,
        communicationOptions,
      } = profileUXLMock || {};
      setProfileDetails({
        profileAddress: contactInformation?.addresses,
        linkedAccount: [
          loyaltyInformation?.rewards?.ownership?.[0]?.code,
          affiliateConnection?.edges?.[0]?.node?.affiliate?.code,
        ],
        paymentMethods: wallet?.paymentMethods,
        preferredLanguage: profile?.preferredLanguage,
        consentFeatures: featuresByGroup?.preferencesFeatures,
        phoneNumber: formatPhoneOptions(contactInformation?.phones),
        communicationOptions: communicationOptions,
      });
    }
  }, []);

  useEffect(() => {
    !isRememberMeSet && isRememberMeSection && getUserRemeberDetails(isDTT);
  }, [getUserRemeberDetails, isRememberMeSection, isRememberMeSet]);

  const formatPhoneOptions = (data: Phones[]) => {
    const formattedOutput = [];
    for (const phoneOption of data) {
      const countryCode = phoneOption.country.code;
      const phoneNumber = phoneOption.number;
      const phoneType = phoneOption.type.code;

      const formattedEntry = {
        countryCode,
        phoneNumber,
        phoneType,
      };

      formattedOutput.push(formattedEntry);
    }
    return formattedOutput;
  };

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode ||
    profileDetails?.isdataLoaded;
  // *************** UXL ****************************

  const { loading, error: profileDetailsError } = useQuery(phoenixAccountGetProfileDetails, {
    variables: {
      customerId: sessionCachedData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    skip: skipQuery,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onCompleted: (data: any) => {
      dataLoaded.current = true;
      const { contactInformation, loyaltyInformation, affiliateConnection, wallet, profile, communicationOptions } =
        data?.customer || {};
      if (isIcc) {
        const paymentStatus = checkPaymentMethods(wallet?.paymentMethods);
        if (paymentStatus === 'success') {
          setBannerMsgs(BANNER_MSGS_KEYS?.ICC_SUCCESS_MSG);
        } else if (paymentStatus === 'pending') {
          setBannerMsgs(BANNER_MSGS_KEYS?.ICC_PENDING_MSG);
        }
      }

      setProfileDetails({
        ...profileDetails,
        profileAddress: contactInformation?.addresses,
        linkedAccount: [
          loyaltyInformation?.rewards?.ownership?.[0]?.code,
          affiliateConnection?.edges?.[0]?.node?.affiliate?.code,
        ],
        paymentMethods: wallet?.paymentMethods,
        preferredLanguage: profile?.preferredLanguage,
        isdataLoaded: true,
        phoneNumber: formatPhoneOptions(contactInformation?.phones || []),
        communicationOptions: communicationOptions,
      });
      setRevisionToken(data?.customer?.revisionToken ?? '');
      setIsProfileUXLLoading(false);
      apiLogger(`[ProfileDetails] GetProfileDetails - sessionId: ${sessionToken}: ${inspect(data)}`);
    },
    onError: error => {
      setIsProfileUXLLoading(false);
      apiLogger(`[ProfileDetails] GetProfileDetails - sessionId: ${sessionToken} - error: ${inspect(error)}`);
    },
  });

  useEffect(() => {
    if (loading) {
      setIsProfileUXLLoading(true);
    }
  }, [loading]);

  if (profileDetailsError) {
    return (
      <div data-testid="uxl-error-msg-wrapper">
        <Messages messageType="warning" className="my-4">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      </div>
    );
  }

  const showSection = profileSection?.showForUsers?.some(
    user => user.toUpperCase() === PROFILE_CONSTANTS.ALL || user.toUpperCase() === levelTypeCode
  );

  return (
    <StyledProfileSectionElement>
      {loading && !isAuthorMode ? (
        <div className={clsx('profile-sections-wrapper container px-0', sectionClass)} data-testid="profile-wrapper">
          <div className="skeleton-loader col-12 col-md-3 mb-3 skeleton-style skeleton-style-header"></div>
          <section className="p-4 p-md-5">
            <div className="skeleton-loader mb-4 skeleton-style skeleton-style-line-item"></div>
            <div className="section-border"></div>
            <div className="skeleton-loader mt-4 skeleton-style skeleton-style-line-item"></div>
          </section>
        </div>
      ) : (
        showSection && (
          <div className={clsx('profile-sections-wrapper', sectionClass)} data-testid="profile-wrapper">
            {profileSection?.sectionTitle && sectionTitleHelper(profileSection?.sectionTitle)}
            <section className="p-4 p-md-5">
              {filteredProfileSectionRow &&
                filteredProfileSectionRow?.map((profileRow, index) => {
                  const actionObjectData = actionObjectDataHelper(profileRow);
                  const isCardSection =
                    actionObjectData?.actionObject?.profileId === PROFILE_CONSTANTS.DYNAMIC_LINE_ITEM_ID.CREDITCARD;

                  if (isCardSection) {
                    const numberOfPaymentMethods = profileDetails?.paymentMethods?.length || 0;
                    // If it's a credit card section and there is no payment method data, render "Add Card" row
                    if (
                      index === 0 &&
                      (!profileDetails?.paymentMethods || profileDetails?.paymentMethods.length === 0)
                    ) {
                      return null;
                    }

                    const preferredCard = profileDetails?.paymentMethods?.find(
                      (card: CustomerPaymentMethod) => card?.preferred
                    );

                    if (preferredCard) {
                      const actionCardObjectData = cardObjectDataHelper(
                        [preferredCard], // Pass an array containing only the preferred card
                        profileRow,
                        profileSection
                      );

                      const remainingCardsToDisplay = profileDetails?.paymentMethods
                        .filter((card: CustomerPaymentMethod) => !card?.preferred)
                        .slice(0, 9);

                      const remainingCardObjects = remainingCardsToDisplay.map((card: CustomerPaymentMethod) => {
                        return cardObjectDataHelper([card], profileRow, profileSection);
                      });

                      return [actionCardObjectData, ...remainingCardObjects].map((cardObject, card) => {
                        return renderLineItemHelper(cardObject, card - 1, numberOfPaymentMethods, profileDetails);
                      });
                    } else {
                      // If no preferred card, pass details of the first 10 cards
                      const cardsToDisplay = profileDetails?.paymentMethods?.slice(0, 10);
                      return cardsToDisplay?.map((card: CustomerPaymentMethod, cardIndex: number) => {
                        const actionCardObjectData = cardObjectDataHelper(
                          [card], // Pass an array containing only the current card
                          profileRow,
                          profileSection
                        );
                        return renderLineItemHelper(
                          actionCardObjectData,
                          cardIndex - 1,
                          numberOfPaymentMethods,
                          profileDetails
                        );
                      });
                    }
                  } else {
                    // Render other sections without additional conditions
                    if (
                      profileDetails?.paymentMethods?.length > 9 &&
                      actionObjectData?.actionObject?.profileId === PROFILE_CONSTANTS.DYNAMIC_LINE_ITEM_ID.ADD_CARD
                    ) {
                      return '';
                    } else {
                      return renderLineItemHelper(
                        actionObjectData,
                        index,
                        filteredProfileSectionRow?.length,
                        profileDetails
                      );
                    }
                  }
                })}
            </section>
          </div>
        )
      )}
    </StyledProfileSectionElement>
  );
};
