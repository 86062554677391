import clsx from 'clsx';
import { Button, Image, Heading, Types, Link, eventUtil } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { useRouter } from 'next/router';
import { useAccountCommonStore } from '../../modules/store/accountCommonStore';
import {
  SWEEPSTAKES_ENTRY_PAGE_URL,
  ACCOUNT_MODAL_CLOSE_EVENT,
  addSubDirectoryPrefix,
  accountConstants,
  getContentBlockImageDimensions,
  generateImageRenditions,
  constants,
  TRACKING_CONSTANTS,
} from '../../modules';
import { fallbackImages } from '../../modules/utils/constants';
import { IconEnrollBlock } from './IconEnrollBlock/IconEnrollBlock';
import {
  StyledSignInContentBlock,
  StyledSignInContentBlockContainer,
  StyledSignInContentBlockFooter,
} from './SignInContentBlock.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SignInContentBlock = (props: any) => {
  const { isReservation, sweepsList } = props;
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { TRACKING_PAGE_NAME: pageName } = clientEnvVars;
  const trackingPageName = props?.trackingPageTitle || pageName;
  const { JOIN_NOW, INTERNAL, JOIN_FOR_FREE, JOIN_AND_CONTINUE } = TRACKING_CONSTANTS;
  const { rememberedUser } = useAccountCommonStore(state => state);
  const router = useRouter();
  const clientId = router && router.query['clientId'];
  const validClientID = typeof clientId === 'string' ? clientId : Array.isArray(clientId) ? clientId[0] || '' : '';
  const sweepsDetailsList = sweepsList?.[validClientID] || [];

  const getRenditions = (src: string) => {
    const imageSizeList = [
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.desktop,
        queryType: 'min-width',
        imageWidth: getContentBlockImageDimensions('lg', props?.isOverLay),
      },
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.tablet,
        queryType: 'min-width',
        imageWidth: getContentBlockImageDimensions('md', props?.isOverLay),
      },
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.mobile,
        queryType: 'min-width',
        imageWidth: getContentBlockImageDimensions('sm', props?.isOverLay),
      },
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.mobile,
        queryType: 'max-width',
        imageWidth: getContentBlockImageDimensions('sm', props?.isOverLay),
      },
    ];
    return generateImageRenditions(src, imageSizeList);
  };

  return (
    <StyledSignInContentBlockContainer
      data-component-name="m-account-SignInContentBlock"
      data-testid="account-SignInContentBlock"
      isResLookUpVariation={isReservation}
      className={clsx(isReservation && 'reslookup-theme')}
    >
      <div className={clsx(isReservation ? 'res-block mx-0 mr-lg-5' : 'sign-in-content-block')}>
        <StyledSignInContentBlock
          overlay={props?.isOverLay}
          className={clsx(
            'signin-img-block row',
            !isReservation
              ? `ml-lg-auto pt-lg-0 ${
                  props?.isOverLay ? 'pt-md-0' : ''
                } pt-5 px-1 px-md-0 px-1 signin--img sign-in-content-block flex-column`
              : 'res-block mx-0'
          )}
        >
          {sweepsDetailsList?.length > 0 ? (
            <>
              <Heading
                variation={Types.headingType.title}
                titleText={sweepsDetailsList?.[0]?.sweepsJoinNowTitle}
                fontSize={Types.size.small}
                customClass="mb-3 mb-lg-4"
              />
              <Button
                isLink={true}
                buttonCopy={sweepsDetailsList?.[0]?.sweepsJoinNowCta}
                custom_click_track_value={`${trackingPageName}|${JOIN_NOW}|${INTERNAL}|`}
                href={`${sweepsDetailsList?.[0]?.sweepsJoinNowCtaPath}${validClientID && `&clientId=${validClientID}`}`}
                className="signin-btn m-button-m m-button-secondary text-center width-mobile-100 d-flex-sm justify-content-center align-items-center px-5 py-3 py-sm-2"
              />
            </>
          ) : props?.variation === constants?.ICON ? (
            <IconEnrollBlock
              rememberedUser={rememberedUser}
              {...props}
              clickTrackValue={`${trackingPageName}|${JOIN_FOR_FREE}|${INTERNAL}|`}
            />
          ) : (
            <div className={clsx(isReservation && 'respath-wrapper reslookup-theme pt-2')}>
              {isReservation ? (
                <div className="logo-container col-12 px-0 mt-3 pt-5 pb-3">
                  <div className={clsx(props?.contentBlockIcon, 'logo')} aria-label="Marriott Bonvoy" />
                </div>
              ) : (
                <Image
                  dynamic={true}
                  defaultImageURL={props?.featuredDynamicImage?.assetPath || fallbackImages.Classic}
                  renditions={getRenditions(props?.featuredDynamicImage?.assetPath || fallbackImages.Classic)}
                  altText={props?.featuredDynamicImage?.altText}
                  loading="eager"
                />
              )}
              <div className={clsx('text-content-container col-12', isReservation ? 'px-0' : '')}>
                {rememberedUser ? (
                  <Heading
                    element={Types.tags.h2}
                    titleText={(isReservation
                      ? props?.rememberedUserHeader
                      : props?.rememberedUserContentBlock
                    )?.replace('{0}', rememberedUser || '')}
                    customClass={clsx(isReservation ? '' : 'mt-4', 'section-title')}
                    {...(isReservation
                      ? { variation: Types.headingType.subtitle, fontSize: Types.size.extraLarge }
                      : { variation: Types.headingType.title, fontSize: Types.size.small })}
                  />
                ) : (
                  <Heading
                    element={Types.tags.h2}
                    titleText={props?.contentBlockHeader}
                    customClass={clsx(isReservation ? 'respath-heading' : 'mt-4')}
                    {...(isReservation
                      ? { variation: Types.headingType.subtitle, fontSize: Types.size.extraLarge }
                      : { variation: Types.headingType.title, fontSize: Types.size.small })}
                  />
                )}
                <div
                  className={clsx('pl-md-0 pt-2 point-list', isReservation ? ' t-font-m' : 't-font-xs')}
                  dangerouslySetInnerHTML={{
                    __html: props?.contentBlockDescription,
                  }}
                ></div>
                {props?.joinNowLabel && (
                  <div className="pt-lg-2">
                    <Button
                      isLink={true}
                      buttonCopy={props?.joinNowLabel}
                      custom_click_track_value={`${trackingPageName}|${
                        isReservation ? JOIN_AND_CONTINUE : JOIN_NOW
                      }|${INTERNAL}|`}
                      target={props?.isOverLay ? '_parent' : '_self'}
                      href={props?.joinNowCtaPath}
                      className="signin-btn m-button-m m-button-secondary text-center width-mobile-100 d-flex-sm justify-content-center align-items-center px-5 py-3 py-sm-2"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </StyledSignInContentBlock>
        <StyledSignInContentBlockFooter className={clsx(isReservation && 'mr-1 mr-md-0')}>
          {isReservation && (
            <div className="mt-5 col-12 footer-container pt-4 d-flex align-items-center px-0 justify-content-center justify-content-md-start pb-4 res-block">
              <span className="t-font-xs pr-1">{props?.orLabel}</span>
              <Link
                target={props?.isOverLay ? '_parent' : '_self'}
                linkType="internal"
                text={props?.continueAsCTALabel}
                linkClassName="t-label-alt-s footer-container__link"
                {...(props?.continueAsCTAPath
                  ? {
                      linkHref: addSubDirectoryPrefix(props?.continueAsCTAPath),
                    }
                  : {
                      linkHref: '#',
                      callback: () => {
                        eventUtil.dispatch(ACCOUNT_MODAL_CLOSE_EVENT, {});
                      },
                    })}
              >
                <span className="icon-forward-arrow-cropped pl-2" />
              </Link>
            </div>
          )}
        </StyledSignInContentBlockFooter>
      </div>
    </StyledSignInContentBlockContainer>
  );
};
export const SignInContentBlockConfig = {
  emptyLabel: 'signincontentblock',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/signincontentblock`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SignInContentBlockEditable = (props: any) => {
  return props.cqPath?.includes('datapagecomponent') ? (
    <SignInContentBlock {...props} />
  ) : (
    <EditableComponent config={SignInContentBlockConfig} {...props}>
      <SignInContentBlock {...props} />
    </EditableComponent>
  );
};
