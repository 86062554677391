/** written file for  */
import axios from 'axios';
import { inspect } from 'util';
import Cookies from 'js-cookie';

import { DATALAYER_PAGE_EVENT, updateDataLayerProperties } from '../../modules/utils';
import { RandData } from '../store/accountCommonStore';
import {
  LOGIN_LINK_RESERVATION_URL,
  LOGIN_URL,
  constants,
  US_ZIP_CODE_REGEXT,
  CANADA_ZIP_CODE_REGEX,
  CONSENT_FORM_KOREA_COOKIE_NAME,
  CONSENT_FORM_EMEA_COOKIE_NAME,
  MASKED_PHONE,
  MFA_OPTIONS_KEY,
  REMEMBER_ME_KEY,
  ENCRYPTED_DATA,
  ENCRYPTED_VERIFY_DATA,
  EMAIL_DATA_KEY,
} from './constants';
import { getCurrentUrlParams, skipDecode } from './helper';

/**url hard coded if anyone want to use the component */
const remeberMeUrl = '/mi/phoenix-account-auth/v1/userDetails';
const remeberMeUrl_v2 = '/mi/phoenix-account-auth/v2/userDetails';
const clearRememberMEUrl = '/mi/phoenix-account-auth/v1/clearRememberedUser';
export const handleRedirect = (url = '', isOverlay = false, decode = true) => {
  /** handle redirect in this file according to the error code */
  const location = isOverlay ? window.parent.location : window.location;

  // Ensure the URL is properly sanitized and validated
  const sanitizedUrl = decode ? validateUrl(url, location.origin) : new URL(url, location.origin).href;

  if (sanitizedUrl) {
    location.href = sanitizedUrl;
  }
};

const validateUrl = (url = '', origin = ' ') => {
  // Use a regular expression to check if the URL starts with a "/"
  const decodedUrl = decodeURIComponent(url);
  // const isRelativePath = /^\/[^/]/.test(decodedUrl); TODO

  try {
    // Use the URL constructor to validate and sanitize the URL
    // const validatedUrl = new URL(isRelativePath ? decodedUrl : `https://${decodedUrl}`, origin); TODO
    const validatedUrl = new URL(decodedUrl, origin);

    return validatedUrl.href;
  } catch (error) {
    // Handle invalid URL or other errors
    return null;
  }
};

export const clearRememberUserDetails = (sessionID: string, isEAASignIn: boolean, isOverlay?: boolean) => {
  const { log } = global.loggerInstance('ClearUserRemeberME');
  const param = new URLSearchParams(isOverlay ? window.parent.location.search : window.location.search);
  /** remember me login */
  log.debug(`[CLear User Remeber] auth sesion:${sessionID}, URL! : ${clearRememberMEUrl}`);
  return axios.get(
    `${clearRememberMEUrl}?${
      param.get('returnTo')
        ? `?returnTo=${param.get('returnTo')}`
        : `returnTo=${isOverlay ? window.parent.location.pathname : window.location.pathname}`
    }`,
    {
      headers: {
        'Content-Type': 'application/json',
        isEAA: isEAASignIn ?? '',
      },
    }
  );
};

export const getUserRememberData = (sessionID: string, isEAASignIn: boolean, isDTT?: boolean) => {
  const { log } = global.loggerInstance('UserRemeberME');
  const url = isDTT ? remeberMeUrl_v2 : remeberMeUrl;
  log.debug(`[User Remeber] auth sesion:${sessionID}, URL! : ${url}`);
  return axios.get(`${url}`, {
    headers: {
      'Content-Type': 'application/json',
      isEAA: isEAASignIn ?? '',
    },
  });
};

export const userLogin = async (
  sessionID: string,
  formData: { email?: string; pwrd?: string },
  rememberMe = true,
  isEAASignIn = false,
  isOverlay = false,
  locale: string,
  eventReturnUrl?: string,
  skipDecodeForPaths?: string,
  randData?: RandData
) => {
  const urlParam = getCurrentUrlParams(isOverlay);
  const { log } = global.loggerInstance('UserLogin');
  /** user login */
  log.debug(`[User Login] auth sessionID:${sessionID}, URL! : ${LOGIN_URL}`);
  const param = new URLSearchParams(urlParam);
  let windowPath = isOverlay ? window?.parent?.location?.pathname : window?.location?.pathname;
  if (param && urlParam) {
    windowPath = `${windowPath}${urlParam}`;
  }

  log.debug(`[User Login] params ${inspect(param)}`);
  // TODO - updating logic for getting all the param in redirect
  // const redirectUrl = isEAASignIn ? param.get('redirect_uri') : param.get('returnTo');
  let redirectUrl = null;
  if (randData?.orderId) {
    redirectUrl = constants?.FIND_RESERVATION_LIST_PAGE_URL;
  } else if (eventReturnUrl) {
    redirectUrl = eventReturnUrl;
  } else if (urlParam && (urlParam.includes('redirect_uri') || urlParam.includes('returnTo'))) {
    redirectUrl = param.get(isEAASignIn ? 'redirect_uri' : 'returnTo');
  }
  const isLayeredEncoding = param.get('layeredEncoding') === 'true';
  const returnUrl = redirectUrl ?? windowPath;
  const shouldSkipDecode = !isLayeredEncoding && (isOverlay || skipDecode(returnUrl, skipDecodeForPaths));
  return axios.post(
    `${randData?.orderId ? LOGIN_LINK_RESERVATION_URL : LOGIN_URL}`,
    {
      password: formData?.pwrd,
      rememberMe: rememberMe,
      returnUrl: shouldSkipDecode ? encodeURIComponent(returnUrl) : returnUrl,
      userId: formData?.email,
      ...(isEAASignIn && urlParam && { queryParams: urlParam }),
      ...(randData?.orderId && { ...randData }),
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': locale ?? 'en-US',
        isEAA: isEAASignIn ?? '',
      },
    }
  );
};

export const validateZipCodeForUSandCanada = (code: string, val: string) => {
  switch (code) {
    case constants.USA_COUNTRY_CODE: {
      /**
       * check if input val is having the correct usa country code
       * 1. 5 digit
       * 2. 10 digit including "-" at 6 th place
       */
      return US_ZIP_CODE_REGEXT.test(val);
    }

    case constants.CANADA_COUNTRY_CODE: {
      /**
       * check if input val is having the correct usa country code
       * 1.Alphanumeric value including below standard
       * 2. one space at 4th place
       */
      return CANADA_ZIP_CODE_REGEX.test(val);
    }

    default:
      return true;
  }
};

export const clearSessionAndCookie = () => {
  sessionStorage.removeItem('AccountPersistentState');
  sessionStorage.removeItem('bannerMessagesState');
  sessionStorage.removeItem('EnrollmentMessageState');
  sessionStorage.removeItem('mr_enrolled');
  sessionStorage.removeItem('pointsTransferState');
  sessionStorage.removeItem(MASKED_PHONE);
  sessionStorage.removeItem(MFA_OPTIONS_KEY);
  sessionStorage.removeItem(REMEMBER_ME_KEY);
  sessionStorage.removeItem(ENCRYPTED_DATA);
  sessionStorage.removeItem(ENCRYPTED_VERIFY_DATA);
  sessionStorage.removeItem(EMAIL_DATA_KEY);
  Cookies?.remove(CONSENT_FORM_KOREA_COOKIE_NAME);
  Cookies?.remove(CONSENT_FORM_EMEA_COOKIE_NAME);
};

export const updateUserLoginDataLayer = (
  loginEventDetails: string,
  isSuccess: boolean = false,
  additionalDataLayerProperties?: { [key: string]: string }
) => {
  if (loginEventDetails) {
    const dataLayerProps = {
      loginEventDetails: loginEventDetails,
      ...additionalDataLayerProperties,
    };
    updateDataLayerProperties(
      dataLayerProps,
      isSuccess ? DATALAYER_PAGE_EVENT.LOGIN_SUCCESS : DATALAYER_PAGE_EVENT.LOGIN_FAILURE
    );
  }
};
