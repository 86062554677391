/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import clsx from 'clsx';
import { MSR_ERROR_CONSTANTS } from '../../../modules/utils/constants';
import { getEventKeyTypes, baseVariables } from '@marriott/mi-ui-library';
import { StyledStepOne } from './ReportMissingStayStepOne.styles';

export const StepOne = (props: any) => {
  const authorModelData = props?.authorModelData;
  const [showFolioQuestion, setFolioQuestion] = useState(false);
  const emailWarning = props?.errorStates?.email === MSR_ERROR_CONSTANTS?.EMAIL_WARNING;
  const phoneWarning = props?.errorStates?.phoneNumber === MSR_ERROR_CONSTANTS?.PHONE_NUMBER_WARNING;
  const handleRadioButtonKeyDown = (e: any) => {
    if (getEventKeyTypes(e)?.isEnterKey) {
      e.preventDefault(); // Prevent form submission when using Enter on the radio button
    }
  };

  return (
    <StyledStepOne>
      <div className="step-one mx-4 mx-md-5">
        {props?.formFields?.thirdPartyBooking === true && (
          <div className="warningcontainer mt-4 mx-0">
            <div className="row justify-content-flexstart">
              <div className="m-message-inline error-sev2 col-12 col-md-10">
                <div className="m-message-content-wrap">
                  <div className="m-message-content">
                    <span dangerouslySetInnerHTML={{ __html: authorModelData?.warningMessage }}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(emailWarning || phoneWarning) && props?.formFields?.thirdPartyBooking === false && (
          <div className="mt-4 mx-0">
            <div className="m-message-inline error-sev1">
              <div className="m-message-content-wrap">
                <div className="m-message-content">
                  <span>{props?.authorModelData?.warningHeader}</span>
                  <ul>
                    {emailWarning === true && <li>{props?.authorModelData?.emailAddressWarning}</li>}
                    {phoneWarning === true && <li>{props?.authorModelData?.phoneNumberWarning}</li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <h2 className="step-one__heading t-subtitle-l mt-3 mb-0" data-testid="step-1">
          {authorModelData?.stepOneSectionTitle}
        </h2>

        <div
          className="step-one__question my-4"
          aria-label={`${authorModelData?.radioButtonGroupLabel}`}
          role="radiogroup"
        >
          <div className="t-font-m step1-question" data-testid="step1-question">
            {authorModelData?.questionOne}
          </div>
          <div className="step-one__question--radiobutton d-flex pt-3">
            <div className="m-radio-button-sm pr-5 mr-3 ">
              <input
                type="radio"
                id="yes"
                value="true"
                name="thirdPartyBooking"
                role="radio"
                data-testid="yes"
                checked={props?.formFields?.thirdPartyBooking === true}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props?.setFormFields({
                    ...props?.formFields,
                    thirdPartyBooking: event.target.value === 'true',
                    digitalBillSelection: false,
                    paperBillSelection: false,
                    noBillSelection: false,
                    scannedCopySelection: false,
                    faxOrMailSelection: false,
                  });
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault();
                    event.stopPropagation();
                    props?.setFormFields({ ...props?.formFields, thirdPartyBooking: true });
                  }
                }}
                aria-checked={props?.formFields?.thirdPartyBooking === true}
              />
              <label htmlFor="yes" aria-label={`${authorModelData?.radioButtonOneLabel}`}>
                {authorModelData?.radioButtonOneLabel}
              </label>
            </div>
            <div className="m-radio-button-sm">
              <input
                type="radio"
                id="no"
                value="false"
                name="thirdPartyBooking"
                role="radio"
                data-testid="no"
                checked={props?.formFields?.thirdPartyBooking === false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props?.setFormFields({ ...props?.formFields, thirdPartyBooking: event.target.value === 'true' });
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault();
                    event.stopPropagation();
                    props?.setFormFields({ ...props?.formFields, thirdPartyBooking: false });
                  }
                }}
                aria-checked={props?.formFields?.thirdPartyBooking === false}
              />
              <label htmlFor="no" aria-label={`${authorModelData?.radioButtonTwoLabel}`}>
                {authorModelData?.radioButtonTwoLabel}
              </label>
            </div>
          </div>
        </div>
        {!props?.fieldNameReverse ? (
          //firstName and lastName
          <div
            className={clsx(
              'step-one__row',
              'row',
              'mb-0',
              'mb-md-4',
              props?.formFields?.thirdPartyBooking !== false ? 'blur' : ''
            )}
          >
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <div
                className={clsx(
                  'm-input-field',
                  props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : '',
                  props?.errorStates?.firstName ? 'is-error' : '',
                  props?.formFields?.firstName ? 'is-active' : ''
                )}
              >
                <label htmlFor="firstname">{authorModelData?.firstNameFieldLabel}</label>
                <input
                  autoComplete="off"
                  type="text"
                  id="firstname"
                  maxLength={20}
                  style={{ caretColor: baseVariables.color['base10'] }}
                  data-testid="fname"
                  value={props?.formFields?.firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props?.setFormFields({
                      ...props?.formFields,
                      firstName: event.target.value,
                      folioStayAndPayApplicable: true,
                    });
                    setFolioQuestion(true);
                  }}
                  disabled={props?.formFields?.thirdPartyBooking !== false}
                />
                {props?.errorStates?.firstName && (
                  <div className="t-label-s validation-msg">{authorModelData?.firstNameErrorMessage}</div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <div
                className={clsx(
                  'm-input-field',
                  props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : '',
                  props?.errorStates?.lastName ? 'is-error' : '',
                  props?.formFields?.lastName ? 'is-active' : ''
                )}
              >
                <label htmlFor="lastname">{authorModelData?.lastNameFieldLabel}</label>
                <input
                  autoComplete="off"
                  type="text"
                  id="lastname"
                  maxLength={25}
                  data-testid="lname"
                  value={props?.formFields?.lastName}
                  style={{ caretColor: baseVariables.color['base10'] }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props?.setFormFields({
                      ...props?.formFields,
                      lastName: event.target.value,
                      folioStayAndPayApplicable: true,
                    });
                    setFolioQuestion(true);
                  }}
                  disabled={props?.formFields?.thirdPartyBooking !== false}
                />
                {props?.errorStates?.lastName && (
                  <div className="t-label-s validation-msg">{authorModelData?.lastNameErrorMessage}</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          //lastNmae and firstName
          <div
            className={clsx(
              'step-one__row',
              'row',
              'mb-0',
              'mb-md-4',
              props?.formFields?.thirdPartyBooking !== false ? 'blur' : ''
            )}
          >
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <div
                className={clsx(
                  'm-input-field',
                  props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : '',
                  props?.errorStates?.lastName ? 'is-error' : '',
                  props?.formFields?.lastName ? 'is-active' : ''
                )}
              >
                <label htmlFor="lastname">{authorModelData?.lastNameFieldLabel}</label>
                <input
                  autoComplete="off"
                  type="text"
                  id="lastname"
                  maxLength={25}
                  data-testid="lname"
                  value={props?.formFields?.lastName}
                  style={{ caretColor: baseVariables.color['base10'] }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props?.setFormFields({
                      ...props?.formFields,
                      lastName: event.target.value,
                      folioStayAndPayApplicable: true,
                    });
                    setFolioQuestion(true);
                  }}
                  disabled={props?.formFields?.thirdPartyBooking !== false}
                />
                {props?.errorStates?.lastName && (
                  <div className="t-label-s validation-msg">{authorModelData?.lastNameErrorMessage}</div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <div
                className={clsx(
                  'm-input-field',
                  props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : '',
                  props?.errorStates?.firstName ? 'is-error' : '',
                  props?.formFields?.firstName ? 'is-active' : ''
                )}
              >
                <label htmlFor="firstname">{authorModelData?.firstNameFieldLabel}</label>
                <input
                  autoComplete="off"
                  type="text"
                  id="firstname"
                  maxLength={20}
                  style={{ caretColor: baseVariables.color['base10'] }}
                  data-testid="fname"
                  value={props?.formFields?.firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props?.setFormFields({
                      ...props?.formFields,
                      firstName: event.target.value,
                      folioStayAndPayApplicable: true,
                    });
                    setFolioQuestion(true);
                  }}
                  disabled={props?.formFields?.thirdPartyBooking !== false}
                />
                {props?.errorStates?.firstName && (
                  <div className="t-label-s validation-msg">{authorModelData?.firstNameErrorMessage}</div>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className={clsx(
            'step-one__row',
            'row',
            'mb-0',
            'mb-md-4',
            props?.formFields?.thirdPartyBooking !== false ? 'blur' : ''
          )}
        >
          <div className="col-12 col-md-6 mb-5 mb-md-0">
            <div
              className={clsx(
                'm-input-field',
                props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : '',
                props?.errorStates?.phoneNumber ? 'is-error' : '',
                props?.formFields?.phoneNumber ? 'is-active' : ''
              )}
            >
              <label htmlFor="phonenumber">{authorModelData?.phoneNumberFieldLabel}</label>
              <input
                type="text"
                name="phoneNumber"
                id="phonenumber"
                data-testid="phone"
                maxLength={16}
                style={{ caretColor: baseVariables.color['base10'] }}
                value={props?.formFields?.phoneNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props?.setFormFields({ ...props?.formFields, phoneNumber: event.target.value });
                }}
                disabled={props?.formFields?.thirdPartyBooking !== false}
              />
              {props?.errorStates?.phoneNumber &&
                (props?.formFields?.phoneNumber?.trim()?.length === 0 ? (
                  <div className="t-label-s validation-msg">{authorModelData?.phoneNumberErrorMessage}</div>
                ) : (
                  props?.formFields?.phoneNumber?.trim()?.length < 7 && (
                    <div className="t-label-s validation-msg">{authorModelData?.phoneNumberWarning}</div>
                  )
                ))}
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5 mb-md-0">
            <div
              className={clsx(
                'm-input-field',
                props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : '',
                props?.errorStates?.email ? 'is-error' : '',
                props?.formFields?.emailAddress ? 'is-active' : ''
              )}
            >
              <label htmlFor="emailaddress">{authorModelData?.emailAddressFieldLabel}</label>
              <input
                autoComplete="off"
                type="email"
                name="emailAddress"
                id="emailaddress"
                data-testid="email"
                maxLength={80}
                style={{ caretColor: baseVariables.color['base10'] }}
                value={props?.formFields?.emailAddress}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props?.setFormFields({ ...props?.formFields, emailAddress: event.target.value });
                }}
                disabled={props?.formFields?.thirdPartyBooking !== false}
              />
              {props?.errorStates?.email &&
                (props?.formFields?.emailAddress?.trim()?.length === 0 ? (
                  <div className="t-label-s validation-msg">{authorModelData?.emailAddressErrorMessage}</div>
                ) : (
                  props?.formFields?.emailAddress?.trim()?.length < 5 && (
                    <div className="t-label-s validation-msg">{authorModelData?.emailAddressWarning}</div>
                  )
                ))}
            </div>
          </div>
        </div>

        {showFolioQuestion && props?.formFields?.thirdPartyBooking !== true && (
          <div
            className="step-one__folioquestion mt-4 mb-5"
            role="radiogroup"
            aria-label={`${authorModelData?.optionGroupLabel}`}
          >
            <div className="t-font-m">{authorModelData?.folioQuestionStatement}</div>
            <div className="step-one__question--radiobutton d-flex pt-3">
              <div className="m-radio-button-sm pr-5 mr-3">
                <input
                  type="radio"
                  id="folio_yes"
                  value="true"
                  name="folioStayAndPayApplicable"
                  role="radio"
                  data-testid="folio_yes"
                  checked={props?.formFields?.folioStayAndPay === 'Yes'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props?.setFormFields({
                      ...props?.formFields,
                      folioStayAndPayApplicable: event.target.value === 'true',
                      folioStayAndPay: 'Yes',
                    });
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Enter' || event.key === ' ') {
                      event.preventDefault();
                      event.stopPropagation();
                      props?.setFormFields({
                        ...props?.formFields,
                        folioStayAndPayApplicable: true,
                      });
                    }
                  }}
                  aria-checked={props?.formFields?.folioStayAndPayApplicable === true}
                  aria-label={`${authorModelData?.optionOneLabel}`}
                />
                <label htmlFor="folio_yes">{authorModelData?.optionOneLabel}</label>
              </div>
              <div className="m-radio-button-sm">
                <input
                  type="radio"
                  id="folio_no"
                  value="false"
                  name="folioStayAndPayApplicable"
                  role="radio"
                  data-testid="folio_no"
                  checked={props?.formFields?.folioStayAndPay === 'No'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props?.setFormFields({
                      ...props?.formFields,
                      folioStayAndPayApplicable: event.target.value === 'false',
                      folioStayAndPay: 'No',
                    });
                  }}
                  onKeyDown={event => {
                    handleRadioButtonKeyDown(event);
                    props?.setFormFields({
                      ...props?.formFields,
                      folioStayAndPayApplicable: true,
                    });
                  }}
                  aria-checked={props?.formFields?.folioStayAndPayApplicable === false}
                />
                <label htmlFor="folio_no" aria-label={`${authorModelData?.optionTwoLabel}`}>
                  {authorModelData?.optionTwoLabel}
                </label>
              </div>
            </div>
          </div>
        )}
        {showFolioQuestion &&
          props?.formFields?.thirdPartyBooking !== true &&
          props?.formFields?.folioStayAndPay === 'No' && (
            <div className="warningcontainer mt-4 mx-0">
              <div className="row justify-content-flexstart">
                <div className="m-message-inline error-sev2 col-12 col-md-10">
                  <div className="m-message-content-wrap">
                    <div className="m-message-content">
                      <span dangerouslySetInnerHTML={{ __html: authorModelData?.secondWarningMessage }}></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </StyledStepOne>
  );
};
