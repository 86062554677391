/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useId, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  Text,
  Types,
  Heading,
  InputTextField,
  CheckBox,
  Button,
  Link,
  Messages,
  RichText,
} from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

import { clearSessionAndCookie, getCountryAndLangCode, getCurrentUrlParams, skipDecode } from '../../modules';
import { PageContext } from '../../modules';
import {
  constants,
  FCP_HURDLE_WITHOUT_ENDPOINT,
  CANCEL_REDIRECTION_URL,
  PROFILE_REDIRECTION_URL,
  BANNER_MSGS_KEYS,
  CHECKED_IN_MODAL_ID,
  MFA_OPTIONS_KEY,
  API_ERROR_KEY,
} from '../../modules/utils/constants/constants';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { handleRedirect, addSubDirectoryPrefix } from '../../modules';
import { Dropdown } from '../../molecules/Dropdown';
import { PasswordInput } from '../../molecules/PasswordInput';
import { StyledForceChangePassword } from './ForceChangePassword.styles';
import { ForceChangePasswordProps, FormData, FormError, CountryOption, FcpReqBody } from './ForceChangePassword.types';
import { encryptPassword } from '../../modules';

const initialFormData: FormData = {
  email: '',
  lastName: '',
  country: {
    value: '',
    id: '',
  },
  zipcode: '',
  currentPwrd: '',
  newPwrd: '',
  confirmPwrd: '',
};

export const ForceChangePassword: FC<ForceChangePasswordProps> = ({ model, authorModelData }) => {
  const passwordCondArr = [
    {
      condition: model?.pwrdCharCheck ?? '',
      isValid: false,
    },
    {
      condition: model?.pwrdLowerCaseCheck ?? '',
      isValid: false,
    },
    {
      condition: model?.pwrdUpperCaseCheck ?? '',
      isValid: false,
    },
    {
      condition: model?.pwrdSpecialCharCheck ?? '',
      isValid: false,
    },
  ];

  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const {
    ACCEPT_LANGUAGE: currentLocale,
    IS_PASSWORD_ENCRYPT: isEncryptionEnable,
    SKIP_DECODE_URL_FOR_PATH: skipDecodeForPaths,
  } = clientEnvVars;
  const inputId = useId();
  const checkBoxId = useId();
  const [errors, setErrors] = useState<FormError>();
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [showZipcodeField, setShowZipcodeField] = useState(false);
  const authorModel = model || authorModelData;
  const [defaultCountry, setDefaultCountry] = useState<CountryOption>();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [passwordConditions, setPasswordConditions] = useState(passwordCondArr);
  const [passwordValid, setPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const pageContext = useContext(PageContext);
  const isEAA = pageContext?.isEAASignIn;
  const isMobileAuthFeatureEnabled = pageContext?.isMobileAuthFeatureEnabled;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const localeCountryCode = countryCode || constants.USA_COUNTRY_CODE;
  const { setBannerMsgs, bannerId, setBannerId } = useBannerMessagesStore();
  const { setMemberDetailsUxl } = useAccountPersistentStore(state => state);
  const isCheckedIn = bannerId === CHECKED_IN_MODAL_ID;

  const publicKey = pageContext?.rsaPublicKey;

  const userName = sessionData?.emailAddress;
  // validate password

  const validatePassword = (value: string) => {
    const str = value;
    const passwordArr = [...passwordConditions];
    if (passwordArr[0]) {
      passwordArr[0].isValid = 8 <= str.length && str.length <= 20;
    }
    if (passwordArr[1]) {
      passwordArr[1].isValid = !!str.match(/[a-z]/g);
    }
    if (passwordArr[2]) {
      passwordArr[2].isValid = !!str.match(/[A-Z]/g);
    }
    if (passwordArr[3]) {
      passwordArr[3].isValid = !!str.match(/^(?=.*[0-9$!#&@?%=_])[A-Za-z0-9$!#&@?%=_]{1,}$/g);
    }
    setPasswordConditions([...passwordArr]);
  };

  // set the default country based on the localeCountryCode
  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      email: userName,
    }));
    if (authorModel && authorModel?.countryRegion && authorModel?.countryRegion.length) {
      const defCountry = authorModel?.countryRegion.find((country: CountryOption) =>
        isEAA && isMobileAuthFeatureEnabled
          ? country?.id === constants.CHINA_COUNTRY_CODE
          : country?.id?.toUpperCase() === localeCountryCode?.toUpperCase()
      );
      setDefaultCountry(defCountry);
    }
  }, []);

  // Verify that all password checks are true.
  useEffect(() => {
    const isPasswordValid = passwordConditions.reduce((acc, curr) => acc && curr.isValid, true);
    setPasswordValid(isPasswordValid);
  }, [passwordConditions]);

  // render the ZIP code field based on a specific countryCode.
  useEffect(() => {
    if (authorModel && authorModel?.countryRegion && authorModel?.countryRegion.length) {
      setFormData(prevData => ({
        ...prevData,
        country: defaultCountry,
      }));
      if (defaultCountry?.id === constants.CANADA_COUNTRY_CODE || defaultCountry?.id === constants.USA_COUNTRY_CODE) {
        setShowZipcodeField(true);
      }
    }
  }, [defaultCountry]);

  //Invoke this function when the dropdown selection changes.
  const handleCountryNameChange = (option: any) => {
    if (option.id === constants.CANADA_COUNTRY_CODE || option.id === constants.USA_COUNTRY_CODE) {
      setShowZipcodeField(true);
    } else {
      setShowZipcodeField(false);
    }
    setFormData(prevData => ({ ...prevData, country: { ...option } }));
  };

  // Ensure that the confirmation password matches the new password.
  const matchConfirmPassword = () => {
    let isValid = true;
    const errObj: any = {};
    if (formData?.newPwrd && formData?.confirmPwrd && formData?.newPwrd !== formData?.confirmPwrd) {
      errObj.newPwrdConfirmPwrdMismatchError = authorModel?.newPwrdConfirmPwrdMismatchErrMsg;
      isValid = false;
    } else {
      isValid = true;
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      ...errObj,
    }));
    setErrorsList((prevErrorsList: string[]) => [...prevErrorsList, ...(Object.values(errObj) as string[])]);
    return isValid;
  };

  const validateField = (value: string) => {
    return value == null || (typeof value === 'string' && value.trim().length === 0);
  };
  // validate fcpWithWithouthurdle fields
  const validateFcpWithWithoutHurdleFields = () => {
    const newErrors: any = {};
    let isValid = true;
    if (sessionData?.isFCPWithoutHurdle === 'true') {
      if (validateField(formData?.email || '')) {
        newErrors.emailError = authorModel?.emailError;
        isValid = false;
      }
    }
    if (sessionData?.isFCPWithHurdle === 'true') {
      if (validateField(formData?.lastName || '')) {
        newErrors.lastNameError = authorModel?.lastNameError;
        isValid = false;
      }
      if (showZipcodeField && validateField(formData?.zipcode || '')) {
        newErrors.zipCodeError = authorModel?.zipCodeError;
        isValid = false;
      }
    }

    if (validateField(formData?.currentPwrd || '')) {
      newErrors.currentPwrdError = authorModel?.currentPwrdError;
      isValid = false;
    }
    if (validateField(formData?.newPwrd || '')) {
      newErrors.newPwrdError = authorModel?.newPwrdError;
      isValid = false;
    }
    if (validateField(formData?.confirmPwrd || '')) {
      newErrors.confirmPwrdError = authorModel?.confirmPwrdError;
      isValid = false;
    }
    setErrors(newErrors);
    setErrorsList(Object.values(newErrors));
    return matchConfirmPassword() && isValid;
  };
  // submit the user data
  const handleFCPFormSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const urlParams = new URLSearchParams(getCurrentUrlParams());
    const redirectUrl = urlParams.get('returnTo') || PROFILE_REDIRECTION_URL;
    let reqBody: FcpReqBody = {
      userName: formData?.email || '',
      oldPwrd: encryptPassword(formData.currentPwrd, publicKey, isEncryptionEnable),
      newPwrd: encryptPassword(formData.newPwrd, publicKey, isEncryptionEnable),
      confirmNewPwrd: encryptPassword(formData.newPwrd, publicKey, isEncryptionEnable),
      rememberMe: rememberMe,
      returnUrl: redirectUrl,
      errorUrl: isCheckedIn ? PROFILE_REDIRECTION_URL : '',
    };
    if (sessionData?.isFCPWithHurdle === 'true') {
      reqBody = {
        ...reqBody,
        lastName: formData?.lastName,
        country: formData?.country?.id,
        postalCode: formData?.zipcode,
      };
    }
    try {
      if (!validateFcpWithWithoutHurdleFields()) return;
      const response = await axios.post(addSubDirectoryPrefix(FCP_HURDLE_WITHOUT_ENDPOINT), reqBody, {
        headers: {
          'Content-Type': 'application/json',
          Cookie: 'sessionID=' + sessionData?.sessionToken,
          isEAA: isEAA ?? '',
        },
      });
      if (response) {
        if (response?.data?.customerDetails) {
          clearSessionAndCookie();
          setMemberDetailsUxl?.(response?.data?.customerDetails);
        }
        if (response?.status === 200) {
          if (
            response.data?.mfaOption &&
            (response.data?.mfaOption?.emailAddress || response.data?.mfaOption?.phoneNumbers?.length > 0)
          ) {
            sessionStorage.setItem(MFA_OPTIONS_KEY, JSON.stringify(response.data?.mfaOption));
          }
          const { nextStateURI } = response.data;
          if (isCheckedIn) {
            setBannerMsgs(constants?.RESERVATION_LINKED);
            setBannerId('');
          } else {
            setBannerMsgs(BANNER_MSGS_KEYS.PASSWORD_UPDATED_MSG);
          }
          const shouldSkipDecode = skipDecode(nextStateURI, skipDecodeForPaths);
          handleRedirect(nextStateURI || addSubDirectoryPrefix(PROFILE_REDIRECTION_URL), false, !shouldSkipDecode);
        }
      }
    } catch (error: any) {
      if (error) {
        const errorBody = error?.response?.data?.phoenixErrorMessages?.errorMessages;
        const errorObject: any = {};
        if (error?.response?.data?.isRedirect) {
          /** redrict user to next response url */
          if (isCheckedIn) {
            setBannerMsgs(errorBody?.errorMessages?.[0]);
            setBannerId('');
          }
          handleRedirect(error?.response?.data?.nextStateURI, false);
        } else {
          setIsLoading(false);
          if (errorBody?.length) {
            errorBody.forEach((key: string) => {
              const errorString = key === API_ERROR_KEY ? pageContext?.uxlErrorMessage : authorModel[key];
              errorObject[key] = errorString;
            });
          } else {
            errorObject[API_ERROR_KEY] = pageContext?.uxlErrorMessage;
          }
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          ...errorObject,
        }));
        setErrorsList((prevErrorsList: string[]) => [...prevErrorsList, ...(Object.values(errorObject) as string[])]);
      }
    }
  };

  const handleKeyPress = (ev: any) => {
    /**
     * handle key press event on input field that will submit the
     * form
     */
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      handleFCPFormSubmit(ev);
    }
  };
  return (
    <StyledForceChangePassword data-testid="forcechangepassword" data-component-name="o-account-forcechangepassword">
      <div className="container">
        <div className="col-md-8 col-lg-5">
          <Heading
            variation={Types.headingType.title}
            titleText={authorModel?.fcpPageTitle}
            customClass="t-title-s mt-5 pt-4"
          />
          <Text
            copyText={authorModel?.protectYourAccountLabel}
            fontSize={Types.size.large}
            customClass="t-subtitle-m mt-5"
            element={Types.tags.paragraph}
          />
          <Text
            copyText={authorModel?.pageDescription}
            fontSize={Types.size.small}
            customClass="t-font-s mt-2"
            element={Types.tags.paragraph}
          />

          {/* warning message */}
          {!!errorsList?.length && (
            <div className="">
              <Messages messageType="error-sev1" className="mt-3 account-page-error-msg mb-4 px-2">
                {errors?.uxlFailureErrorMessage ? (
                  <RichText
                    text={pageContext?.uxlErrorMessage}
                    componentId={'uxl-failure-msg'}
                    customClass="uxl-error-msg"
                  />
                ) : (
                  <>
                    <Text
                      copyText={authorModel?.errorMessageFCPHurdle}
                      fontSize={Types.size.small}
                      customClass="t-subtitle-m mt-5"
                      element={Types.tags.span}
                    />
                    {errorsList.map(error => (
                      <ul>
                        <li>{error}</li>
                      </ul>
                    ))}
                  </>
                )}
              </Messages>
            </div>
          )}
          {/* formdata */}
          <form method="post" onSubmit={handleFCPFormSubmit}>
            <div>
              {sessionData?.isFCPWithoutHurdle === 'true' && (
                <InputTextField
                  type={'email'}
                  label={authorModel?.emailLabel}
                  inputValue={userName}
                  inputMaxLength={20}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-email`,
                  })}
                  showErrorMessage={errors?.emailError !== undefined}
                  messageToShow={errors?.emailError}
                  setErrorHtml={true}
                  className="m-input-field mt-5" //is-error
                  showIcon={true}
                  messageClass="error-label m-0 t-label-s"
                  iconClass={''}
                  getInputValue={() => {
                    setFormData({ ...formData, email: userName });
                  }}
                  getInputProps={() => ({
                    /** setReadOnly props for remeber case */
                    readOnly: true,
                    autoComplete: 'off',
                    id: `${inputId}-email`,
                  })}
                />
              )}
              {((!sessionData?.isFCPWithHurdle && !sessionData?.isFCPWithoutHurdle) ||
                sessionData?.isFCPWithHurdle === 'true') && (
                <>
                  <InputTextField
                    type={'text'}
                    label={authorModel?.lastNameLabel}
                    inputValue={formData?.lastName}
                    inputMaxLength={20}
                    getLabelProps={() => ({
                      htmlFor: `${inputId}-lastname`,
                    })}
                    showErrorMessage={errors?.lastNameError !== undefined}
                    messageToShow={errors?.lastNameError}
                    setErrorHtml={true}
                    messageClass="error-label m-0 t-label-s"
                    className="m-input-field mt-5" //is-error
                    showIcon={true}
                    iconClass={''}
                    getInputValue={(val: string) => {
                      setFormData({ ...formData, lastName: val });
                    }}
                    getInputProps={() => ({
                      /** setReadOnly props for remeber case */
                      autoComplete: 'off',
                      onKeyUp: handleKeyPress,
                      id: `${inputId}-lastname`,
                    })}
                  />
                  <div className="m-input-field mt-5 pb-2 is-active">
                    <label className="country-label" id="dropdown-label-fp-country" htmlFor="dropdown-label-fp-country">
                      {authorModel?.countryRegionLabel}
                    </label>
                    <Dropdown
                      defaultOption={formData?.country?.value || (authorModel?.countryRegion[0]?.value ?? '')}
                      dropdownOptions={authorModel?.countryRegion ?? []}
                      onChange={handleCountryNameChange}
                      customClassName="activate_account--countrydropdown is-active"
                      dropdownId="fp-country"
                    />
                  </div>
                  {showZipcodeField && (
                    <InputTextField
                      type={'text'}
                      label={authorModel?.zipPostalCodeLabel}
                      inputValue={formData?.zipcode}
                      inputMaxLength={20}
                      getLabelProps={() => ({
                        htmlFor: `${inputId}-zipcode`,
                      })}
                      showErrorMessage={errors?.zipCodeError !== undefined}
                      messageToShow={errors?.zipCodeError}
                      setErrorHtml={true}
                      messageClass="error-label t-label-s m-0"
                      className="m-input-field mt-5" //is-error
                      showIcon={true}
                      iconClass={''}
                      getInputValue={(val: string) => {
                        setFormData({ ...formData, zipcode: val });
                      }}
                      getInputProps={() => ({
                        /** setReadOnly props for remeber case */
                        autoComplete: 'off',
                        onKeyUp: handleKeyPress,
                        id: `${inputId}-zipcode`,
                      })}
                    />
                  )}
                </>
              )}

              <PasswordInput
                value={formData.currentPwrd}
                id="password"
                name="password"
                disable={false}
                hasError={!!errors?.currentPwrdError}
                label={authorModel?.currentPwrdLabel || ''}
                passwordType={showCurrentPassword ? 'text' : 'password'}
                error={errors?.currentPwrdError}
                customClass="mt-5"
                iconClass={clsx(!showCurrentPassword ? `icon-visibility` : 'icon-visibility-off')}
                labelClass="t-body-s"
                showValidations={true}
                handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                  setFormData({ ...formData, currentPwrd: e.target.value });
                }}
                onIconClick={() => setShowCurrentPassword(!showCurrentPassword)}
                isValid={passwordValid}
                showCheckIcon={false}
              />
              <PasswordInput
                value={formData.newPwrd}
                id="newpassword"
                name="newpassword"
                disable={false}
                hasError={!!errors?.newPwrdError}
                label={authorModel?.newPwrdLabel || ''}
                passwordType={showNewPassword ? 'text' : 'password'}
                error={errors?.newPwrdError}
                customClass="mt-5"
                iconClass={clsx(!showNewPassword ? `icon-visibility` : 'icon-visibility-off')}
                labelClass="t-body-s"
                showValidations={true}
                handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                  setFormData({ ...formData, newPwrd: e.target.value });
                  validatePassword(e.target.value);
                }}
                onIconClick={() => setShowNewPassword(!showNewPassword)}
                isValid={passwordValid}
                passwordConditions={passwordConditions}
                showCheckIcon={true}
              />

              <PasswordInput
                value={formData.confirmPwrd}
                id="confirmpassword"
                name="confirmpassword"
                disable={!passwordValid && true}
                hasError={(!!errors?.confirmPwrdError && passwordValid) || !!errors?.newPwrdConfirmPwrdMismatchError}
                label={authorModel?.confirmPwrdLabel || ''}
                passwordType={showConfirmPassword ? 'text' : 'password'}
                error={errors?.confirmPwrdError ?? errors?.newPwrdConfirmPwrdMismatchError}
                customClass="mt-5"
                iconClass={clsx(!showConfirmPassword ? `icon-visibility` : 'icon-visibility-off')}
                labelClass="t-body-s"
                showValidations={true}
                handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                  setFormData({ ...formData, confirmPwrd: e.target.value });
                }}
                onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
                isValid={formData?.confirmPwrd?.length && formData?.confirmPwrd === formData?.newPwrd ? true : false}
                showCheckIcon={true}
              />

              <div className="form-field-contaioner mb-md-5 mb-4 mt-5">
                <CheckBox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setRememberMe(e.target.checked);
                  }}
                  checked={rememberMe}
                  checkboxName="remember_me"
                  checkboxLabel={authorModel?.rememberMeLabel}
                  checkboxId={checkBoxId}
                  data-testid="checkbox"
                />
              </div>
              {!(sessionData?.isFCPWithoutHurdle === 'true') && (
                <Text
                  copyText={authorModel?.cancelButtonDescription}
                  fontSize={Types.size.small}
                  customClass="t-font-s mt-5"
                  element={Types.tags.paragraph}
                />
              )}
              <div className="col-12 d-flex align-items-center flex-column flex-md-row mt-5 px-0 space-bottom">
                <Button
                  testId="submitbtn"
                  className="m-button-m m-button-primary custom-btn submit_btn"
                  type={Types.ButtonTypeVariation.Submit}
                  isDisabled={isLoading}
                >
                  {isLoading ? (
                    <div className="m-spinner-s" data-testid="change-password-spinner"></div>
                  ) : (
                    authorModel?.submitButtonLabel
                  )}
                </Button>
                <Link
                  text={authorModel?.cancelButtonLabel}
                  linkClassName="m-link-action mt-4 mt-md-0 ml-md-5"
                  linkHref={addSubDirectoryPrefix(CANCEL_REDIRECTION_URL)}
                  target="_self"
                  linkType="internal"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </StyledForceChangePassword>
  );
};

export const ForceChangePasswordConfig = {
  emptyLabel: 'forcechangepassword',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/forcechangepassword`,
};
export const ForceChangePasswordEditable = (props: any) => {
  return (
    <EditableComponent config={ForceChangePasswordConfig} {...props}>
      <ForceChangePassword {...props} />
    </EditableComponent>
  );
};
