/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';

export interface RandData {
  firstName: string;
  lastName: string;
  orderId: string;
  checkInDate: string;
}

interface AccountCommonState {
  rememberedUser: string;
  setRememberedUser: (name: string) => void;
  randData?: RandData;
  setRandData: (data: RandData) => void;
  rememberDetails: {
    status: string;
    headerSubtext: {
      image: {
        altText: string;
        src: string;
      };
      consumerName: string;
      class: string;
    };
    rememberedUser: boolean;
    memberNumber: string;
    pwdPublicKey: string;
  };
  setRememberDetails: (value: AccountCommonState['rememberDetails']) => void;
}
const initialState: AccountCommonState = {
  rememberedUser: '',
  setRememberedUser: () => {},
  randData: undefined,
  setRandData: () => {},
  rememberDetails: {
    status: '',
    headerSubtext: {
      image: {
        altText: '',
        src: '',
      },
      consumerName: '',
      class: '',
    },
    rememberedUser: false,
    memberNumber: '',
    pwdPublicKey: '',
  },
  setRememberDetails: () => {},
};

const accountCommonStore: StateCreator<AccountCommonState> = set => {
  return {
    ...initialState,
    setRememberedUser: (name: string) => {
      set({ rememberedUser: name });
    },
    setRandData: (data: RandData) => {
      set({ randData: { ...data } });
    },
    setRememberDetails: (value: AccountCommonState['rememberDetails']) => {
      set({ rememberDetails: value });
    },
  };
};

export const useAccountCommonStore = createAppStore(accountCommonStore, {
  usePersistentStore: false,
});
