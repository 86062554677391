// Styles for RegisteredPromotions go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledRegisteredPromotions = styled.div`
  .mt-registered-promo {
    margin-top: ${toRem(40)};
    @media ${baseVariables.mediaQuery.sm} {
      margin-top: ${toRem(64)};
    }
  }
  .promotion {
    &__skeleton {
      background-color: ${baseVariables.color['alert10']} !important;
      border-radius: ${toRem(14)};
      box-sizing: border-box;
      box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
      &__section {
        width: 100%;
        &__desc {
          width: 50%;
          height: ${toRem(24)};
        }
        &__label {
          width: 70%;
          height: ${toRem(24)};
        }
        &__progress {
          width: 100%;
          height: ${toRem(10)};
          border-radius: ${toRem(32)};
        }
        &__btn {
          width: ${toRem(144)};
          height: ${toRem(34)};
        }
      }
      &__img {
        width: ${toRem(200)};
        height: ${toRem(160)};
        border-radius: ${toRem(14)};
      }
    }
    &__mb {
      margin-bottom: ${toRem(56)};
    }
  }
`;
