/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { ReportMissingStayLink } from './ReportMissingStayLink';
import { StyledReportMissingStayLinkSection } from './ReportMissingStayLinkSection.styles';

export const ReportMissingStayLinkSection: React.FC<{ model: any }> = pageProps => {
  return (
    <StyledReportMissingStayLinkSection
      data-testid="missingStayRequest"
      data-component-name="o-account-missingStayRequest"
    >
      <div>
        <div className={clsx(`pt-4 pb-5 container`)}>
          <div className="pb-4 pb-md-5 pb-lg-5 pt-md-3 pt-lg-3">
            <div className="t-font-m pb-3">{pageProps?.model?.description}</div>
            <div>
              <ReportMissingStayLink ctaLabel={pageProps?.model?.ctaLabel} ctaLink={pageProps?.model?.ctaPath} />
            </div>
          </div>
        </div>
      </div>
    </StyledReportMissingStayLinkSection>
  );
};

export const ReportMissingStayLinkSectionConfig = {
  emptyLabel: 'ReportMissingStay',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/missingStayRequest`,
};

export const ReportMissingStayLinkSectionEditable = (props: any) => (
  <EditableComponent config={ReportMissingStayLinkSectionConfig} {...props}>
    <ReportMissingStayLinkSection {...props} />
  </EditableComponent>
);

// export default ReportMissingStayEditable;
