import React, { FC, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BrandFilter } from '@marriott/mi-shop-components/src/molecules';
import { Types, RadioButton, CheckBox, Heading } from '@marriott/mi-ui-library';
import { ToggleSwitch } from '../../../molecules/ToggleSwitch';
import { constants } from '../../../modules/utils/constants/constants';
import EliteMock from '../__mock__/AmbassadorElitePreferences.model.json';
import {
  AmbassadorServiceProps,
  ConvertedBrandCategories,
  FormattedBrands,
  DefaultBrands,
  ModifiedBrandCategories,
  ElitePreferencesData,
  PersonalItems,
  ItemList,
} from './AmbassadorServiceSection.types';
import { StyledAmbassadorServiceSection } from './AmbassadorServiceSection.styles';

export const AmbassadorServiceSection: FC<AmbassadorServiceProps> = ({
  model,
  customerAmbassadorData,
  isAuthorMode,
  onSubmitFlag,
  onHandleFlag,
  profileModalId,
}) => {
  const [contactOption, setContactOption] = useState('');
  const [selectedTab, setSelectedTab] = useState(constants.TAB_BUSINESS);
  const [featureItemsChecked, setFeatureItemsChecked] = useState<PersonalItems>({});
  const [selectedBusinessBrands, setSelectedBusinessBrands] = useState<FormattedBrands[]>([]);
  const [selectedLeisureBrands, setSelectedLeisureBrands] = useState<FormattedBrands[]>([]);
  const [selectedBusinessBrandTags, setSelectedBusinessBrandTags] = useState<string[]>([]);
  const [selectedLeisureBrandTags, setSelectedLeisureBrandTags] = useState<string[]>([]);

  useEffect(() => {
    if (isAuthorMode) {
      loadCheckedItems(EliteMock?.data?.customer?.featuresByGroup?.ambassadorPreferences);
    }
  }, []);

  useEffect(() => {
    if (!isAuthorMode && customerAmbassadorData && profileModalId === 'ambassador-elite-preferences') {
      loadCheckedItems(customerAmbassadorData);
    }
  }, [customerAmbassadorData, profileModalId]);

  const loadCheckedItems = (elitePreferences: ElitePreferencesData) => {
    setContactOption(elitePreferences?.contactMethod?.code);
    const contactTimes = elitePreferences?.bestContactTimes?.contactTimes;
    const checkedItems = {};
    model?.timeList?.forEach(item => {
      (checkedItems as { [key: string]: boolean })[item.id] = contactTimes?.some(
        (contactTime: { code: string }) => contactTime.code === item.id
      );
    });
    setFeatureItemsChecked(checkedItems);
    const defaultSelectedBusinessBrands = elitePreferences?.favoriteBusinessBrands?.businessBrands;
    const defaultSelectedLeisureBrands = elitePreferences?.favoriteLeisureBrands?.leisureBrands;
    const formattedBusinessBrands = defaultSelectedBusinessBrands?.map((brand: DefaultBrands) => ({
      brandTagId: brand.code,
      brandTagTitle: brand.value,
    }));
    const formattedLeisureBrands = defaultSelectedLeisureBrands?.map((brand: DefaultBrands) => ({
      brandTagId: brand.code,
      brandTagTitle: brand.value,
    }));

    setSelectedBusinessBrandTags(formattedBusinessBrands?.map((brand: FormattedBrands) => brand.brandTagId));
    setSelectedBusinessBrands(formattedBusinessBrands);
    setSelectedLeisureBrandTags(formattedLeisureBrands?.map((brand: FormattedBrands) => brand.brandTagId));
    setSelectedLeisureBrands(formattedLeisureBrands);
  };

  const updateFeatureItem = (id: string) => {
    setFeatureItemsChecked(prevChecked => ({
      ...prevChecked,
      [id]: !prevChecked[id],
    }));
  };

  const modifyBrandCategories = (arr: ModifiedBrandCategories[]) => {
    return arr?.map(category => {
      return {
        ...category,
        categorySelectLabel: '',
        categoryClearLabel: '',
      };
    });
  };

  const handleChange = (selectedValues: FormattedBrands[]) => {
    if (selectedTab === constants.TAB_BUSINESS) {
      setSelectedBusinessBrandTags(selectedValues?.map(item => item.brandTagId));
    } else if (selectedTab === constants.TAB_LEISURE) {
      setSelectedLeisureBrandTags(selectedValues?.map(item => item.brandTagId));
    }
  };

  const formattedBusinessCategories = useMemo(() => {
    const data = model?.brandCategories;
    return data?.map(category => {
      return {
        categoryTitle: category.categoryTitle,
        brands: category.brands?.map(brand => ({
          brandTagId: brand.id,
          brandTagTitle: brand.value,
        })),
      };
    });
  }, [model?.brandCategories]);

  const formattedLeisureCategories = useMemo(() => {
    const data = model?.brandCategories;
    return data?.map(category => {
      return {
        categoryTitle: category.categoryTitle,
        brands: category.brands?.map(brand => ({
          brandTagId: brand.id,
          brandTagTitle: brand.value,
        })),
      };
    });
  }, [model?.brandCategories]);

  const getModifiedCategories = (brandCategory: ConvertedBrandCategories[]) => {
    return brandCategory?.map(category => {
      return {
        ...category,
        brands: category.brands?.map(brand => {
          if (selectedTab === constants.TAB_BUSINESS) {
            const isSelected = selectedBusinessBrandTags?.some(tag => tag === brand.brandTagId);
            return {
              ...brand,
              isDisabled: !isSelected && selectedBusinessBrandTags?.length === constants.MAX_BRAND_TAGS,
            };
          } else {
            const isSelected = selectedLeisureBrandTags?.some(tag => tag === brand.brandTagId);
            return {
              ...brand,
              isDisabled: !isSelected && selectedLeisureBrandTags?.length === constants.MAX_BRAND_TAGS,
            };
          }
        }),
      };
    });
  };

  const modifiedBusinessCategories = getModifiedCategories(formattedBusinessCategories);
  const modifiedLeisureCategories = getModifiedCategories(formattedLeisureCategories);

  const tabList = [
    {
      tabTitle: model?.businessTravelLabel,
      tabSubtitle: '',
      tabValue: constants.TAB_BUSINESS,
    },
    {
      tabTitle: model?.leisureTravelLabel,
      tabSubtitle: '',
      tabValue: constants.TAB_LEISURE,
    },
  ];

  const handleTabSelect = (selectedTabValue: string) => {
    setSelectedTab(selectedTabValue);
  };

  const tabContent = (
    <>
      <div className="pb-5" tabIndex={0} role="generic">
        {selectedTab === constants.TAB_BUSINESS ? model?.businessTravelInstruction : model?.leisureTravelInstruction}
      </div>
      {selectedTab === constants.TAB_BUSINESS ? (
        <BrandFilter
          title={''}
          showSeparator={false}
          brandCategories={modifyBrandCategories(modifiedBusinessCategories)}
          defaultSelected={selectedBusinessBrands}
          onChange={handleChange}
          hideLabelSeperator={true}
          categoryLabelFontClass={'t-font-m'}
        />
      ) : (
        <BrandFilter
          title={''}
          showSeparator={false}
          brandCategories={modifyBrandCategories(modifiedLeisureCategories)}
          defaultSelected={selectedLeisureBrands}
          onChange={handleChange}
          hideLabelSeperator={true}
          categoryLabelFontClass={'t-font-m'}
        />
      )}
    </>
  );

  // mutation

  const isDataChanged = () => {
    const contactOptionChange = customerAmbassadorData?.contactMethod?.code !== contactOption;
    const checkedItems = {};
    model?.timeList?.forEach(item => {
      (checkedItems as { [key: string]: boolean | undefined })[item.id] =
        customerAmbassadorData?.bestContactTimes?.contactTimes?.some(
          (contactTime: { code: string }) => contactTime?.code === item.id
        );
    });

    const checkboxItemsCheck = JSON.stringify(checkedItems) !== JSON.stringify(featureItemsChecked);
    const defaultSelectedBusinessBrands = customerAmbassadorData?.favoriteBusinessBrands?.businessBrands;
    const defaultSelectedLeisureBrands = customerAmbassadorData?.favoriteLeisureBrands?.leisureBrands;
    const formattedBusinessBrands = defaultSelectedBusinessBrands?.map((brand: DefaultBrands) => ({
      brandTagId: brand.code,
      brandTagTitle: brand.value,
    }));
    const formattedLeisureBrands = defaultSelectedLeisureBrands?.map((brand: DefaultBrands) => ({
      brandTagId: brand.code,
      brandTagTitle: brand.value,
    }));
    const businessBrandsCheck = JSON.stringify(formattedBusinessBrands) !== JSON.stringify(selectedBusinessBrands);
    const leisureBrandsCheck = JSON.stringify(formattedLeisureBrands) !== JSON.stringify(selectedLeisureBrands);
    const prevBusinessBrandtags = formattedBusinessBrands?.map((brand: FormattedBrands) => brand.brandTagId);
    const businessBrandtagsCheck = JSON.stringify(prevBusinessBrandtags) !== JSON.stringify(selectedBusinessBrandTags);
    const prevLeisureBrandtags = formattedLeisureBrands?.map((brand: FormattedBrands) => brand.brandTagId);
    const leisureBrandtagsCheck = JSON.stringify(prevLeisureBrandtags) !== JSON.stringify(selectedLeisureBrandTags);

    return (
      contactOptionChange ||
      checkboxItemsCheck ||
      businessBrandsCheck ||
      leisureBrandsCheck ||
      businessBrandtagsCheck ||
      leisureBrandtagsCheck
    );
  };

  useEffect(() => {
    if (onSubmitFlag) {
      const ambassadorServiceDataChanged = isDataChanged();
      const ambassadorServicePayload = {
        contactMethod: contactOption,
        bestContactTime: Object.keys(featureItemsChecked).filter(key => featureItemsChecked[key]),
        favoriteBusinessBrands: selectedBusinessBrandTags,
        favoriteLeisureBrands: selectedLeisureBrandTags,
      };
      onHandleFlag(ambassadorServicePayload, ambassadorServiceDataChanged); // Call callback with data if flag is true
    }
  }, [onSubmitFlag]);

  return (
    <StyledAmbassadorServiceSection>
      <div className="" role="radiogroup" aria-labelledby="radio_group_label">
        <Heading
          variation={Types.headingType.subtitle}
          fontSize={Types.size.medium}
          titleText={model?.contactTitle}
          customClass={clsx('')}
          {...{ id: 'radio_group_label' }}
        />
        {model?.contactList?.map((contactOptions: ItemList, index) => {
          const { id, value } = contactOptions;
          return (
            <RadioButton
              tabIndexForInput={-1}
              radiobuttonId={value + index}
              radiobuttonName={value}
              radiobuttonLabel={value}
              ariaLabelForLink={value}
              value={id}
              className="pt-2 pr-3 pr-lg-5 mr-lg-5 pt-md-2 modal__container_radio"
              checked={contactOption === id}
              setTabIndex={0}
              key={id + index}
              onChange={() => {
                setContactOption(id);
              }}
              onKeyDown={event => event.key === 'Enter' && setContactOption(id)}
            />
          );
        })}
        {(contactOption === constants.CONTACT_OPT_PH || contactOption === constants.CONTACT_OPT_BOTH) && (
          <div role="group" aria-labelledby="checkboxgroup_label">
            <Heading
              variation={Types.headingType.subtitle}
              fontSize={Types.size.medium}
              titleText={model?.timeTitle}
              customClass={clsx('d-block mb-3 mt-3')}
              {...{ id: 'checkboxgroup_label' }}
            />
            {model?.timeList?.map((contactOptions: ItemList) => {
              const { id, value } = contactOptions;
              return (
                <CheckBox
                  onChange={() => updateFeatureItem(id)}
                  checked={featureItemsChecked[id]}
                  data-testid={id}
                  checkboxName="featureItem_checkbox"
                  checkboxLabel={value}
                  checkboxId={id}
                  key={id}
                  className="col-12 col-lg-4 t-font-m accordion-checkbox checkbox checkbox-feature_items pb-2"
                />
              );
            })}
          </div>
        )}
        <ToggleSwitch tabList={tabList} onTabSelect={handleTabSelect} />
        {tabContent}
      </div>
    </StyledAmbassadorServiceSection>
  );
};
