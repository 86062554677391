/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useState, useCallback, memo, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { RichText } from '@marriott/mi-ui-library';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.

import { AccountContainerProps, AccountErrorType, UserDetails } from './AccountContainer.types';
import { StyledAccountContainer } from './AccountContainer.styles';
import { MemoizedPageContentWithModal, MemoizedPageTitleAndAlertContent } from './AccountContainer.modal';
import {
  usePageContext,
  correctSubDirectoryPathForHeader,
  AccountModalEvent,
  isReservationModal,
  CHECKED_IN_MODAL_ID,
} from '../../modules';
import { useStore } from '../../modules/store/memberLevelStore';
import { PhoneDetails } from '../../molecules/ActivateOrForgotForm/ActivateOrForgotForm.types';
import { ActivateOrForgotForm } from '../../molecules/ActivateOrForgotForm/ActivateOrForgotForm';
import { Model } from '../FindReservationOverlay/FindReservationOverlay.types';

const OneClickJoinForm = dynamic(() => import('../../molecules/OneClickJoinForm').then(mod => mod.OneClickJoinForm));
const FindReservation = dynamic(() => import('../../molecules/FindReservation').then(mod => mod.FindReservation));
const SetPassword = dynamic(() => import('../../molecules/SetPassword').then(mod => mod.SetPassword));
const SignIn = dynamic(() => import('../../molecules/SignIn').then(mod => mod.SignIn));
const SignInContentBlock = dynamic(() =>
  import('../../molecules/SignInContentBlock').then(mod => mod.SignInContentBlock)
);
/**
 * NOTE:
 * <!-- publish "openAccountContainerSignInModal" to toggle the account signin modal   --->
 * e.g.
 *  eventUtill.dispatch('openAccountContainerSignInModal',publish<data>);
 */
// Use named rather than default exports.
export const AccountContainer: FC<AccountContainerProps> = ({ ...props }) => {
  const {
    isAuthorMode,
    cqItems,
    itemPath,
    pagePath,
    totalNumberOfCards,
    contentDescription,
    title,
    isAccountModal,
    variation,
    modalId,
    isDTT,
    trackingPageName,
  } = props ?? {};
  const [pageErrors, setPageErrors] = useState<AccountErrorType | undefined>();
  const [isActivatedSuccessful, setIsActivatedSuccessful] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [disablePasswordForm, setDisablePasswordForm] = useState(true);
  const [displayTypeOneError, setDisplayTypeOneError] = useState(false);
  const [typeTwoErrorHeader, setTypeTwoErrorHeader] = useState('');
  const [isSignIn, setIsSignIn] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails>({
    givenName: '',
    surName: '',
    memberName: '',
  });
  const [phoneDetails, setPhoneDetails] = useState<PhoneDetails>();
  const { isEAASignIn } = usePageContext(); // check if it is isaasign
  const { showCustomMessage } = useStore(state => state);
  const router = useRouter();
  const overlay = router && router.query['overlay'];
  let resLookupOverlay = false;

  for (const key in cqItems) {
    const molecule = cqItems?.[key]?.[':items'] ?? {};
    for (const moleculeKey in molecule) {
      if (moleculeKey?.split('_')[0]?.toLowerCase() === 'findreservation') {
        resLookupOverlay = true;
      }
    }
  }
  useEffect(() => {
    let signIn;
    signIn = false;
    for (const key in cqItems) {
      const molecule = cqItems?.[key]?.[':items'] ?? {};
      for (const moleculeKey in molecule) {
        if (moleculeKey?.split('_')[0]?.toLowerCase() === 'datapagecomponent') {
          const dataPageMolecule = molecule?.[moleculeKey]?.[':items'] ?? {};
          for (const dataPageMoleculeKey in dataPageMolecule) {
            if (dataPageMoleculeKey?.split('_')[0]?.toLowerCase() === 'signin') {
              signIn = true;
            }
          }
        } else if (moleculeKey?.split('_')[0]?.toLowerCase() === 'signin') {
          signIn = true;
        }
      }
    }
    setIsSignIn(signIn);
  }, [cqItems]);

  useEffect(() => {
    // debugger;
    if (overlay) {
      setIframeHeight();
    }
    /**
     * update the header path once page is loaded
     */
    if (!overlay) {
      // should run only for the pages
      correctSubDirectoryPathForHeader();
    }
  }, []);

  const setIframeHeight = () => {
    const iframe: any = window?.parent?.document?.getElementById(
      resLookupOverlay ? 'resLookupIframe' : 'signInOverlayIframe'
    );

    const iframeContent = iframe?.contentDocument?.scrollingElement?.scrollHeight;
    const paddingAround = 10 * 2;
    if (iframe && iframeContent) {
      iframe.style.height = `${iframeContent + paddingAround}px`;
    }
  };

  const memoZiedDisplayError = useCallback((obj: AccountErrorType) => {
    /**display error alert on page */
    setPageErrors(obj);
  }, []);

  const updateErrorState = useCallback((errArr: string[]) => {
    setPageErrors({
      text: '',
      class: '',
      type: '',
      errorList: errArr,
    });
  }, []);

  const parsysContainer = (index: number) => {
    /** create parsys container in author mode */
    return (
      <div className={clsx(isEAASignIn && isSignIn ? 'col-12' : 'col-6')}>
        <ResponsiveGrid
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pagePath}
          itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
          gridClassNames={'col-12'}
          config={{
            isEmpty: () => true,
            resourceType: `mi-aem-common-spa/components/container`,
          }}
        />
      </div>
    );
  };

  const componentRenderer = (compName: string, compLabels: Model | any, accountEvent?: AccountModalEvent | null) => {
    if (compName === 'signin') {
      /**1. is we have keys relteed to sign-in */
      return (
        <SignIn
          {...compLabels}
          updatePageErrors={memoZiedDisplayError}
          resLookupFromHeader={resLookupOverlay}
          isOverLay={overlay === 'true'}
          isReservation={isReservationModal(isAccountModal, variation)}
          isAccountModal={isAccountModal}
          accountEvent={accountEvent}
          modalId={modalId}
          isInsideAccountContainer={true}
          isDTT={isDTT}
          trackingPageTitle={trackingPageName}
        />
      );
    } else if (compName === 'signincontentblock' && !isEAASignIn) {
      return (
        <SignInContentBlock
          {...compLabels}
          trackingPageTitle={trackingPageName}
          isOverLay={overlay === 'true'}
          isReservation={isReservationModal(isAccountModal, variation)}
        />
      );
    } else if (compName === 'activateorforgotform') {
      /**1. is we have keys relted for forgot/activete page*/
      return (
        <ActivateOrForgotForm
          isActivateAccountFlow={true}
          authorModelData={compLabels}
          setPageError={errorArray => updateErrorState(errorArray)}
          updatePageErrors={memoZiedDisplayError}
          setDisablePasswordForm={flag => setDisablePasswordForm(flag)}
          setDisplayTypeOneError={flag => setDisplayTypeOneError(flag)}
          setTypeTwoErrorHeader={error => setTypeTwoErrorHeader(error)}
          setUserDetails={userDetails => setUserDetails(userDetails)}
          setPhoneDetails={setPhoneDetails}
          setSuccessMsg={(msg: string) => setSuccessMsg(msg)}
        ></ActivateOrForgotForm>
      );
    } else if (compName === 'setpassword') {
      return (
        <SetPassword
          setPageError={errorArray => {
            updateErrorState(errorArray);
          }}
          setDisplayTypeOneError={flag => setDisplayTypeOneError(flag)}
          setTypeTwoErrorHeader={error => setTypeTwoErrorHeader(error)}
          authorModelData={compLabels}
          isFormDisabled={disablePasswordForm}
          userDetails={userDetails}
          phoneDetails={phoneDetails}
          setPhoneDetails={setPhoneDetails}
          setIsActivatedSuccessful={setIsActivatedSuccessful}
          isDTT={isDTT}
        ></SetPassword>
      );
    } else if (compName === 'oneclickjoinreservat') {
      return (
        <OneClickJoinForm
          authorModelData={compLabels}
          isAccountModal={isAccountModal}
          accountEvent={accountEvent}
          hasSignIn={isSignIn}
          setPageError={errorArray => {
            updateErrorState(errorArray);
          }}
          setDisplayTypeOneError={flag => setDisplayTypeOneError(flag)}
          isDTT={isDTT}
        />
      );
    } else if (compName === 'findreservation') {
      return <FindReservation labels={compLabels} resLookupFromHeader={true} isOverLay={overlay === 'true'} />;
    } else {
      return null;
    }
  };

  const renderParsysMolecule = (key: string, cqItems: any, accountEvent?: AccountModalEvent | null) => {
    /**
     * renderparsys molecule
     * find the key and render component
     * user will author two molecule
     * 1. is left part can be login form or any listed component
     * 2. right part content block or any other listed component
     */
    if (Object.prototype.hasOwnProperty.call(cqItems, key)) {
      const componentItems = cqItems[key];
      const components = componentItems[':items'];
      for (const comp in components) {
        /**
         * render components
         */
        if (Object.prototype.hasOwnProperty.call(components, comp)) {
          const compLabels = components[comp]; // molecule labels are avilable here
          const compName = comp?.split('_')[0]?.toLowerCase();
          if (compName === 'datapagecomponent') {
            const dataPageItemsList = components[comp][':items'];
            for (const dataPageComp in dataPageItemsList) {
              const dataPageLabels = dataPageItemsList[dataPageComp];
              return componentRenderer(dataPageComp, dataPageLabels, accountEvent);
            }
          } else {
            return componentRenderer(compName, compLabels, accountEvent);
          }
        }
      }
    }
    return null;
  };

  const renderComponentOnTheBasisOfCards = (key: any, ind: any, accountEvent?: AccountModalEvent | null) => {
    /**
     * return the card on the basis of requirment
     * key = which component we have to render
     * ind = index
     * isResPath = if we have to render reservatrion path modal
     * it will return the component markup
     */

    /** render simple modal sign and sign-in content block component or any other pages */
    // helper.ts, includes in array
    const isReservation = isReservationModal(isAccountModal, variation);
    return (
      <div
        key={ind}
        className={clsx(
          (isReservation || totalNumberOfCards?.length === 1) && !(modalId === CHECKED_IN_MODAL_ID) // show border when it is reservation or checked in flow only when two components are authored in account container
            ? ''
            : 'border-left', // border exists for normal and overlay
          (isEAASignIn && isSignIn) ||
            pagePath?.includes('earn-while-you-sleep') ||
            pagePath?.includes('join-and-save-reservation') ||
            pagePath?.includes('save-reservation')
            ? 'col-12'
            : `col-12 ${
                overlay || pagePath?.includes('checked-in')
                  ? 'col-md-6 border-left-overlay custom-padding-overlay'
                  : 'col-lg-6'
              } ${ind === 0 ? '' : isReservation && ind === 1 ? 'mt-4 mt-lg-0' : 'mt-5 mt-lg-0'}`,
          isReservation && ind === 0 && 'mx-0 px-0 px-md-auto',
          isReservation && ind === 1 && 'px-auto px-md-0 px-lg-0'
        )}
      >
        {renderParsysMolecule(key, cqItems, accountEvent)}
      </div>
    );
  };

  const MemoizedModalPageComponent: React.FC = memo<{ accountEvent?: AccountModalEvent | null }>(accountEvent => {
    const memoCompoent = useMemo(
      /** memoized render component due to avoid the rerender
       * on this component right side and left side component depends
       */
      () => (
        <div className="row mx-0">
          {totalNumberOfCards?.map((key, ind) => {
            return renderComponentOnTheBasisOfCards(key, ind, accountEvent?.accountEvent);
          })}
        </div>
      ),
      []
    );
    return (
      /** return the modal component
       * include reservation/ simple sign-in modal
       */
      <>
        <MemoizedPageTitleAndAlertContent
          pageErrors={pageErrors}
          displayTypeOneError={displayTypeOneError}
          typeTwoErrorHeader={typeTwoErrorHeader}
          isEAASignIn={isEAASignIn}
          title={title}
          contentDescription={contentDescription}
          isOverlay={overlay === 'true'}
          isCheckedIn={modalId === CHECKED_IN_MODAL_ID}
          trackingPageName={trackingPageName}
        />
        {memoCompoent}
      </>
    );
  });

  const renderSuccessMessageField = (successMsg: string) => {
    return (
      <div className="m-message-inline account-page-error-msg success mb-4">
        <div className="m-message-content-wrap">
          <div className="m-message-content">
            <RichText text={successMsg} componentId="success-msg" />
          </div>
        </div>
      </div>
    );
  };

  return !showCustomMessage ? (
    <StyledAccountContainer
      className={clsx(!overlay ? 'container my-5' : 'py-2 px-3 px-md-2 my-4 my-md-0', 'column-container')}
      data-component-name="o-account-accountcontainer"
      data-testid="account-container"
    >
      {isAccountModal && !isAuthorMode ? (
        //render modal with required page content
        <MemoizedPageContentWithModal Children={MemoizedModalPageComponent} modalId={modalId} />
      ) : (
        /** render the simple page content */
        <>
          <MemoizedPageTitleAndAlertContent
            pageErrors={pageErrors}
            displayTypeOneError={displayTypeOneError}
            typeTwoErrorHeader={typeTwoErrorHeader}
            isEAASignIn={isEAASignIn && isSignIn}
            title={title}
            pagePath={pagePath}
            isOverlay={overlay === 'true'}
            contentDescription={contentDescription}
            trackingPageName={trackingPageName}
          />
          {isActivatedSuccessful && renderSuccessMessageField(successMsg)}
          <div className={clsx('row', overlay === 'true' && 'mx-1')}>
            {isAuthorMode && Array.from({ length: totalNumberOfCards?.length }, (_, i) => parsysContainer(i))}
            {!isAuthorMode &&
              totalNumberOfCards?.map((key, ind) => {
                /** retrun the mcomponent for the page in author and simple mode */
                return renderComponentOnTheBasisOfCards(key, ind);
              })}
          </div>
        </>
      )}
    </StyledAccountContainer>
  ) : (
    <></>
  );
};

export const AccountContainerConfig = {
  emptyLabel: 'AccountContainer',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/accountcontainer`,
};
export const AccountContainerEditable = (props: any) => {
  return props.cqPath.includes('datapagecomponent') ? (
    <AccountContainer {...props?.model} isDTT={props?.isDTT} />
  ) : (
    <EditableComponent config={AccountContainerConfig} {...props}>
      <AccountContainer {...props} />
    </EditableComponent>
  );
};
