import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledForgotPasswordEmailSent = styled.div`
  ${rtl`
    &:is(${constants.RTL_LANG_LIST}){
        text-align: justify;
      }
    .spacing-top-bottom {
      margin-top: ${toRem(56)};
      margin-bottom: ${toRem(72)};
      @media ${baseVariables.mediaQuery.md} {
        margin-top: ${toRem(80)};
        margin-bottom: ${toRem(136)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        margin-bottom: ${toRem(160)};
      }
    }
  `}
`;

export const StyledCustomMessage = styled.div`
  ${rtl`
    .custom-message-wrapper{
      margin-top:${toRem(48)};
    }
`}
`;
