/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, useState, useId, useEffect, useContext, useMemo } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { inspect } from 'util';
import { useLazyQuery } from '@apollo/client';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Button, Text, Link, Types, Messages, CheckBox, InputTextField, RichText } from '@marriott/mi-ui-library';
import { PageContext } from '../../../modules/context/PageContext';
import { PhoneNumberField } from '../../../molecules/PhoneNumberField';

import {
  constants,
  CREATE_ACCOUNT_CLICK_VAL,
  CHINESE_MOBILE_CODE,
  CHINESE_MOBILE_LENGTH,
  NUMBERS_REGEX,
  ALPHABETS_REGEX,
  ONE_CLICK_JOIN_ERROR,
  CHINESE_SOURCE_LANG,
  ENGLISH_SOURCE_LANG,
  BANNER_MSGS_KEYS,
  generateOneTimePwrdForMobileUrl,
  API_ERROR_KEY,
} from '../../../modules/utils/constants/constants';

import {
  addSubDirectoryPrefix,
  apiLogger,
  checkChinaLocale,
  checkForValidZipCode,
  emailRegex,
  generateApolloClientHeaders,
  getCountryAndLangCode,
  getCurrentUrlParams,
  isChinese,
  randDecrpytAPI,
  shouldSwapName,
} from '../../../modules/utils';

import { useCreateAccountStore } from '../../../modules/store/createAccountStore';
import { useBannerMessagesStore } from '../../../modules/store/bannerMessagesStore';
import { Dropdown } from '../../../molecules/Dropdown';
import { PasswordInput } from '../../../molecules/PasswordInput';
import { phoenixAccountTransliterate } from '../../../modules/graph';

import { CreateAccountFormProps, CountryOption, CountryList } from './CreateAccountForm.types';
import { StyledCreateAccountForm } from './CreateAccountForm.styles';

// Use named rather than default exports.
export const CreateAccountForm: FC<CreateAccountFormProps> = models => {
  const {
    pwrdCharCheck,
    pwrdLowerCaseCheck,
    pwrdUpperCaseCheck,
    pwrdSpecialCharCheck,
    countries,
    firstNameLabel,
    lastNameLabel,
    zipPostalCodeLabel,
    countryRegionLabel,
    emailLabel,
    mobilePhoneLabel,
    sendVerificationLabel,
    sendVerificationDescription,
    verificationCodeLabel,
    newOtpLabel,
    rememberMeLabel,
    confirmPwrdLabel,
    pwrdLabel,
    firstNameError,
    lastNameError,
    zipCodeError,
    emailErrorLabel,
    alreadyAMemberText,
    activateOnlineAccountText,
    activateOnlineAccountPath,
    pwrdErrorLabel,
    confirmAndNewPwrdMismatchMsg,
    errorMessageHeader,
    mobilePhoneError,
    verificationCodeRequired,
    mobilePhoneRequired,
    phoneNumberWarning,
    mobilePhoneDigitsRequired,
    errorMessageCorrectName,
    pinyinEnglishSurname,
    pinyinEnglishName,
    chinaSurNameError,
    chinaNameError,
    prohibitedCountryErrMsg,
    emailDuplicateErrMsg,
    inlineMessageForName,
    zipInlineError,
    emailInvalidError,
    optionalLabel,
    zipCodeInvalidError,
    eligibleCountries,
    isValidClientId,
    partnerPortalObject,
    isDTT,
  } = models;

  const {
    formData,
    setFormData,
    errorKey,
    setTransactionDetails,
    setTripId,
    setStartDate,
    setJoinNowBtnClicked,
    joinNowBtnClicked,
    errorsList,
    setErrorsList,
    remembermeCheckBox,
    consentErrors,
    setRemembermeCheckBox,
    setFormValidation,
    goBackUrl,
    isValidPartnerPortal,
  } = useCreateAccountStore();
  // TODO: Identify behavior by responsible team
  // const [transactionId, setTransactionId] = useState<PhoneDetails>();

  const passwordCondArr = [
    {
      condition: pwrdCharCheck,
      isValid: false,
    },
    {
      condition: pwrdUpperCaseCheck,
      isValid: false,
    },
    {
      condition: pwrdLowerCaseCheck,
      isValid: false,
    },
    {
      condition: pwrdSpecialCharCheck,
      isValid: false,
    },
  ];

  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { isMobileAuthFeatureEnabled, isNonChinaMobileEnabled, currentLocale } = pageContext;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const localeCountryCode = countryCode || constants.USA_COUNTRY_CODE;
  const localeBasedNameSwapConfig = pageContext?.localeBasedNameSwapConfig;
  const serverENV = pageContext?.serverENV ? JSON.parse(pageContext?.serverENV) : {};
  const { randParameter, isOCJFlow, bannerMsgs } = useBannerMessagesStore(state => state);

  const inputId = useId();
  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState<'text' | 'password'>('password');
  const [passwordConditions, setPasswordConditions] = useState(passwordCondArr);
  const [disableField, setDisableField] = useState(false);
  const [mobileField, setMobileField] = useState(false);
  const [mobileFieldOptional, setMobileFieldOptional] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showZipcodeField, setShowZipcodeField] = useState(false);
  const [reverseNamefield, setReverseNamefield] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState<CountryOption>();
  const isValidChangePassword = !passwordValid;
  const { IS_LOCAL_DEV } = process.env;

  const [errors, setErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    errorFirstName: '',
    errorLastName: '',
    zipCodeError: '',
    emailErrorLabel: '',
    pwrdErrorLabel: '',
    confirmAndNewPwrdMismatchMsg: '',
    verificationCodeRequired: '',
    mobilePhoneRequired: '',
    mobilePhoneDigitsRequired: '',
    mobilePhoneError: '',
    chinaSurNameError: '',
    chinaNameError: '',
    prohibitedCountryErrMsg: '',
    zipCodeInvalidError: '',
    emailInvalidError: '',
    usPostalCodeInvalidError: '',
    canadaPostalCodeInvalidError: '',
    uxlFailureErrorMessage: '',
    existingAccountInlineErrorMessage: '',
  });

  const resetPwrd = () => {
    setFormData({ pwrd: '', confirmPwrd: '' });
    setPasswordValid(false);
  };

  const randDecryptionAPI = async () => {
    const urlParam = getCurrentUrlParams()?.replace(/&amp;/g, '&'); // to fetch param from url
    const param = new URLSearchParams(urlParam);
    const randQueryParameter = param?.get('rand') ?? ''; // rand parameter

    if (randParameter || randQueryParameter) {
      try {
        const response = await randDecrpytAPI(isOCJFlow ? randParameter : randQueryParameter, isDTT);
        if (response?.status === 200) {
          setFormData({
            ...formData,
            firstName: response?.data?.firstName,
            lastName: response?.data?.lastName,
            zipcode: response?.data?.postalCode,
            email: response?.data?.email,
          });
          handleCountryNameChange(getCountryDetailByCountryCode(response?.data?.country));
          setTripId(response?.data?.reservationId);
          setStartDate(response?.data?.checkInDate);
          if (isOCJFlow) {
            checkProhibitedCountries(response?.data?.country);
          }
        }
      } catch (error) {
        apiLogger(`[ProfileDetails] GetProfileDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(error)}`);
      }
    }
  };
  // to run rand api once when isCheckedIn modal is up
  // to check if clientid is valid for sweepstake variation
  useEffect(() => {
    randDecryptionAPI();
  }, []);

  useEffect(() => {
    if (bannerMsgs?.key !== BANNER_MSGS_KEYS.NEW_MEMBER_NUMBER_MSG) {
      sessionStorage.removeItem('bannerMessagesState');
    }
  }, [bannerMsgs?.key]);

  // sweepstake phone dropdown to have countries with country code +1
  const updatedSweepstakeCountriesDialCodes = useMemo(() => {
    return eligibleCountries?.map(eligibleCountry => ({
      ...eligibleCountry,
      ...(countries as CountryList[])?.find(country => country?.id === eligibleCountry?.id),
    }));
  }, []);

  // used to map country code from rand response
  const getCountryDetailByCountryCode = (countryCode: string) => {
    const countryDetail = (isValidClientId ? updatedSweepstakeCountriesDialCodes : countries)?.find(
      country => country.id === countryCode
    );
    return countryDetail;
  };
  const updatedCountriesDialCodes = (isValidClientId ? updatedSweepstakeCountriesDialCodes : countries)?.map(
    (country: { id?: string; countryDialCode?: string; value?: string }) => ({
      id: country?.id || '',
      name: country?.value || '',
      value: country?.countryDialCode || '',
    })
  );
  const countryDialCode = updatedCountriesDialCodes?.find(
    (d: any) => d.id?.toUpperCase() === countryCode?.toUpperCase()
  )?.value;

  const [selectedCountryCode, setSelectedCountryCode] = useState(countryDialCode);

  const isChinesePhone = formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE;
  const isChinesePhoneValid = formData?.mobilePhone?.length === CHINESE_MOBILE_LENGTH;
  const isValidPhoneNo = formData?.mobilePhone?.length >= 7 && formData?.mobilePhone?.length <= 16;

  const phoneNumberValdiation = (countryId?: string) => {
    if (checkChinaLocale(currentLocale || '') && countryId === constants.CHINA_COUNTRY_CODE) {
      setDisableField(true);
      setMobileField(true);
    } else if (
      (isValidClientId && !checkChinaLocale(currentLocale || '')) ||
      countryId === constants.CHINA_COUNTRY_CODE
    ) {
      setMobileField(true);
      setMobileFieldOptional(true);
    } else {
      setDisableField(false);
      setMobileField(false);
      setMobileFieldOptional(false);
    }
  };

  useEffect(() => {
    const defaultCountryObj = (isValidClientId ? updatedSweepstakeCountriesDialCodes : countries)?.find(
      cc => cc.id === localeCountryCode
    );
    const updatedDefaultCountry =
      defaultCountryObj ??
      (isValidClientId ? updatedSweepstakeCountriesDialCodes : countries)?.find(
        cc => cc.id === constants?.USA_COUNTRY_CODE
      );
    setDefaultCountry(updatedDefaultCountry);
    if (
      isValidClientId &&
      (updatedDefaultCountry?.id === constants?.USA_COUNTRY_CODE ||
        updatedDefaultCountry?.id === constants?.CANADA_COUNTRY_CODE)
    ) {
      setShowZipcodeField(true);
    }
    setFormData({
      country: updatedDefaultCountry,
    });
    phoneNumberValdiation(defaultCountryObj?.id);
  }, []);

  useEffect(() => {
    const isPasswordValid = passwordConditions.reduce((acc, curr) => acc && curr.isValid, true);
    setPasswordValid(isPasswordValid);
  }, [passwordConditions]);

  useEffect(() => {
    const localeSwapCheck = shouldSwapName(localeBasedNameSwapConfig, localeCountryCode);

    if (localeCountryCode === constants.CANADA_COUNTRY_CODE || localeCountryCode === constants.USA_COUNTRY_CODE) {
      setShowZipcodeField(true);
    }

    if (localeSwapCheck) {
      setReverseNamefield(true);
    } else {
      setReverseNamefield(false);
    }
  }, []);

  const handleCountryNameChange = (option: any) => {
    if (option.id === constants.CANADA_COUNTRY_CODE || option.id === constants.USA_COUNTRY_CODE) {
      setShowZipcodeField(true);
    } else {
      setFormData({ zipcode: '' });
      setShowZipcodeField(false);
    }

    if (
      option.id === constants.KOREA_COUNTRY_CODE ||
      option.id === constants.CHINA_COUNTRY_CODE ||
      option.id === constants.JAPAN_COUNTRY_CODE
    ) {
      setReverseNamefield(true);
    } else {
      setReverseNamefield(false);
    }
    fetchMobileNumber({ countryCode: option?.countryDialCode, mobileNumber: formData?.mobilePhone });
    phoneNumberValdiation(option?.id);
    setFormData({ country: { ...option } });
    resetForm('zipcode');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    value && resetForm(name);

    if (name === 'pwrd') {
      validatePassword(value);
    }

    setFormData({ [name]: value });
  };

  const validatePassword = (value: string) => {
    const str = value;
    const passwordArr = [...passwordConditions];
    if (passwordArr[0]) {
      passwordArr[0].isValid = 8 <= str.length && str.length <= 20;
    }
    if (passwordArr[1]) {
      passwordArr[1].isValid = !!str.match(/[A-Z]/g);
    }
    if (passwordArr[2]) {
      passwordArr[2].isValid = !!str.match(/[a-z]/g);
    }
    if (passwordArr[3]) {
      passwordArr[3].isValid = !!str.match(/^(?=.*[0-9$!#&@?%=_])[A-Za-z0-9$!#&@?%=_]{1,}$/g);
    }
    setPasswordConditions([...passwordArr]);
  };

  const resetForm = (fieldName: string) => {
    const newErrors: any = { ...errors };
    switch (fieldName) {
      case 'firstName':
        newErrors.firstNameError = '';
        break;
      case 'lastName':
        newErrors.lastNameError = '';
        break;
      case 'zipcode':
        newErrors.zipCodeError = '';
        newErrors.zipCodeInvalidError = '';
        newErrors.usPostalCodeInvalidError = '';
        newErrors.canadaPostalCodeInvalidError = '';
        break;
      case 'mobilePhone':
        newErrors.mobilePhoneError = '';
        newErrors.mobilePhoneRequired = '';
        newErrors.mobilePhoneDigitsRequired = '';
        break;
      case 'verificationCodeRequired':
        newErrors.verificationCodeRequired = '';
        break;
      case 'email':
        newErrors.emailErrorLabel = '';
        newErrors.emailInvalidError = '';
        newErrors.existingAccountInlineErrorMessage = '';
        break;
      case 'pwrd':
        newErrors.pwrdErrorLabel = '';
        break;
      case 'confirmPwrd':
        newErrors.confirmAndNewPwrdMismatchMsg = '';
        break;
      case 'pinyinEnglishSurname':
        newErrors.chinaSurNameError = '';
        break;
      case 'pinyinEnglishName':
        newErrors.chinaNameError = '';
        break;

      default:
    }
    setErrors(newErrors);
  };

  const validateAllFields = () => {
    const newErrors: any = {};
    let isValid = true;

    if (!formData?.firstName) {
      newErrors.firstNameError = firstNameError;
      isValid = false;
    }

    if (!formData?.lastName) {
      newErrors.lastNameError = lastNameError;
      isValid = false;
    }

    if (!checkChinaLocale(currentLocale || '')) {
      if (formData?.firstName) {
        if (!ALPHABETS_REGEX.test(formData?.firstName)) {
          newErrors.errorFirstName = errorMessageCorrectName;
          isValid = false;
        }
      }

      if (formData?.lastName) {
        if (!ALPHABETS_REGEX.test(formData?.lastName)) {
          newErrors.errorLastName = errorMessageCorrectName;
          isValid = false;
        }
      }
    }

    if (isMobileAuthFeatureEnabled || checkChinaLocale(currentLocale || '')) {
      if (!formData?.pinyinEnglishSurname) {
        newErrors.chinaSurNameError = chinaSurNameError;
        isValid = false;
      }

      if (!formData?.pinyinEnglishName) {
        newErrors.chinaNameError = chinaNameError;
        isValid = false;
      }
    }

    if (showZipcodeField) {
      if (!formData?.zipcode.trim()) {
        newErrors.zipCodeError = zipCodeError;
        isValid = false;
      } else if (!checkForValidZipCode(formData?.zipcode, formData?.country?.id)) {
        newErrors.zipCodeInvalidError = zipCodeInvalidError;
        isValid = false;
      }
    }

    if (!formData?.email && !disableField) {
      newErrors.emailErrorLabel = emailErrorLabel;
      isValid = false;
    }
    // check if length is less than 5 or it is of incorrect format according to regex
    if (
      !disableField &&
      formData?.email?.trim() &&
      (formData?.email?.trim()?.length < 5 || !emailRegex.test(formData.email.trim()))
    ) {
      newErrors.emailInvalidError = emailInvalidError;
      isValid = false;
    }

    if (!formData?.pwrd && !disableField) {
      newErrors.pwrdErrorLabel = pwrdErrorLabel;
      isValid = false;
    }

    if ((formData?.pwrd && !formData?.confirmPwrd) || formData.confirmPwrd !== formData.pwrd) {
      newErrors.confirmAndNewPwrdMismatchMsg = confirmAndNewPwrdMismatchMsg;
      isValid = false;
    }
    if (formData.confirmPwrd !== formData.pwrd) {
      newErrors.confirmAndNewPwrdMismatchMsg = confirmAndNewPwrdMismatchMsg;
      isValid = false;
    }

    if (mobileField && !disableField && !mobileFieldOptional) {
      if (!formData?.verificationCode) {
        newErrors.verificationCodeRequired = verificationCodeRequired;
        isValid = false;
      }
    }

    if (disableField && mobileField && !mobileFieldOptional) {
      if (!formData?.mobilePhone) {
        newErrors.mobilePhoneRequired = mobilePhoneRequired;
        newErrors.mobilePhoneDigitsRequired = mobilePhoneDigitsRequired;
        isValid = false;
      }
    }

    if (formData?.mobilePhone) {
      if (
        (!isChinesePhone && !isValidPhoneNo) ||
        (isChinesePhone && !isChinesePhoneValid) ||
        !NUMBERS_REGEX.test(formData?.mobilePhone)
      ) {
        newErrors.mobilePhoneRequired = mobilePhoneRequired;
        newErrors.mobilePhoneDigitsRequired = mobilePhoneDigitsRequired;
        isValid = false;
      }
    }
    if (formData?.country?.id === constants.KOREA_COUNTRY_CODE && Object.keys(consentErrors)?.length) {
      if (consentErrors['errorMessagePersonal']) {
        newErrors.errorMessagePersonal = consentErrors['errorMessagePersonal'];
        isValid = false;
      }
      if (consentErrors['errorMessagePromotions']) {
        newErrors.errorMessagePromotions = consentErrors['errorMessagePromotions'];
        isValid = false;
      }
    }
    setErrors(newErrors);
    setErrorsList(Object.values(newErrors));
    setFormValidation(isValid);
    setJoinNowBtnClicked(false);
    if (!isValid) {
      resetPwrd();
    }
    return isValid;
  };

  useEffect(() => {
    const newErrors: any = {};
    if (errorKey) {
      errorKey.forEach(error => {
        if (isValidPartnerPortal && errorKey[0] === 'emailDuplicateError') {
          newErrors.existingAccountInlineErrorMessage = partnerPortalObject?.existingAccountInlineErrorMessage;
        } else {
          newErrors[error as keyof CreateAccountFormProps] =
            models[error as keyof CreateAccountFormProps] ?? pageContext?.uxlErrorMessage;
        }
      });
    }
    setErrors(newErrors);
    setErrorsList(Object.values(newErrors));
    setFormValidation(false);
    setJoinNowBtnClicked(false);
    resetPwrd();
  }, [errorKey]);

  const checkProhibitedCountries = (randCountry?: string) => {
    let isInvalid = false;
    const prohibitedCountries = serverENV?.PROHIBITED_COUNTRIES_CODES
      ? serverENV?.PROHIBITED_COUNTRIES_CODES?.split(',')
      : [];
    if (
      prohibitedCountries.includes(formData?.country?.id) ||
      prohibitedCountries.includes(localeCountryCode) ||
      prohibitedCountries.includes(randCountry)
    ) {
      const newErrors: any = {};
      newErrors.prohibitedCountryErrMsg = prohibitedCountryErrMsg;
      setErrors(newErrors);
      setErrorsList(Object.values(newErrors));
      isInvalid = true;
      setJoinNowBtnClicked(false);
    }
    if (isInvalid) {
      resetPwrd();
    }
    return isInvalid;
  };

  // The validateAllFields() function will be executed solely after the 'joinNow' button has received a click event.
  useEffect(() => {
    if (joinNowBtnClicked) {
      if (!checkProhibitedCountries()) {
        validateAllFields();
      }
    }
  }, [joinNowBtnClicked]);

  const [chineseTranslate] = useLazyQuery(phoenixAccountTransliterate, {
    onCompleted: (data: any) => {
      apiLogger(`[CreateAccount] ChineseTranslate - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`);
      return data?.transliterate?.transliteratedText?.[0];
    },
    onError: error => {
      apiLogger(
        `[CreateAccount] ChineseTranslate - sessionId value:: ${sessionData?.sessionToken} - error: ${inspect(error)}`
      );
    },
  });

  const chineseNumbCheck = () => {
    const newErrors: any = {};

    if (
      (!isChinesePhone && !isValidPhoneNo) ||
      (isChinesePhone && !isChinesePhoneValid) ||
      !NUMBERS_REGEX.test(formData?.mobilePhone)
    ) {
      newErrors.mobilePhoneRequired = mobilePhoneRequired;
      newErrors.mobilePhoneDigitsRequired = mobilePhoneDigitsRequired;
    } else {
      setDisableField(false);
    }

    setErrors(newErrors);
    setErrorsList(Object.values(newErrors));
  };

  const handleOtp = async () => {
    chineseNumbCheck();
    try {
      const payload = {
        phoneNumber: `${formData?.mobilePhoneCountryId?.value}${formData?.mobilePhone}`,
      };
      const response = await axios.post(addSubDirectoryPrefix(generateOneTimePwrdForMobileUrl), payload, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          ...(currentLocale && { locale: currentLocale }),
        },
      });
      setTransactionDetails(response.data.transactionId);
    } catch (error) {
      apiLogger(
        `[CreateAccount] GenerateTransactionDetails - sessionId value:: ${sessionData?.sessionToken} - error: ${inspect(
          error
        )}`
      );
    }
  };

  const englishTranslateFromChinese = (textValue: string) => {
    return chineseTranslate({
      variables: {
        input: {
          sourceLanguage: CHINESE_SOURCE_LANG,
          sourceText: textValue,
          targetLanguage: ENGLISH_SOURCE_LANG,
        },
      },
      // skip: skipQuery,
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    });
  };

  const fetchMobileNumber = (obj: any) => {
    const countryId = updatedCountriesDialCodes?.find(country => obj.countryCode === country.value);
    setFormData({
      mobilePhone: obj.mobileNumber,
      mobilePhoneCountryId: countryId,
    });
    setSelectedCountryCode(obj?.countryCode);
  };

  const handleCheckboxChange = () => {
    setRemembermeCheckBox(!remembermeCheckBox);
  };
  const renderForm = () => {
    return (
      <form method="post" data-testid="create-account-form">
        <div className="create-account-form">
          <div className="row">
            <div className="col-12 col-lg-6">
              {reverseNamefield ? (
                <InputTextField
                  label={lastNameLabel}
                  inputValue={formData?.lastName}
                  showErrorMessage={!!errors?.lastNameError || !!errors?.errorLastName}
                  messageToShow={errors?.lastNameError ? lastNameError : ''}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    // onKeyUp: handleKeyPress,
                    id: `${inputId}-lastName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-lastName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.lastNameError ? 'is-error' : formData?.lastName ? 'is-active' : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('lastName');
                    setFormData({ ...formData, lastName: val });
                  }}
                  onBlur={event => {
                    checkChinaLocale(currentLocale || '') &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target?.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishSurname: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                      });
                  }}
                />
              ) : (
                <InputTextField
                  label={firstNameLabel}
                  inputValue={formData?.firstName}
                  showErrorMessage={!!errors?.firstNameError || !!errors?.errorFirstName}
                  messageToShow={errors?.firstNameError ? firstNameError : ''}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-firstName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-firstName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.firstNameError ? 'is-error' : formData?.firstName ? 'is-active' : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('firstName');
                    setFormData({ ...formData, firstName: val });
                  }}
                  onBlur={event => {
                    isChinese(event?.target.value as string) &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishName: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                      });
                  }}
                />
              )}
            </div>
            <div className="col-12 col-lg-6">
              {reverseNamefield ? (
                <InputTextField
                  label={firstNameLabel}
                  inputValue={formData?.firstName}
                  showErrorMessage={!!errors?.firstNameError || !!errors?.errorFirstName}
                  messageToShow={errors?.firstNameError ? firstNameError : ''}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-firstName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-firstName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.firstNameError ? 'is-error' : formData?.firstName ? 'is-active' : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('firstName');
                    setFormData({ ...formData, firstName: val });
                  }}
                  onBlur={event => {
                    isChinese(event?.target.value as string) &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishName: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                      });
                  }}
                />
              ) : (
                <InputTextField
                  label={lastNameLabel}
                  inputValue={formData?.lastName}
                  showErrorMessage={!!errors?.lastNameError || !!errors?.errorLastName}
                  messageToShow={errors?.lastNameError ? lastNameError : ''}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    // onKeyUp: handleKeyPress,
                    id: `${inputId}-lastName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-lastName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.lastNameError ? 'is-error' : formData?.lastName ? 'is-active' : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('lastName');
                    setFormData({ ...formData, lastName: val });
                  }}
                  onBlur={event => {
                    checkChinaLocale(currentLocale || '') &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target?.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishSurname: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                      });
                  }}
                />
              )}
            </div>
          </div>
          {(isMobileAuthFeatureEnabled || checkChinaLocale(currentLocale || '')) && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={pinyinEnglishSurname}
                  inputValue={formData?.pinyinEnglishSurname}
                  showErrorMessage={!!errors?.chinaSurNameError}
                  messageToShow={chinaSurNameError}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-pinyinEnglishSurname`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-pinyinEnglishSurname`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.chinaSurNameError ? 'is-error' : formData?.pinyinEnglishSurname ? 'is-active' : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('pinyinEnglishSurname');
                    setFormData({ ...formData, pinyinEnglishSurname: val });
                  }}
                  showBadge={!!inlineMessageForName}
                  badgeValue={inlineMessageForName}
                />
              </div>
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={pinyinEnglishName}
                  inputValue={formData?.pinyinEnglishName}
                  showErrorMessage={!!errors?.chinaNameError}
                  messageToShow={chinaNameError}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-pinyinEnglishName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-pinyinEnglishName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.chinaNameError ? 'is-error' : formData?.pinyinEnglishName ? 'is-active' : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('pinyinEnglishName');
                    setFormData({ ...formData, pinyinEnglishName: val });
                  }}
                  showBadge={!!inlineMessageForName}
                  badgeValue={inlineMessageForName}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className={clsx(`col-12 ${showZipcodeField ? 'col-lg-6' : 'col-lg-12'}`)}>
              <div className="m-input-field pb-2 mt-5">
                <label
                  className="country-label"
                  id="dropdown-label-fp-country-code"
                  htmlFor="dropdown-label-fp-country-code"
                >
                  {countryRegionLabel}
                </label>
                <Dropdown
                  defaultOption={formData?.country?.value || defaultCountry?.value || ''}
                  dropdownOptions={isValidClientId ? updatedSweepstakeCountriesDialCodes ?? [] : countries || []}
                  onChange={handleCountryNameChange}
                  customClassName="activate_account--countrydropdown is-active"
                  dropdownId="fp-country-code"
                />
              </div>
            </div>
            {!!showZipcodeField && (
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={zipPostalCodeLabel}
                  inputValue={formData?.zipcode}
                  showErrorMessage={
                    !!errors?.zipCodeError ||
                    !!errors?.zipCodeInvalidError ||
                    !!errors?.canadaPostalCodeInvalidError ||
                    !!errors?.usPostalCodeInvalidError
                  }
                  messageToShow={errors?.zipCodeError ? zipCodeError : zipInlineError}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={10}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    // onKeyUp: handleKeyPress,
                    id: `${inputId}-Zipcode`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-Zipcode`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.zipCodeError || errors?.zipCodeInvalidError
                      ? 'is-error'
                      : formData?.zipcode
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('zipcode');
                    setFormData({ ...formData, zipcode: val });
                  }}
                />
              </div>
            )}
          </div>
          {(disableField || mobileField) && (
            <div className="row">
              <div className="col-12">
                <div className={`m-input-field py-2 mt-5 phone-field`}>
                  <PhoneNumberField
                    label={mobilePhoneLabel}
                    placeHolderText={mobileFieldOptional ? `(${optionalLabel})` : ''}
                    defaultCCOption={
                      formData?.mobilePhoneCountryId?.value
                        ? formData?.mobilePhoneCountryId?.value
                        : isValidClientId && !checkChinaLocale(currentLocale || '')
                        ? (updatedCountriesDialCodes ?? [])?.find(country => country?.id === defaultCountry?.id)
                            ?.value ?? constants?.USA_COUNTRY_DIAL_CODE
                        : CHINESE_MOBILE_CODE
                    }
                    dropdownCCOptions={updatedCountriesDialCodes ?? []}
                    mobileInputVal={formData?.mobilePhone?.replace(selectedCountryCode || '', '') || ''}
                    isInputError={!!errors?.mobilePhoneError || !!errors?.mobilePhoneRequired}
                    inputErrorMsg={formData?.mobilePhone?.length ? phoneNumberWarning : mobilePhoneError}
                    disabled={false}
                    fetchMobileNumber={(obj: any) => fetchMobileNumber(obj)}
                    isNonChinaMobileEnabled={!isNonChinaMobileEnabled}
                    isRegisteredNumber={false}
                    isMobileEditable={true}
                    showIdandValueinOptions={true}
                  />
                  {(isMobileAuthFeatureEnabled || checkChinaLocale(currentLocale || '')) &&
                    formData?.country?.id === constants?.CHINA_COUNTRY_CODE &&
                    formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE && (
                      <>
                        <Text
                          customClass={clsx('t-font-xs d-flex help-text-decoration mt-1 py-3')}
                          copyText={sendVerificationDescription}
                          fontSize={Types.size.extraSmall}
                          element={Types.tags.paragraph}
                        />
                        <Button
                          type={Types.ButtonTypeVariation.Button}
                          buttonCopy={''}
                          className={clsx('m-button-m m-button-primary submit-btn')}
                          isLink={false}
                          callback={handleOtp}
                        >
                          {sendVerificationLabel}
                        </Button>
                      </>
                    )}
                </div>
                {(isMobileAuthFeatureEnabled || checkChinaLocale(currentLocale || '')) &&
                  formData?.country?.id === constants?.CHINA_COUNTRY_CODE &&
                  formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE && (
                    <div className={clsx('m-input-field py-2 mt-5', disableField ? 'disableField' : '')}>
                      <InputTextField
                        iconClass="icon icon-clear"
                        // showIcon={otpError.otp !== undefined}
                        type="number"
                        label={verificationCodeLabel}
                        inputValue={formData.verificationCode}
                        showErrorMessage={!!errors?.verificationCodeRequired}
                        messageToShow={verificationCodeRequired}
                        setErrorHtml={true}
                        messageClass="error-label"
                        className={'m-input-field'}
                        inputMaxLength={10}
                        getInputValue={(val: string) => {
                          setFormData({ ...formData, verificationCode: val });
                        }}
                        getLabelProps={() => ({
                          htmlFor: `${inputId}-verificationCode`,
                        })}
                        getInputProps={() => ({
                          autoComplete: 'off',
                          // onKeyUp: handleKeyPress,
                          id: `${inputId}-verificationCode`,
                          disabled: disableField,
                        })}
                      />

                      <div className={clsx('t-font-xs d-flex mt-1')}>
                        <Button
                          type={Types.ButtonTypeVariation.Button}
                          buttonCopy={''}
                          className={clsx('ml-1 pointer-cursor help-text-decoration')}
                          isLink={true}
                          callback={handleOtp}
                          isDisabled={disableField}
                          href={''}
                        >
                          {newOtpLabel}
                        </Button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          <div
            className={clsx(
              'row',
              disableField && formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE ? 'disableField' : ''
            )}
          >
            <div className="col-12">
              <InputTextField
                label={emailLabel}
                inputValue={formData?.email}
                testId="email-label"
                showErrorMessage={
                  !!errors?.emailErrorLabel ||
                  !!errors?.emailInvalidError ||
                  !!errors?.existingAccountInlineErrorMessage
                }
                messageToShow={
                  errorsList?.length && errors?.existingAccountInlineErrorMessage
                    ? partnerPortalObject?.existingAccountInlineErrorMessage
                    : errors?.emailErrorLabel
                    ? emailErrorLabel
                    : emailInvalidError
                }
                setErrorHtml={true}
                messageClass="error-label"
                inputMaxLength={80}
                infoLabel={emailLabel}
                getInputProps={() => ({
                  autoComplete: 'off',
                  // onKeyUp: handleKeyPress,
                  id: `${inputId}-Email`,
                  disabled: disableField && formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE,
                })}
                getLabelProps={() => ({
                  htmlFor: `${inputId}-Email`,
                })}
                className={clsx(
                  'm-input-field pb-2 mt-5',
                  errors?.emailErrorLabel || errors?.emailInvalidError || errors?.existingAccountInlineErrorMessage
                    ? 'is-error'
                    : formData?.email
                    ? 'is-active'
                    : ''
                )}
                getInputValue={(val: string) => {
                  val && resetForm('email');
                  setFormData({ ...formData, email: val });
                }}
              />
            </div>
          </div>
          <div
            className={clsx(
              'row',
              disableField && formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE ? 'disableField' : ''
            )}
          >
            <div className="col-12 col-lg-6 mt-5">
              <PasswordInput
                value={formData.pwrd}
                id="password"
                name="pwrd"
                disable={disableField && formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE}
                hasError={!!errors?.pwrdErrorLabel || !!errors?.confirmAndNewPwrdMismatchMsg}
                label={pwrdLabel}
                passwordType={passwordType}
                error={errors?.pwrdErrorLabel || errors?.confirmAndNewPwrdMismatchMsg}
                customClass="mb-2"
                iconClass={`password-icon ${passwordType === 'password' ? 'icon-visibility-off' : 'icon-visibility'}`}
                labelClass="t-body-s"
                showValidations={true}
                passwordConditions={passwordConditions}
                handleChange={handleChange}
                onIconClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                isValid={passwordValid}
                showCheckIcon={true}
                ariaLabel="createaccount-password"
              />
            </div>
            <div className="col-12 col-lg-6 pb-2 mt-5">
              <PasswordInput
                value={formData.confirmPwrd}
                id="confirmPassword"
                name="confirmPwrd"
                disable={isValidChangePassword}
                hasError={false}
                label={confirmPwrdLabel}
                passwordType={confirmPasswordType}
                customClass="mb-4"
                iconClass={confirmPasswordType === 'password' ? 'icon-visibility-off' : 'icon-visibility'}
                labelClass="t-body-s"
                handleChange={handleChange}
                onIconClick={() => setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password')}
                isValid={formData.confirmPwrd?.length && formData.confirmPwrd === formData.pwrd ? true : false}
                showCheckIcon={true}
                ariaLabel="createaccount-confirm-password"
              />
            </div>
          </div>
        </div>
      </form>
    );
  };
  return (
    <StyledCreateAccountForm className="mb-3">
      {(!!errorsList?.length || (isOCJFlow && bannerMsgs?.key)) && (
        <div>
          <Messages messageType="error-sev1" className="mt-3 account-page-error-msg mb-4 px-2">
            {errors?.prohibitedCountryErrMsg ? (
              <RichText text={prohibitedCountryErrMsg} componentId="error-span" />
            ) : errors?.uxlFailureErrorMessage ? (
              <RichText text={pageContext?.uxlErrorMessage} componentId={'uxl-failure-msg'} />
            ) : bannerMsgs?.key ? (
              <RichText
                text={
                  bannerMsgs?.key === ONE_CLICK_JOIN_ERROR.EMAIL_DUPLICATE_ERROR
                    ? emailDuplicateErrMsg
                    : pageContext?.uxlErrorMessage
                }
                componentId="error-span"
              />
            ) : isValidPartnerPortal && errors?.existingAccountInlineErrorMessage ? (
              <RichText
                text={partnerPortalObject?.existingAccountErrorMessage?.replace('{returnToUrl}', goBackUrl) ?? ''}
                componentId={'partner-portal-error'}
              />
            ) : (
              <>
                <span>{errorMessageHeader}</span>
                <ul>
                  {errorsList.map((error, index) => (
                    <li key={index}>
                      <RichText text={error} componentId={error} />
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Messages>
        </div>
      )}

      {!partnerPortalObject?.hideActivateAccountCta && (
        <div className="create-account__link row col-12 mt-5 mb-2 flex flex-md-row flex-column">
          <Text
            customClass={clsx(' pb-1 pb-md-0')}
            copyText={alreadyAMemberText}
            fontSize={Types.size.medium}
            element={Types.tags.paragraph}
          />
          <Link
            text={activateOnlineAccountText}
            linkClassName="m-link-action link-underline mx-md-1"
            linkHref={activateOnlineAccountPath || ''}
            target="_self"
            linkType="internal"
            custom_click_track_value={CREATE_ACCOUNT_CLICK_VAL}
          />
        </div>
      )}

      {renderForm()}
      <div className="row col-12 mb-2">
        <CheckBox
          onChange={handleCheckboxChange}
          checked={remembermeCheckBox}
          checkboxName="remember_me"
          children={
            <RichText
              customClass="t-font-m paragraph-margin px-2"
              text={rememberMeLabel}
              componentId={`checkbox-${rememberMeLabel}`}
            />
          }
          checkboxId={inputId}
          className="create_account__checkbox"
        />
      </div>
    </StyledCreateAccountForm>
  );
};
