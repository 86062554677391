// Imports for external libraries go here.
import React, { FC, useContext, useState } from 'react';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import { Button, Heading, InputTextField, Messages, RadioButton, RichText, Types } from '@marriott/mi-ui-library';
import { PageContext } from '../../../modules/context/PageContext';
import { SUCCESS_CODE, UNSUBSCRIBE_ACTION } from '../../../modules/utils/constants/constants';
import { generateApolloClientHeaders, isValidEmaiFormat } from '../../../modules/utils/helper';
import { phoenixAccountUpdateEmailSubscription } from '../../../modules/graph/index';
import { GuestEmailSurveyProps } from './GuestEmailSurvey.types';
import { StyledGuestEmailSurvey } from './GuestEmailSurvey.styles';

export const GuestEmailSurvey: FC<GuestEmailSurveyProps> = props => {
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const [updateEmailSubscribtion] = useMutation(phoenixAccountUpdateEmailSubscription);
  const [emailInput, setEmailInput] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMessage, setHasMessage] = useState({
    emailUpdatedSuccess: false,
    emailInvalidError: false,
  });
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  //check if any of the checkbox is subscribed
  const hasAnyRadioBtnChecked = emailInput && selectedIndex !== null;

  //handle radio btn for guest survey page
  const handleRadioChange = (index: number) => {
    setSelectedIndex(index);
  };
  //mutation
  const updateGuestConsent = () => {
    setHasError(false);
    setHasMessage({
      emailUpdatedSuccess: false,
      emailInvalidError: false,
    });
    let selectedConsent: { type: string; method: string; subscribed: boolean }[] = [];
    if (selectedIndex !== null) {
      const selectedOption = props?.pageProps?.consentDetails[selectedIndex];
      selectedConsent = [
        {
          type: selectedOption.type,
          method: selectedOption.method,
          subscribed: selectedIndex === 0,
        },
      ];
    }
    const checkEmailFormat = isValidEmaiFormat(emailInput);
    if (!emailInput) {
      setHasMessage(prevState => ({ ...prevState, emailEmptyError: true }));
    } else if (!checkEmailFormat) {
      setHasMessage(prevState => ({ ...prevState, emailInvalidError: true }));
    } else {
      // Check if there are any changes in consents
      setIsLoading(true);
      updateEmailSubscribtion({
        variables: {
          input: {
            action: UNSUBSCRIBE_ACTION,
            email: emailInput,
            options: selectedConsent,
          },
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        onCompleted: data => {
          setIsLoading(false);
          const responseCode = data?.updateAccountCommunicationOptions?.code;
          if (responseCode === SUCCESS_CODE) {
            setHasMessage(prevState => ({ ...prevState, emailUpdatedSuccess: true }));
            setHasError(false);
          } else {
            setHasError(true);
          }
        },
        onError: () => {
          setIsLoading(false);
          setHasError(true);
        },
      });
    }
  };
  return (
    <StyledGuestEmailSurvey>
      <div className="container">
        <Heading
          variation={Types.headingType.title}
          fontSize={Types.size.small}
          titleText={props?.pageProps?.header}
          customClass="after-submitting--header "
        />
        <RichText
          text={props?.pageProps?.description}
          componentId="titleDescription"
          customClass="titleDescription col-lg-10 t-body-m pb-3 px-0"
        />
        {(hasError || hasMessage.emailUpdatedSuccess || hasMessage.emailInvalidError) && (
          <div id="banner-msg" data-testid="banner-msg" className="banner-msg py-3">
            <Messages
              messageType={clsx(hasMessage.emailUpdatedSuccess ? 'success' : 'error-sev1')}
              className="unsubscribe__errormsg px-2"
            >
              {hasMessage.emailUpdatedSuccess && (
                <RichText text={props?.pageProps?.emailUpdatedMsg} componentId="success-msg" />
              )}
              {hasMessage.emailInvalidError && (
                <RichText text={props?.pageProps?.emailErrorMessage} componentId="error-msg" />
              )}
              {hasError && <RichText text={props?.pageProps?.apiFailureErrMsg} componentId="error-msg" />}
            </Messages>
          </div>
        )}
        <RichText
          text={props?.pageProps?.title}
          componentId="titleDescription"
          customClass="col-lg-10 t-body-m pt-3 px-0"
        />
        <InputTextField
          label={props?.pageProps?.emailLabel}
          infoLabel={`${props?.pageProps?.emailLabel}-label`}
          testId="email-input"
          showErrorMessage={!!(props?.pageProps?.inlineEmailInvalidMsg && hasMessage?.emailInvalidError)}
          messageToShow={props?.pageProps?.inlineEmailInvalidMsg}
          messageClass="error-label t-font-xs m-0"
          setErrorHtml={true}
          inputMaxLength={80}
          className={clsx('unsubscribe__email my-5 m-input-text-field', hasMessage.emailInvalidError && ' is-error')}
          getInputValue={val => setEmailInput(val)}
          getInputProps={() => ({
            autoComplete: 'off',
            id: props?.pageProps?.emailLabel,
          })}
        />
      </div>
      <div className="unsubscribe__consents">
        <div className="container my-5">
          <RichText
            text={props?.pageProps?.userInstructionText || ''}
            componentId="optionDescription"
            customClass="t-font-s"
          />
          <div className="m-radio-button-m my-5">
            {props?.pageProps?.consentDetails?.map((item, index) => (
              <RadioButton
                key={index}
                dataTestId={`radioid-${index}`}
                radiobuttonId={`radioid-${index}`}
                radiobuttonName={`radiobtn-${index}`}
                radiobuttonLabel={item.consentTitle}
                value="consent"
                checked={selectedIndex === index}
                onChange={() => handleRadioChange(index)}
                labelClassName="unsubscribe__radiobtn t-body-s mb-3"
                setTabIndex={0}
                aria-label={`radiolabel-${index}`}
                onKeyDown={event => event.key === 'Enter' && handleRadioChange(index)}
              />
            ))}
          </div>
        </div>

        <div className="container unsubscribe__footer d-flex flex-md-row flex-column align-items-center">
          <Button
            className={clsx(
              'm-button-m px-3 m-button-primary modal-footer__unsubscribe ml-md-0',
              !hasAnyRadioBtnChecked && 'disabled'
            )}
            callback={() => updateGuestConsent()}
            isDisabled={!hasAnyRadioBtnChecked} // Disable the button if no subscribed consent
            testId="unsubscribeBtn"
            ariaLabel={props?.pageProps?.submitButtonLabel}
          >
            {isLoading ? (
              <div className="m-spinner-s" data-testid="loading-spinner"></div>
            ) : (
              props?.pageProps?.submitButtonLabel
            )}
          </Button>
          <Button
            className={'modal-footer__cancel  m-link-action mx-md-5  mx-3 mt-5 mt-md-0'}
            buttonCopy={props?.pageProps?.cancelButton}
            isLink={true}
            href={props?.pageProps?.cancelCtaPath}
          />
        </div>
      </div>{' '}
    </StyledGuestEmailSurvey>
  );
};
