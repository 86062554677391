// Imports for external libraries go here.
import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useCheckBreakpoint, Icon, Types, Heading, RichText } from '@marriott/mi-ui-library';
import { BREAKPOINT_DESKTOP_TEXT, useGetBreakpoint } from '../../../modules';
import { useCreateAccountStore } from '../../../modules/store/createAccountStore';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { CreateAccountDescriptionProps } from './CreateAccountDescription.types';
import { StyledCreateAccountDescription } from './CreateAccountDescription.styles';

// Use named rather than default exports.
export const CreateAccountDescription: FC<CreateAccountDescriptionProps> = pageProps => {
  const { isValidPartnerPortal } = useCreateAccountStore();
  const isTabletAndAboveViewPort = useCheckBreakpoint('viewportL');
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  useEffect(() => {
    // using handleResize to avoid flickering of container class happening on the initial load
    const handleResize = () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsDesktop(useGetBreakpoint() === BREAKPOINT_DESKTOP_TEXT);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {
    header,
    subHeader,
    earnFreeNightsLabel,
    memberRatesLabel,
    freeWifiLabel,
    exclusiveOffersLabel,
    isValidClientId,
    sweepStakeObject,
    partnerPortalObject,
  } = pageProps;
  return (
    <StyledCreateAccountDescription className="d-flex flex-column text-center create-account-description pb-4">
      <div className="create-account-description-wrapper px-0">
        <RichText
          text={
            isValidClientId
              ? sweepStakeObject?.title ?? ''
              : isValidPartnerPortal
              ? partnerPortalObject?.title ?? ''
              : header
          }
          customClass={'pb-2 t-title-m mb-0 mb-sm-1'}
          componentId="create-account-header"
        />
        {!isValidClientId && (
          <RichText
            text={isValidPartnerPortal ? partnerPortalObject?.description ?? '' : subHeader}
            customClass={'px-0 pb-0'}
            styleclass={isDesktop ? 't-subtitle-xl' : 't-subtitle-l'}
            componentId="create-account-subHeader"
          />
        )}
      </div>
      {!isValidClientId && (
        <div className="create-account-description-container d-flex row text-center pt-5 mt-2 mt-md-5 mx-0">
          <div
            className={clsx(
              `create-account-description__icon-wrapper d-flex align-items-center col-6 col-md-3 pb-5 ${
                isTabletAndAboveViewPort ? 'flex-row' : 'flex-column'
              }`
            )}
          >
            <Icon iconClass={`create-account-description-container__icon icon-extra-free-nights pb-2 pb-lg-0`}></Icon>
            <Heading
              variation={Types.headingType.subtitle}
              fontSize={Types.size.medium}
              titleText={earnFreeNightsLabel}
              customClass={'create-account-description-container__text  m-0'}
            />
          </div>

          <div
            className={clsx(
              `create-account-description__icon-wrapper d-flex align-items-center col-6 col-md-3 pb-5 ${
                isTabletAndAboveViewPort ? 'flex-row' : 'flex-column'
              }`
            )}
          >
            <Icon iconClass={`create-account-description-container__icon icon-member-rates1x pb-2 pb-lg-0`}></Icon>
            <Heading
              variation={Types.headingType.subtitle}
              fontSize={Types.size.medium}
              titleText={memberRatesLabel}
              customClass={'create-account-description-container__text  m-0'}
            />
          </div>

          <div
            className={clsx(
              `create-account-description__icon-wrapper d-flex align-items-center col-6 col-md-3 pb-5 ${
                isTabletAndAboveViewPort ? 'flex-row' : 'flex-column'
              }`
            )}
          >
            <Icon iconClass={`create-account-description-container__icon icon-wifi pb-2 pb-lg-0`}></Icon>
            <Heading
              variation={Types.headingType.subtitle}
              fontSize={Types.size.medium}
              titleText={freeWifiLabel}
              customClass={'create-account-description-container__text  m-0'}
            />
          </div>

          <div
            className={clsx(
              `create-account-description__icon-wrapper d-flex align-items-center col-6 col-md-3 pb-5 ${
                isTabletAndAboveViewPort ? 'flex-row' : 'flex-column'
              }`
            )}
          >
            <Icon iconClass={`create-account-description-container__icon icon-gift-points1x pb-2 pb-lg-0`}></Icon>
            <Heading
              variation={Types.headingType.subtitle}
              fontSize={Types.size.medium}
              titleText={exclusiveOffersLabel}
              customClass={'create-account-description-container__text  m-0'}
            />
          </div>
        </div>
      )}
    </StyledCreateAccountDescription>
  );
};
