// Imports for external libraries go here.
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { inspect } from 'util';
import { useMutation } from '@apollo/client';
import { Text, Types, Modal, RichText, Messages } from '@marriott/mi-ui-library';
import { apiLogger, generateApolloClientHeaders, getCurrentUrlParams } from '../../../modules/utils';
import { phoenixAccountAddCustomerPreferences } from '../../../modules/graph/index';
import { useProfileStore } from '../../../modules/store/profileStore';
import { PageContext } from '../../../modules/context/PageContext';
import { useBannerMessagesStore } from '../../../modules/store/bannerMessagesStore';
import { BANNER_MSGS_KEYS, constants, FOLIO_CLICK_TRACK } from '../../../modules/utils/constants/constants';
import { FolioOptionsChildProps } from './FolioOptionsChild.types';
import { StyledFolioOptionsChild } from './FolioOptionsChild.styles';

// Use named rather than default exports.
export const FolioOptionsChild: FC<FolioOptionsChildProps> = ({
  resetPreferenceData,
  preferenceData,
  model,
  hasError,
  profileId,
  setProfileId,
  setHasError,
}) => {
  const { profileDetails } = useProfileStore(state => state);
  const [selectedOption, setSelectedOption] = useState('');
  const pageContext = useContext(PageContext);
  const userProfileCountryCode = profileDetails?.profileAddress?.filter(address => address?.primary === true)?.[0];
  const userCountryCode = userProfileCountryCode?.address?.country?.code;
  const [updateCustomerPreferences] = useMutation(phoenixAccountAddCustomerPreferences);
  const { IS_LOCAL_DEV } = process.env;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { setBannerMsgs } = useBannerMessagesStore();

  const folioItems = useMemo(() => {
    if (userCountryCode !== constants?.CHINA_COUNTRY_CODE) {
      return model?.items?.filter(item => item?.id !== 'FAP');
    }
    return model?.items;
  }, [userCountryCode, model]);

  useEffect(() => {
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isFolioOptionsModal = param.get('editFolioOptions');
    if (isFolioOptionsModal === 'true') {
      setProfileId('folio-delivery');
    }
  }, []);

  useEffect(() => {
    if (preferenceData) {
      let selectedPreference;
      if (Array.isArray(preferenceData)) {
        selectedPreference = preferenceData.find(
          (item: { feature: { code: string } }) => item.feature.code === 'billing-preference'
        );
      }
      /**
       * hard code email value for intial load
       * if value is not default is empty
       */
      setSelectedOption(selectedPreference?.value || '');
    }
  }, [preferenceData]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const updatePreferences = (payload: { code: string; value: string }[]) => {
    updateCustomerPreferences({
      variables: {
        input: {
          customerId: sessionData?.consumerID,
          customerFeatures: payload,
        },
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: data => {
        resetPreferenceData();
        setProfileId('');
        setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
        setHasError(false);
        window?.scrollTo(0, 0);
        apiLogger(
          `[CustomerPreferences] updateCustomerPreferences - sessionId value: ${sessionData?.sessionToken}: ${inspect(
            data
          )}`
        );
      },
      onError: error => {
        setHasError(true);
        apiLogger(
          `[CustomerPreferences] updateCustomerPreferences - sessionId value: ${
            sessionData?.sessionToken
          } - error: ${inspect(error)}`
        );
      },
    });
  };

  const resetData = () => {
    setProfileId('');
    setBannerMsgs('');
    setHasError(false);
  };

  return (
    <StyledFolioOptionsChild>
      <Modal
        handleBlur={true}
        show={profileId === model?.modelId}
        popupOpenState={profileId === model?.modelId}
        setPopupOpenState={(): void => {
          resetData();
        }}
        modalId={model?.modelId + 'modal'}
        labelledBy={model?.modelId}
        onClose={(): void => {
          resetData();
        }}
        role="dialog"
      >
        <Modal.Header
          className="t-subtitle-xl py-sm-5 py-4 px-sm-5 px-4"
          customHeadingClass="t-subtitle-xl"
          labelText={model?.header || ''}
          popupHeaderOnCLoseFunc={(): void => {
            resetData();
          }}
          data-testid="edit-folio-header"
        />
        <Modal.Body className="py-sm-5 py-4 px-sm-5 px-4 text_modal-align">
          <div>
            {hasError && (
              <div data-testId="uxl-error-msg" className="uxl-error-msg">
                <Messages messageType="warning" className="mb-4">
                  <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
                </Messages>
              </div>
            )}
            <Text
              copyText={model?.description}
              fontSize={Types.size.medium}
              customClass="t-font-mt-5"
              element={Types.tags.paragraph}
            />
            <div className="mt-5">
              {preferenceData ? (
                folioItems.map((item, index) => (
                  <div className="mb-2 folio-radiobtn" key={index}>
                    <input
                      type="radio"
                      id={item?.id}
                      value={item?.id}
                      name={item?.id}
                      role="radio"
                      data-testid={item?.id}
                      checked={selectedOption === item?.id}
                      onChange={handleOptionChange}
                      className="mb-3"
                    />
                    <label htmlFor={item?.id}>{item?.value}</label>
                  </div>
                ))
              ) : (
                <div className="col-8 folio-option-loader px-0">
                  <div className="skeleton-loader mb-2" />
                  <div className="skeleton-loader mb-2" />
                  <div className="skeleton-loader mb-2" />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className="py-4 py-sm-5 px-4 align-items-baseline justify-content-md-end modal-footer"
          clearButtonLabel={model?.cancelButton}
          clearClickHandler={(): void => {
            resetData();
          }}
          applyLabel={model?.saveButton}
          data-testid="Save"
          applyBtnClickHandler={() => {
            updatePreferences([{ code: 'BILLING_PREFERENCE', value: selectedOption || '' }]);
          }}
          clearBtnClassname="submit-btn-footer mr-sm-5 pr-sm-4 pr-5 mr-5"
          applyBtnClickTrackValue={FOLIO_CLICK_TRACK}
        ></Modal.Footer>
      </Modal>
    </StyledFolioOptionsChild>
  );
};
