/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { inspect } from 'util';
import clsx from 'clsx';
import moment from 'moment';
import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import { usePathname } from 'next/navigation';

import { Eyebrow, Messages, RichText, Types, useCheckBreakpoint } from '@marriott/mi-ui-library';
import {
  accountConstants,
  generateImageRenditions,
  OverviewMovableComponents,
  PROMOTIONS_CONSTANT,
  PageContext,
  addSubDirectoryPrefix,
  apiLogger,
  fallbackImages,
  generateApolloClientHeaders,
  BANNER_MSGS_KEYS,
} from '../../modules';
import { constants } from '../../modules/utils/constants/constants';
import { phoenixAccountGetAllPromotionsByCustomerId } from '../../modules/graph';
import { getImgProcQueryString, getNewPromotions, isPresentPromotion } from '../../modules/utils/promotionHelper';
import { useStore } from '../../modules/store/memberLevelStore';
import { useOverviewStore } from '../../modules/store/overviewStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { LoyaltyPromotionEdge, NewPromotionsProps, RootPromotions } from './NewPromotions.types';
import { StyledNewPromotions } from './NewPromotions.styles';
import promotionsMock from './__mock__/NewPromotions.model.json';

const CardHorizontalFeature = dynamic(() => import('@marriott/mi-ui-library').then(mod => mod.CardHorizontalFeature));
const PromotionEmptyStatus = dynamic(() =>
  import('../../molecules/PromotionEmptyStatus').then(mod => mod.PromotionEmptyStatus)
);
const OverviewSectionHeading = dynamic(() =>
  import('../../molecules/OverviewSectionHeading').then(mod => mod.OverviewSectionHeading)
);

// Use named rather than default exports.
export const NewPromotions: FC<NewPromotionsProps> = (pageProps: any) => {
  const {
    setPromotions,
    setIsPromotionLoading,
    promotions,
    isPromotionLoading,
    showOnboardingSection,
    setShowOnboardingSection,
  } = useStore(state => state);
  const model = pageProps?.model;
  const { IS_LOCAL_DEV } = process.env;
  const isAuthorMode = pageProps?.isAuthorMode;
  const pageContext = useContext(PageContext);
  const isOverViewPage = pageContext?.isOverViewPage;
  const dataLoaded = useRef<boolean>(false);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { componentMoved, setComponentHasData, setComponentHasError, setComponentIsLoading } = useOverviewStore(
    state => state
  );
  const isPromotionsMoved = componentMoved === OverviewMovableComponents.PROMOTIONS;
  const [hasPromotions, setHasPromotions] = useState<boolean>(false);
  const isViewportS = useCheckBreakpoint('viewportS');
  const isDestopAndAbove = useCheckBreakpoint('viewportL');
  const { bannerMsgs } = useBannerMessagesStore(state => state);
  const isUpcomingTripSelected = useStore(state => state.isUpcomingTripSelected);
  const { setBannerMsgs } = useBannerMessagesStore(state => state);

  const isMytripsPage = usePathname()?.includes('/findReservationList') ? true : false;
  const bannerMsgStatus = pageContext?.enrollAndLinkStatus;
  const checksIsOcj = () => {
    if (isMytripsPage && bannerMsgStatus) {
      return true;
    } else if (
      isMytripsPage &&
      (bannerMsgs.key === BANNER_MSGS_KEYS.NEW_MEMBER_NUMBER_MSG ||
        bannerMsgs.key === BANNER_MSGS_KEYS.RESERVATION_NOT_LINKED_ERROR_MSG)
    ) {
      return true;
    }
    return false;
  };

  const isOcj = useMemo(() => checksIsOcj(), []);

  useEffect(() => {
    if (isOcj && bannerMsgStatus) {
      if (bannerMsgStatus === constants.STATUS_SUCCESS) {
        setBannerMsgs(BANNER_MSGS_KEYS.NEW_MEMBER_NUMBER_MSG);
      } else if (bannerMsgStatus === constants.STATUS_FAILURE) {
        setBannerMsgs(BANNER_MSGS_KEYS.RESERVATION_NOT_LINKED_ERROR_MSG);
      }
    }
    if (isAuthorMode) {
      setPromotions(promotionsMock.data);
      setHasPromotions(true);
      isOverViewPage && setComponentHasData(OverviewMovableComponents.PROMOTIONS, true);
    }
  }, []);

  const imageDimensions = {
    height: 260,
    width: 2600,
  };
  const imgProcQuery = getImgProcQueryString(
    6,
    90,
    PROMOTIONS_CONSTANT.PROMOTION_IMG_OUTPUT_INTERPOLATION,
    imageDimensions
  );

  const getRenditions = (src: string) => {
    const imageSizeList = [
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.desktop,
        queryType: 'min-width',
        imageWidth: isPromotionsMoved ? 434 : 656,
      },
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.largerTablet,
        queryType: 'min-width',
        imageWidth: isPromotionsMoved ? 312 : 472,
      },
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.mediumTablet,
        queryType: 'min-width',
        imageWidth: isPromotionsMoved ? 344 : 472,
      },
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.tablet,
        queryType: 'min-width',
        imageWidth: isPromotionsMoved ? 344 : 352,
      },
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.mobile,
        queryType: 'min-width',
        imageWidth: 524,
      },
      {
        breakpoint: accountConstants.VIEWPORT_SIZE.mobile,
        queryType: 'max-width',
        imageWidth: 398,
      },
    ];
    return generateImageRenditions(src, imageSizeList);
  };

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    (!isOcj && isMytripsPage) ||
    dataLoaded.current ||
    isAuthorMode;

  const { loading, error } = useQuery(phoenixAccountGetAllPromotionsByCustomerId, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    skip: skipQuery,
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    onCompleted: (data: any) => {
      setPromotions(data);
      isOverViewPage && setComponentHasData(OverviewMovableComponents.PROMOTIONS, hasOverviewPromotions(data));
      setShowOnboardingSection(hasMyTripsPromotions(data));
      setIsPromotionLoading(false);
      apiLogger(
        `[NewPromotions] getAllPromotionsData - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`
      );
    },
    onError: error => {
      isOverViewPage && setComponentHasError(OverviewMovableComponents.PROMOTIONS, true);
      apiLogger(
        `[NewPromotions] getAllPromotionsData - sessionId value:: ${sessionData?.sessionToken} - error: ${inspect(
          error
        )}`
      );
    },
  });

  useEffect(() => {
    if (loading) {
      setIsPromotionLoading(true);
      isOverViewPage && setComponentIsLoading(OverviewMovableComponents.PROMOTIONS, true);
    }
  }, [loading]);

  const hasOverviewPromotions = (promotions: RootPromotions | null) => {
    const today = moment();
    const newPromotions = getNewPromotions(promotions);
    const registeredPromotions = [];
    promotions?.loyaltyPromotions?.edges.forEach(promo => {
      if (isPresentPromotion(promo, today)) {
        registeredPromotions.push(promo);
      }
    });
    const hasPromotionData = newPromotions.length > 0 || registeredPromotions.length > 0;
    setHasPromotions(hasPromotionData);
    return hasPromotionData;
  };

  const hasMyTripsPromotions = (promotions: RootPromotions | null) => {
    const newPromotions = getNewPromotions(promotions);
    const hasPromotionData = newPromotions.length > 0;
    return hasPromotionData ? false : true;
  };

  const newPromotions = useMemo(() => {
    const unRegLoyaltyPromo = getNewPromotions(promotions);
    const sortedNewPromotions = [...(unRegLoyaltyPromo ?? [])].sort((newPromoA, newPromoB) => {
      const endDateA = moment(newPromoA.node?.offers?.[0]?.hurdle?.endDate);
      const endDateB = moment(newPromoB.node?.offers?.[0]?.hurdle?.endDate);
      return endDateA.isBefore(endDateB) ? -1 : endDateA.isAfter(endDateB) ? 1 : 0;
    });

    if ((isOverViewPage || isOcj) && sortedNewPromotions.length > 0) {
      return sortedNewPromotions.slice(0, 1);
    }
    return sortedNewPromotions;
  }, [promotions]);

  if (error) {
    return (
      <div id={OverviewMovableComponents.PROMOTIONS} data-testid="uxl-error-msg">
        <Messages messageType="warning" className="my-4">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      </div>
    );
  }
  const getDesc = (promotion: LoyaltyPromotionEdge) => {
    const desc = promotion?.node?.offers?.[0]?.descriptions?.descriptions.find(
      desc => desc.key === PROMOTIONS_CONSTANT.PROMOTION_DETAIL_KEYS[0]
    );
    return desc !== undefined ? desc.value.replace(PROMOTIONS_CONSTANT.HTML_TAG_REGEX, '') : '';
  };

  return (
    <StyledNewPromotions
      data-component-name="o-account-newpromotions"
      data-testid="newpromotions"
      id={OverviewMovableComponents.PROMOTIONS}
      // data-testid={OverviewMovableComponents.PROMOTIONS}
      isPromotionsMoved={isPromotionsMoved}
      className={clsx(
        !isOverViewPage && !showOnboardingSection && !isMytripsPage && 'mb-4 mb-md-5',
        isOcj && !showOnboardingSection && 'pb-md-2'
      )}
    >
      {((!isOverViewPage && !isOcj) ||
        (isOcj && isUpcomingTripSelected) ||
        (isOverViewPage && hasPromotions && componentMoved)) && (
        <div
          className={clsx('container', isOcj ? 'pt-0' : isOverViewPage && !isPromotionsMoved ? '' : ' pt-3 pt-md-4')}
        >
          {isOverViewPage ? (
            <>
              <OverviewSectionHeading
                title={model?.promotionsTitle}
                ctaLabel={model?.promotionsCtaLabel}
                ctaPath={model?.promotionsCtaPath}
                sectionHeadingClass={clsx(isPromotionsMoved ? 'px-4 py-3' : 'pt-4 pt-md-5 mt-md-2')}
                isComponentMoved={isPromotionsMoved}
              />
              {isPromotionsMoved && <div className="border-line"></div>}
            </>
          ) : (
            !isMytripsPage && (
              <span className="color-scheme1">
                <Eyebrow text={model?.sectionTitle} />
              </span>
            )
          )}
          {isPromotionLoading ? (
            <div className="card__container__skeleton d-flex justify-content-between mt-4">
              <div className="d-flex flex-column card__container justify-content-between p-4">
                <div className="skeleton-loader card__container__skeleton__label"></div>
                <div className="skeleton-loader card__container__skeleton__desc"></div>
                <div className="skeleton-loader card__container__skeleton__btn"></div>
              </div>
              <div className="skeleton-loader mx-sm-auto mx-md-0 card__container__skeleton__img"></div>
            </div>
          ) : newPromotions && newPromotions.length > 0 ? (
            newPromotions?.map((promotion, index) => (
              <div
                className={clsx(
                  isOverViewPage ? !isPromotionsMoved && 'mt-3' : isOcj ? 'mt-3' : 'mt-4',
                  isViewportS && index !== newPromotions.length - 1 && 'pb-3'
                )}
                key={index}
                data-testid={`newPromo-${index}`}
              >
                <CardHorizontalFeature
                  assetVariation="image"
                  header={promotion?.node?.descriptions?.name}
                  key={index}
                  description={getDesc(promotion)}
                  imageAlignment={!isViewportS || (isPromotionsMoved && !isDestopAndAbove) ? 'left' : 'right'}
                  contentBlockAlignment={!isViewportS || (isPromotionsMoved && !isDestopAndAbove) ? 'center' : 'left'}
                  ctaType="secondaryButton"
                  linkText={model?.ctaButtonLabel}
                  linkUrl={addSubDirectoryPrefix(PROMOTIONS_CONSTANT.PROMOTIONS_URL)?.replace(
                    '{promotionCode}',
                    promotion?.node?.id
                  )}
                  trackingProperties={{ clickTrack: true }}
                  styleclass="card__container m-0"
                  descriptionCustomClass="t-font-m"
                  headerTag={Types.tags.h2}
                  headerFontSize={Types.size.small}
                  headerCustomClass={isPromotionsMoved ? 't-subtitle-xl' : ''}
                  dynamicMedia={{
                    altText:
                      promotion?.node?.media && promotion?.node?.media?.images?.length > 0
                        ? promotion.node.media.images?.[promotion.node.media.images.length - 1].alternateText
                        : '',
                    assetPath:
                      promotion?.node?.media && promotion?.node?.media?.images?.length > 0
                        ? promotion.node.media.images?.[promotion.node.media.images.length - 1].url
                        : fallbackImages.Classic,
                    dynamic: true,
                    renditions: getRenditions(
                      promotion?.node && promotion?.node?.media?.images?.length > 0
                        ? promotion.node.media.images[promotion.node.media.images.length - 1].url
                        : fallbackImages.Classic + imgProcQuery
                    ),
                  }}
                  openInNewTab={false}
                />
              </div>
            ))
          ) : (
            !isOverViewPage &&
            !isMytripsPage && (
              <div className="mt-3">
                <PromotionEmptyStatus message={model?.description} />
              </div>
            )
          )}
        </div>
      )}
    </StyledNewPromotions>
  );
};

export const NewPromotionsConfig = {
  emptyLabel: 'NewPromotions',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/newpromotions`,
};
export const NewPromotionsEditable = (props: any) => {
  return (
    <EditableComponent config={NewPromotionsConfig} {...props}>
      <NewPromotions {...props} />
    </EditableComponent>
  );
};
