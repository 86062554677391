import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Heading, Link, Text, Types } from '@marriott/mi-ui-library';

import { constants, correctSubDirectoryPathForHeader, clearSessionAndCookie, getUserRememberData } from '../../modules';
import { SYSTEM_MAINTENANCE_URL } from '../../modules/utils/constants';
import { usePointsTransferStore } from '../../modules/store/pointsTransferStore';
import { updatePointsTransferDataLayer } from '../PointsTransfer/PointsTransferUtils/PointsTransferHelper';
import { SignoutData } from './SignOut.types';
import { StyledSignOut } from './SignOut.styles';

export const SignOut: FC<SignoutData> = (props: SignoutData) => {
  const {
    setVerificationPointsFailed,
    setVerificationPointsSuccess,
    setTransferPointsDetails,
    setIsResetState,
    isVerificationPointsFailed,
    transferPointsDetails,
  } = usePointsTransferStore(store => store);

  const router = useRouter();
  const slugPathName = router.query['page'];
  const showPointsTransferVariation =
    !slugPathName?.includes(constants?.LOGOUT_URL) && !slugPathName?.includes(constants.SYSTEM_MAINTENANCE);
  const [isPointsTranferFailed, setIsPointsTranferFailed] = useState(false);

  useEffect(() => {
    if (!props.isAuthorMode && slugPathName?.includes(constants?.LOGOUT_URL)) {
      getUserRememberData('', false, false);
    }
  }, []);

  useEffect(() => {
    setIsPointsTranferFailed(isVerificationPointsFailed);
  }, [isVerificationPointsFailed]);

  useEffect(() => {
    correctSubDirectoryPathForHeader();
    if (showPointsTransferVariation) {
      if (isPointsTranferFailed) {
        updatePointsTransferDataLayer(transferPointsDetails?.partnerConfig?.dataLayerName ?? '');
        sessionStorage.removeItem('pointsTransferState');
      }
    } else if (!slugPathName?.includes(SYSTEM_MAINTENANCE_URL)) {
      clearSessionAndCookie();
    }
  }, [isPointsTranferFailed, showPointsTransferVariation]);

  const handlePointsTranfer = (e: Event) => {
    e.preventDefault();
    setTransferPointsDetails({
      partnerId: '',
      partnerName: '',
      partnerNumber: '',
      pointsToTransfer: '',
      typeOfPointsTransfer: '',
      partnerConfig: undefined,
    });
    setVerificationPointsSuccess(false);
    setVerificationPointsFailed(false);
    setIsResetState(true);
  };
  return (
    <StyledSignOut data-testid="signout" data-component-name="o-account-signout">
      {((showPointsTransferVariation && isPointsTranferFailed) || !showPointsTransferVariation) && (
        <div className="container mt-4 spacing-top-bottom">
          <div className="pb-5">
            <Heading variation={Types.headingType.title} titleText={props?.model?.title} customClass={'t-title-s'} />
            <Text
              copyText={
                showPointsTransferVariation && isPointsTranferFailed
                  ? `${props?.model?.subTitle} `
                  : props?.model?.subTitle
              }
              fontSize={Types.size.large}
              customClass={'t-subtitle-m'}
              element={Types.tags.span}
            />
            {showPointsTransferVariation && isPointsTranferFailed && (
              <Link
                text={props?.model?.subTitleCtaLabel}
                linkClassName="m-link py-0"
                linkHref={'#'}
                target="_self"
                linkType="internal"
                callback={handlePointsTranfer}
              />
            )}
          </div>
          <div
            className="mt-4 mt-md-5 t-font-s link-underline-dark paragraphh"
            dangerouslySetInnerHTML={{ __html: props?.model?.description }}
          ></div>
        </div>
      )}
    </StyledSignOut>
  );
};

export const SignOutConfig = {
  emptyLabel: 'SignOut',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/signout`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SignOutEditable = (props: any) => {
  return (
    <EditableComponent config={SignOutConfig} {...props}>
      <SignOut {...props} />
    </EditableComponent>
  );
};
