// Imports for external libraries go here.
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Heading, Icon, InputTextField, Link, Messages, Types, RichText, Text } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import axios from 'axios';
import { inspect } from 'util';
import { useQuery } from '@apollo/client';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Dropdown } from '../../../molecules/Dropdown';
import { constants, LINK_ACCOUNT_CLICK_TRACK } from '../../../modules/utils/constants';
import { addClassToBlankTargetLinks, nonDigitRegex } from '../../../modules/utils';
import { useAccountPersistentStore } from '../../../modules/store/accountPersistentStore';
import { usePointsTransferStore } from '../../../modules/store/pointsTransferStore';
import { numberWithCommas } from '../../../modules/utils/numericalFormatsHelper';
import { apiLogger, PageContext, useCheckBreakpoint, generateApolloClientHeaders } from '../../../modules';
import { phoenixAccountGetRelationshipList } from '../../../modules/graph';
import { PointsTransferErrors } from '../PointsTransfer.types';
import {
  handlePartnerPoints,
  updatePointsTransferDataLayer,
  errorKeyHandler,
} from '../PointsTransferUtils/PointsTransferHelper';
import {
  TransferPointsProps,
  FormData,
  FormDataErrors,
  PartnerList,
  PartnersList,
  PartnerDetails,
} from './TransferPartnerPoints.types';
import { StyledTransferPoints } from './TransferPartnerPoints.styles';
import ConvertPointsMock from './__mock__/ConvertPoints.json';

// Use named rather than default exports.
export const TransferPartnerPoints: FC<TransferPointsProps> = props => {
  const { IS_LOCAL_DEV } = process.env;
  const defaultErrorsList = props?.errorList?.['default']?.[0];
  const initailState = {
    partnerID: '',
    partnerName: '',
    partnerAccountNumber: '',
    points: '',
    convertedPoints: '',
  };

  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [partnersList, setPartnersList] = useState<PartnersList | undefined>();
  const [partnerDetails, setPartnerDetails] = useState<PartnerDetails | undefined>();
  const [partnerSelectionList, setPartnerSelectionList] = useState<PartnerList[]>([]);
  const [formData, setFormData] = useState<FormData>(initailState);
  const [errorList, setErrorList] = useState<PointsTransferErrors>();
  const [formErrors, setFormErrors] = useState<FormDataErrors>({});
  const [pointsError, setPointsError] = useState<string | undefined>('');
  const [accountNumberError, setAccountNumberError] = useState<string | undefined>('');
  const [checkLinking, setCheckLinking] = useState<boolean>(false);
  const [accountLinkMessage, setAccountLinkMessage] = useState({ accountLinkingRequired: false, hasEntityId: false });
  const currentPointsBalance = useAccountPersistentStore(state => state.currentPointBalance);
  const { errorKey, transferPointsDetails, setErrorKey, setTransferPointsDetails } = usePointsTransferStore(
    store => store
  );
  const [pointsBalance, setPointsBalance] = useState<number | undefined>();
  const [isUXLError, setIsUXLError] = useState(false);
  const isValidFormData = Object.values(formData).every((value: string) => value?.trim().length !== 0);
  const formErrorsList = Object.values(formErrors).filter((error: string) => error !== undefined);
  const isTabletViewPort = useCheckBreakpoint('viewportM');
  const isBtnDisabled =
    !isValidFormData ||
    formErrorsList?.length > 0 ||
    !!pointsError ||
    !!accountNumberError ||
    !formData?.convertedPoints;

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !props?.isAuthorMode;
    }, [pageContext, props?.isAuthorMode]) || props?.isAuthorMode;

  useEffect(() => {
    if (currentPointsBalance !== undefined) {
      setPointsBalance(currentPointsBalance);
    }
  }, [currentPointsBalance]);

  useEffect(() => {
    if (errorKey) {
      errorKeyHandler(
        errorKey,
        setFormErrors,
        props.errorList?.[transferPointsDetails.partnerId]?.[0] as { [key: string]: string },
        transferPointsDetails.partnerConfig,
        () => {
          setIsUXLError(true);
        }
      );
      updatePointsTransferDataLayer(transferPointsDetails?.partnerConfig?.dataLayerName ?? '');
      setTransferPointsDetails({
        partnerId: '',
        partnerName: '',
        partnerNumber: '',
        pointsToTransfer: '',
        typeOfPointsTransfer: '',
        partnerConfig: undefined,
      });
      setErrorKey('');
    }
  }, [errorKey]);

  const getPartnersDetails = async () => {
    try {
      const response = await axios.get(props?.convertPointsMappingUrl);
      const partnersListReponse = response.data.pointsTransferData;
      setPartnersList(partnersListReponse);
    } catch (error) {
      setIsUXLError(true);
      apiLogger(
        `[TransferPartnerPoints] getPartnersDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(error)}`
      );
    }
  };

  useEffect(() => {
    const updatedPartnerSelectionList =
      props?.partnerSelectionList?.marriottcountrylist?.list
        ?.filter(partner => partner.code !== null && partner.code !== undefined)
        ?.map(partner => {
          return {
            id: partner?.code || '',
            value: partner?.name || '',
            showPartnerForLocales: partner?.showPartnerForLocales,
            accountLinkingRequired: partner?.accountLinkingRequired,
            partnerUnlinkLabel: partner?.partnerUnlinkLabel,
          };
        }) || [];
    setPartnerSelectionList(updatedPartnerSelectionList);
    const defaultPartner = updatedPartnerSelectionList?.find(partner => !partner.id);
    setFormData({ ...formData, partnerID: defaultPartner?.id || '', partnerName: defaultPartner?.value || '' });

    if (!props?.isAuthorMode) {
      getPartnersDetails();
    } else {
      setPartnersList(ConvertPointsMock);
    }
  }, []);

  // *************** UXL ****************************
  const {
    loading: relationShipListLoading,
    data: relationShipListData,
    error: relationShipListError,
  } = useQuery(phoenixAccountGetRelationshipList, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    fetchPolicy: 'no-cache',
    skip: skipQuery,
  });

  useEffect(() => {
    if (!props.isAuthorMode) {
      if (relationShipListData) {
        const relationShipEntities: Record<string, string> = {};
        relationShipListData?.customer?.loyaltyInformation?.programPartners?.relationshipList?.relationships?.forEach(
          (relationship: {
            relatedEntityId: string;
            relationshipId: string;
            relationshipType: {
              code: string;
            };
          }) => {
            if (relationship?.relationshipType?.code) {
              relationShipEntities[relationship?.relationshipType?.code] = relationship?.relatedEntityId;
            }
          }
        );

        const updatedPartnerSelectionList =
          props?.partnerSelectionList?.marriottcountrylist?.list
            ?.filter(partner => partner.code !== null && partner.code !== undefined)
            ?.map(partner => {
              let partnerLabel = partner?.name;
              if (partner?.accountLinkingRequired && partner?.enumCode && !relationShipEntities[partner?.enumCode]) {
                partnerLabel = partner?.partnerUnlinkLabel;
              }
              return {
                ...partner,
                id: partner?.code || '',
                value: partnerLabel || '',
                entityId: partner?.enumCode ? relationShipEntities[partner?.enumCode] : '',
              };
            }) || [];

        setPartnerSelectionList(updatedPartnerSelectionList);
      }
    }
  }, [relationShipListData]);

  const handlePartnerNameChange = (event: PartnerList) => {
    setAccountLinkMessage({ accountLinkingRequired: false, hasEntityId: false });
    setCheckLinking(event?.accountLinkingRequired ?? false);
    setIsUXLError(false);

    if (relationShipListError && event?.accountLinkingRequired) {
      setIsUXLError(true);
    } else {
      if (event?.accountLinkingRequired && event?.entityId) {
        setAccountLinkMessage({ accountLinkingRequired: true, hasEntityId: true });
      } else if (event?.accountLinkingRequired && !event?.entityId) {
        setAccountLinkMessage({ accountLinkingRequired: true, hasEntityId: false });
      }
    }

    setFormData({
      ...initailState,
      partnerID: event?.id,
      partnerName: event.value,
      partnerAccountNumber: event?.entityId || '',
    });
    setErrorList(props.errorList[event.id]?.[0]);
    const partnerId = event.id;
    setPartnerDetails({ ...partnersList?.[partnerId], accountLinkingCheck: event?.accountLinkingRequired });
    setFormErrors({});
    setPointsError('');
    setAccountNumberError('');
  };

  const handlePartnerAccountNumberChange = (accountNumber: string) => {
    let accountNumberError;
    let inlineAccountError;
    const hasSpecialChar = nonDigitRegex.test(accountNumber);
    if (accountNumber && hasSpecialChar && formData?.partnerID) {
      accountNumberError = errorList?.nonNumericAccountNoErrorMessage
        ? errorList?.nonNumericAccountNoErrorMessage
        : defaultErrorsList?.nonNumericAccountNoErrorMessage;
      inlineAccountError = errorList?.invalidCharOrSpaceErrorMessage
        ? errorList?.invalidCharOrSpaceErrorMessage
        : defaultErrorsList?.invalidCharOrSpaceErrorMessage;
    }

    setAccountNumberError(inlineAccountError);
    setFormErrors({ ...formErrors, invalidAccountNumber: accountNumberError });
    setFormData({ ...formData, partnerAccountNumber: accountNumber });
  };

  const handlePartnerPointsChange = (value: string) => {
    const partnerPointsUpdatedData = handlePartnerPoints(
      value,
      partnerDetails ?? {},
      formData,
      errorList ?? {},
      pointsBalance,
      defaultErrorsList
    );

    setPointsError(partnerPointsUpdatedData?.inlinePointsError);
    setFormErrors({ ...formErrors, invalidPoints: partnerPointsUpdatedData?.invalidPointsError });
    setFormData({
      ...formData,
      points: value,
      convertedPoints: !partnerPointsUpdatedData?.inlinePointsError ? partnerPointsUpdatedData?.convertedPoints : '',
    });
  };

  const validateData = (formData: FormData) => {
    const errors: FormDataErrors = {};
    let isDataInvalid = false;
    if (
      partnerDetails?.accountNumberLength &&
      formData?.partnerAccountNumber?.length !== Number(partnerDetails?.accountNumberLength) &&
      formData?.partnerID === partnerDetails?.code
    ) {
      errors.invalidAccountNumber = errorList?.sixteenDigitAccountNoErrorMessage;
      setAccountNumberError(errorList?.sixteenDigitAccountNoErrorMessage);
      isDataInvalid = true;
    }

    setFormErrors(errors);
    return isDataInvalid;
  };

  const handlePointsSubmission = () => {
    setIsUXLError(false);
    setisLoading(true);
    const isDataInvalid = validateData(formData);
    if (isDataInvalid) {
      setisLoading(false);
      return;
    }

    const pointsDetails = {
      partnerId: formData?.partnerID,
      partnerName: formData?.partnerName,
      partnerNumber: formData?.partnerAccountNumber,
      pointsToTransfer: formData?.points?.replace(/^0+/, ''), // removing leading zero's
    };
    props?.setTransferPointsDetails({ ...pointsDetails, isVerifyConvertPoints: true, partnerConfig: partnerDetails });
    setisLoading(false);
  };

  return (
    <StyledTransferPoints>
      <Heading
        customClass={'pb-3 pb-lg-4'}
        titleText={props?.transferHeader}
        variation={Types.headingType.title}
        fontSize={Types.size.small}
      />
      <Heading
        customClass={clsx('subheader', { 'pb-2': !!formErrorsList?.length })}
        titleText={props?.transferSubHeader}
        variation={Types.headingType.body}
        fontSize={Types.size.medium}
      />
      {isUXLError ? (
        <Messages messageType="error-sev1" className="mt-2 mt-md-0 mb-5 px-2 points-page-error-msg">
          <RichText
            text={errorList?.partnerErrorMessage ? errorList?.partnerErrorMessage : pageContext?.uxlErrorMessage}
            componentId="uxl-error-msg"
          />
        </Messages>
      ) : (
        !!formErrorsList?.length && (
          <Messages messageType="error-sev1" className="mt-2 mt-md-0 mb-5 px-2 points-page-error-msg t-font-s">
            <RichText text={formErrorsList?.[0]} componentId="uxl-error-msg" />
          </Messages>
        )
      )}
      <div className="current-balance py-4 px-3 d-flex justify-content-start align-items-center">
        <Icon iconClass="icon-buy-points icon-l mi-icon icon-decorative my-auto my-md-0"></Icon>
        {pointsBalance !== undefined ? (
          <span className={clsx(isTabletViewPort ? 't-subtitle-xl' : 't-subtitle-l')}>
            {props?.currentBalText?.replace('{0}', numberWithCommas(pointsBalance))}
          </span>
        ) : (
          <div className="skeleton-loader current-balance__loader " />
        )}
      </div>
      <div className="d-flex flex-column transfer-details-container">
        <div className="transfer-details-container__header px-3 py-4 px-md-5">
          <Heading
            element={Types.tags.span}
            titleText={props?.transferSectionHeader}
            variation={Types.headingType.subtitle}
            fontSize={Types.size.extraLarge}
          />
        </div>
        <div className="transfer-details-container__form px-4 px-md-5 py-4 py-md-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="m-input-field">
                <label
                  className={clsx('transfer-details-container__form__partnerlabel', {
                    'label-error': accountLinkMessage?.accountLinkingRequired && !accountLinkMessage?.hasEntityId,
                  })}
                  id="dropdown-label-fp-partner"
                  htmlFor="dropdown-label-fp-partner"
                  data-testid="partner-label"
                >
                  {props?.partnerLabel}
                </label>
                <div className="transfer-details-container__form__dropdown-container">
                  <Dropdown
                    isDisabled={relationShipListLoading}
                    hasError={accountLinkMessage?.accountLinkingRequired && !accountLinkMessage?.hasEntityId}
                    defaultOption={formData?.partnerName}
                    dropdownOptions={partnerSelectionList}
                    onChange={event => handlePartnerNameChange(event)}
                    customClassName="transfer-details-container__form__dropdown-container__dropdown is-active"
                    dropdownId="fp-partner"
                  />
                </div>
              </div>
              {accountLinkMessage?.accountLinkingRequired && (
                <div className="d-flex pt-1 justify-content-start align-items-center message-gap">
                  {!accountLinkMessage?.hasEntityId && (
                    <Icon iconClass="icon-information icon-info icon-s mi-icon "></Icon>
                  )}
                  <div
                    className={clsx(
                      't-label-xs',
                      !accountLinkMessage?.hasEntityId ? 'transfer-details-container__form__validation-msg' : ''
                    )}
                  >
                    {accountLinkMessage?.hasEntityId && <span>{props?.linkedMessage}</span>}
                    {!accountLinkMessage?.hasEntityId && (
                      <div
                        className="transfer-details-container__form__unlinked-msg"
                        data-testid="account-nonlink-message"
                      >
                        <Text
                          fontSize={Types.size.extraSmall}
                          element={Types.tags.span}
                          copyText={props?.unLinkedMessage}
                          customClass={'transfer-details-container__form__unlinked-msg__text'}
                        />{' '}
                        <Link
                          text={props?.linkYourAccountsCtaLabel}
                          ariaLabel={props?.linkYourAccountsCtaLabel}
                          linkHref={props?.linkYourAccountsCtaPath}
                          target="_target"
                          custom_click_track_value={LINK_ACCOUNT_CLICK_TRACK}
                          linkClassName="transfer-details-container__form__unlinked-msg__link m-link-action"
                        />
                        <Icon iconClass="icon-external icon-external-arrow icon-s mi-icon " />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {!checkLinking ? (
              <div className="col-12 col-md-6">
                <InputTextField
                  testId="partner-account-number-field"
                  iconClass="icon icon-clear icon-m input-valid-check"
                  label={props?.partnerAccountNumberLabel}
                  inputValue={formData?.partnerAccountNumber}
                  showErrorMessage={formErrors?.invalidAccountNumber !== undefined}
                  showIcon={formErrors?.invalidAccountNumber !== undefined}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: 'partneraccountnumber',
                  })}
                  getLabelProps={() => ({
                    htmlFor: 'partneraccountnumber',
                  })}
                  className="m-input-field mt-5 mt-md-0"
                  getInputValue={(val: string) => {
                    handlePartnerAccountNumberChange(val);
                  }}
                />
                {accountNumberError && (
                  <div className="t-label-s pt-1 transfer-details-container__form__validation-msg">
                    {accountNumberError}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="row">
            <div
              className={clsx('col-12 pb-4 pt-5', {
                'transfer-details-container__form__sectionSubHeader': !accountLinkMessage?.accountLinkingRequired,
              })}
            >
              <Heading
                element={Types.tags.span}
                titleText={props?.sectionSubHeader}
                variation={Types.headingType.subtitle}
                fontSize={Types.size.medium}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-start align-items-center">
            <div className="col-6 transfer-details-container__form__points">
              <InputTextField
                testId="points-field"
                label={props?.pointsLabel}
                inputValue={formData?.points}
                iconClass="icon icon-clear icon-m input-valid-check"
                showIcon={formErrors?.invalidPoints !== undefined || !!pointsError}
                showErrorMessage={formErrors?.invalidPoints !== undefined}
                setErrorHtml={true}
                messageClass="error-label"
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'points',
                })}
                getLabelProps={() => ({
                  htmlFor: 'points',
                })}
                className={clsx('m-input-field', { 'is-error': !!pointsError })}
                getInputValue={(val: string) => {
                  handlePartnerPointsChange(val);
                }}
              />
            </div>
            <span className=" transfer-details-container__form__separator">{constants.CONVERSION_POINTS_SYMBOL}</span>
            <div className="col-6 ">
              <InputTextField
                testId="convert-points-field"
                label={props?.partnerRewards}
                inputValue={formData?.convertedPoints}
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'converted-points',
                })}
                getLabelProps={() => ({
                  htmlFor: 'converted-points',
                })}
                className="m-input-field is-disabled"
                inputTextFieldClassName="transfer-details-container__form__converted-points"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {pointsError && (
                <div className="t-label-s pt-1 transfer-details-container__form__validation-msg">{pointsError}</div>
              )}
            </div>
          </div>
          {partnerDetails && formData.partnerID === partnerDetails?.code && errorList?.maxReceiveAmtErrorMessage && (
            <div className="row">
              <div className="col-12 partner-instruction pt-5 d-flex justify-content-start align-items-start align-items-md-center">
                <Icon iconClass="icon-information icon-m mi-icon "></Icon>
                <RichText
                  customClass="t-font-s"
                  text={addClassToBlankTargetLinks(
                    errorList?.maxReceiveAmtErrorMessage?.replace(
                      '{0}',
                      numberWithCommas(partnerDetails?.maxGiveAmt ?? '')
                    )
                  )}
                  componentId={'partner-user-instruction'}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Heading
        customClass={'user-instruction'}
        titleText={props?.userInstruction}
        variation={Types.headingType.body}
        fontSize={Types.size.small}
        element={Types.tags.paragraph}
      />
      <div className="col-12 d-flex align-items-center flex-column flex-md-row mt-5 px-0 space-bottom">
        <Button
          ariaLabel="continuebtn"
          testId="continuebtn"
          isDisabled={isLoading || isBtnDisabled}
          className={clsx('m-button-m m-button-primary continue_btn', isBtnDisabled ? 'disabled' : '')}
          type={Types.ButtonTypeVariation.Button}
          callback={handlePointsSubmission}
        >
          {isLoading ? <div className="m-spinner-s"></div> : props?.continueButtonLabel}
        </Button>
        <Link
          text={props?.cancel}
          linkClassName="m-link-action mt-5 mt-md-0 ml-md-5 cancel"
          linkHref={props?.cancelCtaPath || ''}
          target="_self"
          linkType="internal"
        />
      </div>
    </StyledTransferPoints>
  );
};
