// Imports for external libraries go here.
import React, { useState, useContext, useRef, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { inspect } from 'util';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
// import { FeaturePreferencesProps } from './FeaturePreferences.types';
import {
  Modal,
  Accordion,
  Types,
  Icon,
  CheckBox,
  Heading,
  RadioButton,
  Messages,
  RichText,
} from '@marriott/mi-ui-library';
import {
  phoenixAccountGetCustomerPreferenceFeatures,
  phoenixAccountAddCustomerPreferences,
} from '../../../modules/graph';
import { useProfileStore } from '../../../modules/store/profileStore';
import { PageContext } from '../../../modules/context/PageContext';
import {
  HYPHEN_SYMBOL,
  UNDERSCORE_SYMBOL,
  COLON_SYMBOL,
  SPA_LOV_TYPE,
  GOLF_LOV_TYPE,
  FoodBevIconsType,
  ValueOrder,
} from '../../../modules/utils/constants';
import {
  apiLogger,
  generateApolloClientHeaders,
  getCurrentUrlParams,
  checkAndSetConsentChecked,
  updateConsentFeatureOptOut,
  scrollToClass,
} from '../../../modules/utils';
import { useBannerMessagesStore } from '../../../modules/store/bannerMessagesStore';
import {
  BANNER_MSGS_KEYS,
  OPTED_OUT_PERSONALIZATION_KEY,
  INTERESTS_PASSIONS_CLICK_TRACK,
  constants,
} from '../../../modules/utils/constants/constants';
import FeaturesMock from '../__mock__/InterestsAndHobbies.model.json';
import { StyledInterestsAndHobbiesModal } from './InterestsAndHobbiesModal.styles';
import { ConsentDetails } from '../InterestsAndHobbies.types';
import {
  PersonalItems,
  PreferencesFeatures,
  ItemTypeObj,
  CustomerType,
  Hobby,
  InterestOrHobby,
  FilteredDataItems,
  FilteredData,
  SpaOrGolf,
  InterestsAndHobbiesModalProps,
} from './InterestsAndHobbiesModal.types';

// Use named rather than default exports.
export const InterestsAndHobbiesModal = (props: InterestsAndHobbiesModalProps) => {
  const { modalId, filteredArray, preferenceModel } = props;
  const isAuthorMode = props?.isAuthorMode;
  const dataLoaded = useRef<boolean>(false);
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [interests, setInterests] = useState<PreferencesFeatures[] | null>(null);
  const { profileModalId, setProfileModalId, profileDetails, setProfileDetails } = useProfileStore(state => state);
  const [consentChecked, setConsentChecked] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [featureItemsChecked, setFeatureItemsChecked] = useState<PersonalItems>({});
  const [getCustomerFeaturePreferences] = useLazyQuery(phoenixAccountGetCustomerPreferenceFeatures);
  const [updateCustomerInterests] = useMutation(phoenixAccountAddCustomerPreferences);
  const [selectedOption, setSelectedOption] = useState<SpaOrGolf>({ 'spa-lov': '', 'golf-lov': '' });
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const userProfileCountryCode = profileDetails?.profileAddress?.filter(address => address?.primary === true)?.[0];
  const userCountryCode = userProfileCountryCode?.address?.country?.code;
  const modalBodyClass = 'interestsandhobbies-modal-body';
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    if (isAuthorMode) {
      setInterests(FeaturesMock?.customer?.featuresByGroup?.preferencesFeatures);
      fetchFeaturePreferences();
    }
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isEditInterestsHobbiesModal = param.get('editInterestsHobbies');
    if (isEditInterestsHobbiesModal === 'true') {
      setProfileModalId(modalId);
    }
  }, []);

  useEffect(() => {
    if (!isAuthorMode && profileModalId === modalId && !interests) {
      fetchFeaturePreferences();
    }
  }, [profileModalId, interests]);

  const resetData = () => {
    setInterests(null);
    setConsentChecked(false);
    setFeatureItemsChecked({});
    setSelectedOption({ 'spa-lov': '', 'golf-lov': '' });
    setHasError(false);
    setIsSaveButtonEnabled(false);
  };

  const fetchFeaturePreferences = () => {
    getCustomerFeaturePreferences({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: (data: CustomerType) => {
        dataLoaded.current = true;
        setProfileDetails({
          ...profileDetails,
          consentFeatures: data?.customer?.featuresByGroup?.preferencesFeatures,
        });
        setInterests(data?.customer?.featuresByGroup?.preferencesFeatures);
        loadCheckedItems(data?.customer?.featuresByGroup?.preferencesFeatures);
        setHasError(false);
        apiLogger(
          `[FoodBevPreferences] getFoodBevPreferencesData - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: () => {
        setHasError(true);
        scrollToClass(modalBodyClass);
      },
    });
  };

  const featureCodes = ['interests-and-hobbies-lov', 'fitness-lov'];
  const loadCheckedItems = (features: [PreferencesFeatures]) => {
    const itemTypeObj: ItemTypeObj = {};
    const spaAndGolfObj: SpaOrGolf = { 'spa-lov': 'NOINTR', 'golf-lov': 'NOINTR' };
    featureCodes.forEach(code => {
      itemTypeObj[code] = [];
    });
    let selectedFeatureItems = {};

    features?.map((featureItem: PreferencesFeatures) => {
      const { feature, value } = featureItem;
      if (value !== null) {
        if (featureCodes.includes(feature.code)) {
          itemTypeObj[feature.code].push(...value.split(COLON_SYMBOL));
        } else if (feature.code === 'spa-lov') {
          spaAndGolfObj[feature.code] = value;
        } else if (feature.code === 'golf-lov') {
          spaAndGolfObj[feature.code] = value;
        }
      }
    });
    const optInConsent = checkAndSetConsentChecked(profileDetails?.consentFeatures, OPTED_OUT_PERSONALIZATION_KEY, 'N');
    setConsentChecked(optInConsent);

    Object.keys(itemTypeObj).forEach(key => {
      itemTypeObj[key].forEach((value: string) => {
        selectedFeatureItems = { ...selectedFeatureItems, [key + UNDERSCORE_SYMBOL + value]: true };
      });
    });

    setFeatureItemsChecked(selectedFeatureItems);
    setSelectedOption(spaAndGolfObj);
  };

  const updateFeatureItem = (type: string, value: string) => {
    const featurekey = type + UNDERSCORE_SYMBOL + value;
    setFeatureItemsChecked((featureItems: PersonalItems) => ({
      ...featureItems,
      [featurekey]: !featureItems[featurekey],
    }));
  };

  const handleItemSelection = (id: string, sectionId: string) => {
    setSelectedOption(prevOptions => ({ ...prevOptions, [sectionId]: id }));
  };

  const updatePayload = () => {
    const filteredChecked: PersonalItems = {};
    Object.entries(featureItemsChecked).forEach(([key, value]) => {
      if (value === true) {
        filteredChecked[key] = value;
      }
    });
    const groups: { [code: string]: string[] } = {};
    Object.keys(filteredChecked).forEach((key: string) => {
      const [code, suffix] = key.split(UNDERSCORE_SYMBOL);
      groups[code] = groups[code] || [];
      groups[code].push(suffix);
    });

    let featurePayload = Object.entries(groups).map(([code, suffixes]) => ({
      code: code.replaceAll(HYPHEN_SYMBOL, UNDERSCORE_SYMBOL).toUpperCase(),
      value: suffixes.join(COLON_SYMBOL),
    }));
    const spaAndGolfPayload = Object.entries(selectedOption).map(([code, suffixes]) => ({
      code: code.replaceAll(HYPHEN_SYMBOL, UNDERSCORE_SYMBOL).toUpperCase(),
      value: suffixes,
    }));
    featurePayload = [...featurePayload, ...spaAndGolfPayload];

    const featureDescList: Hobby[] = [];
    featurePayload?.forEach((featureItem: Hobby) => {
      if (featureItem.code?.includes('_LOV')) {
        const updateFeature = {
          code: featureItem.code.replace('_LOV', '_DESC'),
          value: featureItem.value.replaceAll(COLON_SYMBOL, ':|') + COLON_SYMBOL,
        };
        featureDescList.push(updateFeature);
      }
    });

    featureCodes.forEach(code => {
      if (!Object.prototype.hasOwnProperty.call(groups, code)) {
        featurePayload.push(
          {
            code: code.replaceAll(HYPHEN_SYMBOL, UNDERSCORE_SYMBOL).toUpperCase(),
            value: '',
          },
          {
            code: code.replaceAll(HYPHEN_SYMBOL, UNDERSCORE_SYMBOL).toUpperCase().replace('_LOV', '_DESC'),
            value: '',
          }
        );
      }
    });

    const consentItem =
      consentChecked === true
        ? { code: 'OPTED_OUT_PERSONALIZATION', value: 'N' }
        : { code: 'OPTED_OUT_PERSONALIZATION', value: 'Y' };

    featurePayload.push(...featureDescList);
    featurePayload.push(consentItem);

    updateCustomerInterests({
      variables: {
        input: {
          customerId: sessionData?.consumerID,
          customerFeatures: featurePayload,
        },
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: (data: CustomerType) => {
        setInterests(null);
        setProfileDetails({
          ...profileDetails,
          consentFeatures: updateConsentFeatureOptOut(profileDetails?.consentFeatures, consentChecked),
        });
        setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
        setProfileModalId('');
        setIsSaveButtonEnabled(false);
        setHasError(false);
        apiLogger(
          `[InterestsHobbies] updateCustomerFoodPreferences - sessionId value: ${sessionData?.sessionToken}: ${inspect(
            data
          )}`
        );
      },
      onError: error => {
        setHasError(true);
        scrollToClass(modalBodyClass);
        apiLogger(
          `[InterestsHobbies] addCustomerFeatures - sessionId value: ${sessionData?.sessionToken} - error: ${inspect(
            error
          )}`
        );
      },
    });
    return featurePayload;
  };

  const order: { [key: string]: ValueOrder } = {
    ENTHU: ValueOrder.ENTHU,
    CASUAL: ValueOrder.CASUAL,
    NOINTR: ValueOrder.NOINTR,
  };

  return (
    <StyledInterestsAndHobbiesModal>
      <Modal
        show={profileModalId === modalId}
        popupOpenState={profileModalId === modalId}
        setPopupOpenState={(): void => {
          setProfileModalId('');
          resetData();
        }}
        modalId={modalId}
        labelledBy={modalId}
        role="dialog"
        onClose={(): void => {
          setProfileModalId('');
          resetData();
        }}
        secondaryClassName="modal__container"
        disableScrollOnBody={true}
        handleBlur={true}
        aria-modal="true"
      >
        <Modal.Header
          className="Interests-header t-subtitle-xl py-3 py-md-4 pl-4 pr-4 px-md-5 pt-md-5"
          customHeadingClass="t-subtitle-xl"
          labelText={preferenceModel?.header}
          popupHeaderOnCLoseFunc={(): void => {
            setProfileModalId('');
            resetData();
          }}
        />
        <Modal.Body className={`modal__container-content px-4 px-md-5 py-4 mt-md-2 mb-3 mx-2 ${modalBodyClass}`}>
          <div className="row" data-testid="Interests-body">
            {hasError && (
              <div data-testId="uxl-error-msg" className="uxl-error-msg">
                <Messages messageType="warning" className="mb-4">
                  <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
                </Messages>
              </div>
            )}
            <div className={clsx('t-font-m col-12 m-modal-content mt-2 mt-md-0 mb-0 px-0')}>
              {preferenceModel?.description}
            </div>
            <div className="modal__container-accordion-food pb-2">
              {filteredArray &&
                filteredArray.map((filteredItem: FilteredData, index: number) => {
                  const { title, sectionTitle, items } = filteredItem;
                  const enableAccordionSeparator = index === filteredArray.length - 1 ? false : true;
                  return (
                    <Accordion
                      id={'title' + index}
                      enableAccordionSeparator={enableAccordionSeparator}
                      key={index}
                      isOpen={index === 0 ? true : false}
                      headerChildren={
                        <div className="pt-2">
                          {items.map((item: FilteredDataItems) => {
                            const { sectionId } = item;

                            return <Icon iconClass={clsx(FoodBevIconsType[sectionId], 'icon-decorative')}></Icon>;
                          })}
                          <Heading
                            variation={Types.headingType.subtitle}
                            fontSize={Types.size.large}
                            titleText={title}
                            customClass={clsx('d-inline food-item ml-3')}
                          />
                        </div>
                      }
                    >
                      <div className="ml-5 mb-0 mb-md-3">
                        {title && sectionTitle && (
                          <Heading
                            variation={Types.headingType.subtitle}
                            fontSize={Types.size.medium}
                            titleText={sectionTitle}
                            customClass={clsx('')}
                          />
                        )}
                        <div className="d-flex flex-wrap mt-3 px-0 col-6 col-md-5 col-lg-12">
                          {items.map((item: FilteredDataItems, index: number) => {
                            const { name, data, sectionId } = item;
                            const isSpaGolfSection = sectionId === SPA_LOV_TYPE || sectionId === GOLF_LOV_TYPE;
                            return (
                              <div
                                role="radiogroup"
                                aria-labelledby={'radiogroup' + index}
                                className={clsx(isSpaGolfSection ? 'spa-golf-title ' : 'd-contents')}
                              >
                                {isSpaGolfSection && (
                                  <Heading
                                    variation={Types.headingType.subtitle}
                                    fontSize={Types.size.medium}
                                    titleText={name}
                                    {...{ id: 'radiogroup' + index }}
                                  />
                                )}
                                {data
                                  ?.sort((a, b) => order[a.id] - order[b.id])
                                  .map((interestOrHobby: InterestOrHobby) => {
                                    const { id, value } = interestOrHobby;
                                    return isSpaGolfSection ? (
                                      <RadioButton
                                        tabIndexForInput={-1}
                                        radiobuttonId={sectionId + id}
                                        radiobuttonName={sectionId === SPA_LOV_TYPE ? 'spa' : 'golf'}
                                        radiobuttonLabel={value}
                                        ariaLabelForLink={value}
                                        value={id}
                                        className="pt-2 pr-3 pr-lg-5 mr-lg-5 pt-md-3 modal__container_radio"
                                        checked={
                                          selectedOption[sectionId === SPA_LOV_TYPE ? SPA_LOV_TYPE : GOLF_LOV_TYPE] ===
                                          id
                                        }
                                        setTabIndex={0}
                                        key={sectionId + id}
                                        onChange={() => {
                                          handleItemSelection(id, sectionId);
                                          setIsSaveButtonEnabled(true);
                                        }}
                                        onKeyDown={event => {
                                          if (event.key === 'Enter') {
                                            handleItemSelection(id, sectionId);
                                            setIsSaveButtonEnabled(true);
                                          }
                                        }}
                                      />
                                    ) : (
                                      <CheckBox
                                        onChange={() => {
                                          updateFeatureItem(sectionId, id);
                                          setIsSaveButtonEnabled(true);
                                        }}
                                        checked={featureItemsChecked[sectionId + UNDERSCORE_SYMBOL + id]}
                                        data-testid={id}
                                        checkboxName="featureItem_checkbox"
                                        checkboxLabel={value}
                                        checkboxId={id}
                                        key={sectionId + UNDERSCORE_SYMBOL + id}
                                        className="col-12 col-lg-4 t-font-m accordion-checkbox checkbox checkbox-feature_items pb-2 px-0"
                                      />
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Accordion>
                  );
                })}
            </div>
            {profileModalId === modalId && userCountryCode && userCountryCode !== constants.KOREA_COUNTRY_CODE && (
              <div className="mt-5 pt-2">
                {preferenceModel?.consentDetails &&
                  preferenceModel?.consentDetails.map((consent: ConsentDetails) => {
                    const { consentTitle, consentDescription } = consent;
                    return (
                      <>
                        <CheckBox
                          onChange={() => {
                            setConsentChecked(!consentChecked);
                            setIsSaveButtonEnabled(true);
                          }}
                          checked={consentChecked}
                          data-testId="checkbox_consent"
                          checkboxName="checkbox_consent"
                          checkboxLabel={consentTitle}
                          checkboxId={'consent-interest-hobbies'}
                          className="t-subtitle-m checkbox checkbox-consent"
                        />
                        <div className="t-font-m ml-5">{consentDescription}</div>
                      </>
                    );
                  })}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          className="modal__container-footer m-auto px-5 py-4 py-md-5 align-items-baseline justify-content-md-end"
          clearButtonLabel={preferenceModel?.cancelButton}
          clearClickHandler={(): void => {
            setProfileModalId('');
            resetData();
          }}
          clearBtnClassname="clear m-link-action mr-md-4 p-0 px-auto px-md-0 custom_click_track"
          applyLabel={preferenceModel?.saveButton}
          data-testid="Save"
          applyBtnClickHandler={() => {
            updatePayload();
          }}
          applyButtonDisabled={!isSaveButtonEnabled}
          applyBtnClickTrackValue={INTERESTS_PASSIONS_CLICK_TRACK}
        ></Modal.Footer>
      </Modal>
    </StyledInterestsAndHobbiesModal>
  );
};
